import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import jsonToTable from 'json-to-table'
import pdfStyle from '../css/pdf.css'
import i18n from "@/i18n"
import yess from "@/store/yess"
import timeSlots from "@/store/timeSlots"
import { createClient } from 'graphql-ws'
import db from '../db'

import currencyFormatter from 'currency-formatter'

const getOrganizationDB = db.getOrganizationDB

import moment from 'moment-timezone'
import amplitudeWrapper from './amplitudeWrapper'

/**
 * "GLOBAL" Values
 */
const EPOCH_TIMESTAMP_IN_MILLIS_LENGTH = 13

/**
 * @property {Boolean} inviteUserToOrganization
 * @property {Object} taxes
 */

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		installedApplications: [],
		currentReleases: [],
		organizationalTeamMates: [],
		installingApps: [],
		uninstallingApps: [],
    appInstances: [],
		appGroups: [],
		installedApps: [],
		applicationGroups: {},
		applications: [],
		installAppResult: null,
    gettingAppReleases: null,
		onBoardingApp: null,
		configureApp: null,
		installedAppSettings: {},
		isUpdatingAppSettings: false,
		initialDataProcessed: false,
    inApp: false,
		graphUrl: process.env.VUE_APP_GRAPHQL_URL,
		socketUrl: 'wss://ws.salescloud.is/subscriptions',
    paymentRequestUrl: 'https://service-payment-request.salescloud.is/v/1',
    socketClient: null,
    useDevUrl: false,
    organizationUUID: '',
    startTimeInMillis: 0,
    endTimeInMillis: 0,
    selectedDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    channels: [],
    user: null,
    team: null,
    searchQuery: null,
    locations: null,
    zones: null,
    locationVariationCombinations: [],
    callToActions: [],
    showPlatforms: false,
    showSidebar: true,
    organizations: null,
    showAppBarSearchField: false,
		genericDialog: null,
    showAppBar: true,
    platforms: [],
    accessToken: null,
    customers: null,
    subscriptions: null,
    minimizeSidebar: localStorage.getItem('minimizeSidebar') === "true",
    bookings: null,
    payments: null,
    items: null,
    itemTypes: null,
    itemLocationBlocks: null,
    paymentMethods: null,
    deliveryMethodInstances: null,
    deliveryMethods: null,
    messengerMethodInstances: null,
    dashCollections: null,
    dashCollection: null,
    categories: null,
    inIframe: false,
    discounts: null,
    pucks: null,
    staff: null,
    sales: null,
    contextOverlay: null,
    exportFileName: null,
    exportXlsHtml: null,
    exportPdfHtml: null,
    billingAccountState: null,
    languages: null,
    userLanguage: null,
    pdfStyle: pdfStyle,
    exportHtmlHeader: '<html><head><meta http-equiv="Content-type" content="text/html; charset="utf-8" /><meta charset="UTF-8" /><style>body {font-family: Arial, sans-serif;} table {width: 100%; margin-bottom: 40px;} th {text-align: left; padding: 5px;} td {text-align: left; padding: 5px;} button { display: none; } .table td, .table th { padding: .75rem; vertical-align: top; border-top: 1px solid #eceeef; } .table-striped > tbody > tr:nth-child(odd) { background-color: rgba(0,0,0,0.025); background-clip: padding-box; } caption { caption-side: top; background: #fff; color: #333; font-size: 1rem; } .text-right { text-align: right;} .text-left { text-align: left;}</style></head><body>',
    exportHtmlFooter: '</body></html>',
    countries: null,
    currencies: null,
    invoices: null,
    orders: null,
    selectedCallToAction: null,
    organization: null,
    badgeProcess: null,
    bulkOperation: null,
    appBarTabs: null,
    appBarFAB: null,
    appBarFABs: null,
    appBarActions: null,
    deviceSettingCategories: null,
    defaultDeviceSettings: null,
    appBarTitle: 'SalesCloud',
    work: null,
    workTypes: [],
    selectedAppBarTab: null,
    bills: null,
    siteTemplates: null,
    emailTemplates: null,
    sites: null,
    ls: '',
    lo: '',
    appBarExport: null,
    appBarChangeDisplay: null,
    routes: [
    ],
    isUpdatingStaff: false,
    isProcessingTeamInvite: false,
    roles: [],
    printers: [],
    notReady: true,
    actionSuccess: null,
    actionError: null,
    users: null,
    isUpdatingBookings: false,
    gettingAvailableTimeSlots: false,
    reservingSlot: false,
    releasingSlot: false,
    sessionToken: null,
    dashTypes: null,
    reportTypes: null,
    formsOfPayment: null,
    pointCards: null,
    paymentMethodInstances: null,
    processingBooking: false,
    etags: null,
    unclaimedEtags: null,
    etagURL: "https://salescloud.is/",
    defaultCountry: null,
    defaultCurrency: null,
    exportObjects: null,
    organizationTaxRates: null,
    exportFormats: [
      {
        key: 'csv',
        label: 'CSV'
      },
      {
        key: 'xlsx',
        label: 'XLSX'
      }
    ],
    appBarSearchChips: null,
    appBarMarketing: null,
    coupons: null,
    systemTime: null,
    platformPaymentMethodInstances: null,
    availableTimeSlots: [],
    cancellingBooking: false,
    yessProfiles: [],
    blockedTimeSlots: null,
    updatingItem: false,
    translations: [],
    updatingTranslation: false,
    attributes: [],
    variations: [],
    gettingYessProfiles: false,
    gettingPaymentMethodInstanceList: false,
    bookingStates: [
      {
        value: 'active',
        text: i18n.t('active')
      },
      {
        value: 'canceled',
        text: i18n.t('canceled')
      }
    ],
    selectedBookingState: 'active',
    selectedLocation: null,
    bulkOperations: [],
    selectedOrganization: null,
    availableBookingStates: [],
    showFilters: false,
    appProfileTypes: [],
    reservationWidgets: [],
    profileStatsStartTimeInMillis: null,
    profileStatsEndTimeInMillis: null,
    dataToMutate: null,
    variationLocationBlocks: null,
		salesCloudColors: {
			// A special thanks to Lárus for these colors. -JMC
			softBlack: "#2C2C2E",
			blue: "#007AFF",
			brightRed: "#FF3B30",
			indigo: "#5856D6",
			yellow: "#FFCC00",
			orange: "#FF9500",
			green: "#34C759",
			mossGreen: "#147A2D",
			red: "#F24F4b",
			pink: "#FF837D"
		},
    orderFulfillmentOptions: [],
    lastUpdatedObject: null,
		canEnableSalesCloudPay: false,
    showTermsAndConditions: false,
		isPendingSalesCloudPayOnboardingCompletion: false,
    selectedOrder: null,
    selectedLocationProfile: null
  },
  mutations: {
		setCanEnableSalesCloudPay(state, canEnableSalesCloudPay) {
			state.canEnableSalesCloudPay = canEnableSalesCloudPay
		},
    setShowTermsAndConditions(state, showTermsAndConditions) {
			state.showTermsAndConditions = showTermsAndConditions
		},
		setIsPendingSalesCloudPayOnboardingCompletion(state, isPendingSalesCloudPayOnboardingCompletion) {
			state.isPendingSalesCloudPayOnboardingCompletion = isPendingSalesCloudPayOnboardingCompletion
		},
		updateInitialDataProcessed(state, initialData) {
			state.initialDataProcessed = initialData
		},
		updateGenericDialog(state, genericDialog) {
			state.genericDialog = genericDialog
		},
    updateVariationLocationBlocks(state, blocks) {
          state.variationLocationBlocks = blocks
    },
    setMinimizeSidebar(state, shouldMinimizeSidebar) {
      localStorage.setItem('minimizeSidebar', shouldMinimizeSidebar)
      state.minimizeSidebar = shouldMinimizeSidebar
    },
    updateDeviceSettingsCategories(state, defaultDeviceSettings) {
      state.deviceSettingCategories = defaultDeviceSettings
    },
    updateDefaultDeviceSettings(state, deviceSettingsCategories) {
      state.defaultDeviceSettings = deviceSettingsCategories
    },
    updateDataToMutate(state, dataMutation) {
      state.dataToMutate = dataMutation
    },
    setLastUpdatedObject(state, updatedValues) {
      state.lastUpdatedObject = updatedValues
    },
    updateSocketClient(state, client) {
      state.socketClient = client
    },
    updateProfileStatsStartTimeInMillis(state, millis) {
      state.profileStatsStartTimeInMillis = millis
    },
    updateProfileStatsEndTimeInMillis(state, millis) {
      state.profileStatsEndTimeInMillis = millis
    },
    updateWorkTypes(state, workTypes) {
      state.workTypes = workTypes
    },
    updateReservationWidgets(state, widgets) {
      state.reservationWidgets = widgets
    },
    updateContextOverlay(state, contextOverlay) {
      state.contextOverlay = contextOverlay
    },
    updateShowFilters(state, shouldShow) {
      state.showFilters = shouldShow
    },
    updateAvailableBookingStates(state, availableBookingStates) {
      state.availableBookingStates = availableBookingStates
    },
    updateSelectedOrganization(state, selectedOrganization) {
      state.selectedOrganization = selectedOrganization
      axios.defaults.headers.common['salescloud-organization'] = selectedOrganization
      sessionStorage.setItem('selectedOrganization', selectedOrganization)
    },
    updateSelectedOrder(state, newOrder) {
      state.selectedOrder = newOrder
    },
    updateSelectedDate(state, date) {
      state.selectedDate = date
    },
    updateAccessToken(state, accessToken) {
      state.accessToken = accessToken

      if(accessToken !== null && accessToken !== undefined) {
        axios.defaults.headers.common['authorization'] = 'Auth0 ' + accessToken
      }
      else {
        delete axios.defaults.headers.common['authorization']
      }
    },
    updateSelectedLocation(state, selectedLocation) {
      state.selectedLocation = selectedLocation
      localStorage.setItem(state.organizationUUID + ':selectedLocation', selectedLocation)
    },
    updateSelectedLocationProfile(state, selectedLocationProfile) {
      state.selectedLocationProfile = selectedLocationProfile
    },
    updateSelectedBookingState(state, bookingState) {
      state.selectedBookingState = bookingState
    },
    gettingPaymentMethodInstanceList(state, status) {
      state.gettingPaymentMethodInstanceList = status
    },
    updateGettingYessProfiles(state, status) {
      state.gettingYessProfiles = status
    },
    updateBulkOperations(state, bulkOperations) {
      state.bulkOperations = bulkOperations
    },
    updateCallToActions(state, callToActions) {
      state.callToActions = callToActions
    },
    updateSelectedCallToAction(state, newCallToAction) {
      state.selectedCallToAction = newCallToAction
    },
    setAttributes(state, attributes) {
      state.attributes = attributes
    },
    setVariations(state, variations) {
      state.variations = variations
    },
    updatingTranslation(state, status) {
      state.updatingTranslation = status
    },
    setTranslations(state, translations) {
      state.translations = translations
    },
    setAppGroups(state, appGroups) {
      state.appGroups = appGroups
    },
    updatingItem(state, status) {
      state.updatingItem = status
    },
    updateInApp(state, isInApp) {
      state.inApp = isInApp
    },
    updateBlockedTimeSlots(state, blockedSlots) {
      state.blockedTimeSlots = blockedSlots
    },
    updatePucks(state, pucks) {
      state.pucks = pucks
    },
    updateYessProfiles(state, profiles) {
      state.yessProfiles = profiles
    },
    updateCancellingBooking(state, status) {
      state.cancellingBooking = status
    },
    updateBillingAccountState(state, billingAccountState) {
      state.billingAccountState = billingAccountState
    },
    updateAvailableTimeSlots(state, timeSlots) {
      state.availableTimeSlots = timeSlots
    },
    updatePlatformPaymentMethodInstances(state, platformPaymentMethodInstances) {
      state.platformPaymentMethodInstances = platformPaymentMethodInstances
    },
    updateAppBarMarketing(state, appBarMarketing) {
      state.appBarMarketing = appBarMarketing
    },
    updateSystemTime(state, time) {
      state.systemTime = time
    },
    updateCoupons(state, coupons) {
      state.coupons = coupons
    },
    updateAppBarSearchChips(state, chips) {
      state.appBarSearchChips = chips
    },
    updateOrganizationTaxRates(state, taxes) {
      state.organizationTaxRates = taxes
    },
    updateExportObjects(state, objects) {
      state.exportObjects = objects
    },
    updateDefaultCurrency(state, currency) {
      state.defaultCurrency = currency
    },
    updateUseDevUrl(state, status) {
      state.useDevUrl = status
    },
    updateProcessingBooking(state, status) {
      state.processingBooking = status
    },
    setDashCollection(state, collection) {
      state.dashCollection = collection
    },
    updateDashCollection(state, collection) {
      try {
        collection.data = JSON.parse(collection.data)
      }
      catch (e) {
        console.error('[updateDashCollection] error parsing JSON', e)
        collection.data = {}
      }

      for (let i in state.dashCollections) {
        if (state.dashCollections[i].uuid === collection.uuid) {
          Object.assign(state.dashCollections[i], collection)
        }
      }
    },
    updatePaymentMethodInstances(state, instances) {
      state.paymentMethodInstances = instances
    },
    updateDashWeight(state, updateOperation) {
      console.log(updateOperation)
    },
    updateStartTimeInMillis(state, timestampInMillis) {
      state.startTimeInMillis = timestampInMillis
    },
    updateEndTimeInMillis(state, timestampInMillis) {
      state.endTimeInMillis = timestampInMillis
    },
    updateDashTypes(state, dashTypes) {
      state.dashTypes = dashTypes
    },
    updateGettingAvailableTimeSlots(state, status) {
      state.gettingAvailableTimeSlots = status
    },
    updateReservingSlot(state, status) {
      state.reservingSlot = status
    },
    updateReleasingSlot(state, status) {
      state.releasingSlot = status
    },
    setIsUpdatingBookings(state, status) {
      state.isUpdatingBookings = status
    },
    updateActionSuccess(state, success) {
      /**
       * success object
       * @type {{message: string, subMessage: string, callbackLabel: string, callback: function}}
       */
      state.actionSuccess = success
      state.dataToMutate = null
    },
    updateActionError(state, error) {
      /**
       * error object
       * @type {{message: string, subMessage: string, callbackLabel: string, callback: function}}
       */
      state.actionError = error
    },
    updateRoles(state, roles) {
      state.roles = roles
    },
    setIsProcessingTeamInvite(state, status) {
      state.isProcessingTeamInvite = status
    },
    setIsUpdatingStaff(state, status) {
      state.isUpdatingStaff = status
    },
    updateAppBarChangeDisplay(state, appBarChangeDisplay) {
      state.appBarChangeDisplay = {
        ...state.appBarChangeDisplay,
        ...appBarChangeDisplay
      }
    },
    updateAppBarExport(state, appBarExport) {
      state.appBarExport = appBarExport
    },
    updateAppBarActions(state, actions) {
      state.appBarActions = actions
    },
    updateTeam(state, team) {

      if (team === null) {
        return
      }

      team.sort(function (a, b) {

        if (a === null || b === null) {
          return 0
        }

        if (a.user === null || b.user === null) {
          return 0
        }

        if (a.user.displayName === null || b.user.displayName === null) {
          return 0
        }

        return ('' + a.user.displayName).localeCompare(b.user.displayName)
      })

      state.team = team
    },
    updateWork(state, work) {
      state.work = work
    },
    exportXLS(state, payload) {
      let html = state.exportHtmlHeader
      html += payload.html
      html += state.exportHtmlFooter

      state.exportFileName = payload.fileName
      state.exportXlsHtml = html
    },
    exportPDF(state, payload) {

      let html = state.exportHtmlHeader
      html += payload.html
      html += state.exportHtmlFooter

      state.exportFileName = payload.fileName
      state.exportPdfHtml = html
    },
    updateAppBarTitle(state, title) {
      state.appBarTitle = title
    },
    updateExportFileName(state, fileName) {
      state.exportFileName = fileName
    },
    updateCountries(state, countries) {
      state.countries = countries
    },
    updateLanguages(state, supportedLanguages) {
      state.languages = supportedLanguages
    },
    setUserLanguage(state, languageKey) {
      state.userLanguage = languageKey
      i18n.locale = languageKey
    },
    updateDefaultCountry(state, country) {
      state.defaultCountry = country
    },
    updateOrganizations(state, organizations) {
      state.organizations = organizations
    },
    updateShowAppBarSearchField(state, show) {
      state.showAppBarSearchField = show
    },
    updateShowAppBar(state, show) {
      state.showAppBar = show
    },
    updateSearchQuery(state, query) {

      const searchQuery = []

      for (let i in query) {

        if (typeof query[i] === 'string' && query[i] !== null) {
          searchQuery.push({
            key: 'query',
            label: query[i],
            value: query[i]
          })
        }
        else {
          searchQuery.push(query[i])
        }

      }
      state.searchQuery = searchQuery
    },
    updateAppBarFab(state, fab) {
      state.appBarFAB = fab
    },
    updateAppBarFabs(state, fabs) {
      state.appBarFABs = fabs
    },
    updateBadgeProcess(state, badgeProcess) {
      state.badgeProcess = badgeProcess
    },
    updateBulkOperation(state, bulkOperation) {
      state.bulkOperation = bulkOperation
    },
    updatePrinters(state, printers) {
      state.printers = printers
    },
    updateLocations(state, locations) {
      state.locations = locations.map(v => { return { ...v, selectedForBulk: false } })
    },
    updateFormsOfPayment(state, formsOfPayment) {
      state.formsOfPayment = formsOfPayment
    },
    updatePointCards(state, pointCards) {
      state.pointCards = pointCards
    },
    updateZones(state, zones) {
      state.zones = zones
    },
    updateLocationVariationCombinations(state, locationVariationCombinations) {
      state.locationVariationCombinations = locationVariationCombinations
    },
    updateBills(state, bills) {
      state.bills = bills
    },
    updateLs(state, ls) {
      state.ls = ls
    },
    updateLo(state, lo) {
      state.lo = lo
    },
    updateSites(state, sites) {
      state.sites = sites
    },
    updateSiteTemplates(state, templates) {
      state.siteTemplates = templates
    },
    updateEmailTemplates(state, templates) {
      state.emailTemplates = templates
    },
    updateInIframe(state, inIframe) {
      state.inIframe = inIframe
    },
    updateDeliveryMethodInstances(state, deliveryMethodInstances) {

      for (let i in deliveryMethodInstances) {
        try {
          deliveryMethodInstances[i].data = JSON.parse(deliveryMethodInstances[i].data)
        }
        catch (e) {
          deliveryMethodInstances[i].data = {}
        }
      }

      state.deliveryMethodInstances = deliveryMethodInstances
    },
    updateDeliveryMethodInstance(state, deliveryMethodInstance) {

      const deliveryMethodInstances = state.deliveryMethodInstances

      for (let i in deliveryMethodInstances) {

        if (deliveryMethodInstances[i].uuid === deliveryMethodInstance.uuid) {
          try {
            deliveryMethodInstance.data = JSON.parse(deliveryMethodInstance.data)
          }
          catch (e) {
            deliveryMethodInstance.data = {}
          }

          deliveryMethodInstances[i] = deliveryMethodInstance
        }
      }

      state.deliveryMethodInstances = deliveryMethodInstances
    },
    updateDeliveryMethods(state, deliveryMethods) {
      state.deliveryMethods = deliveryMethods
    },
    updateMessengerMethodInstances(state, messengerMethodInstances) {
      state.messengerMethodInstances = messengerMethodInstances
    },
    updateSelectedAppBarTab(state, tab) {
      state.selectedAppBarTab = tab
    },
    updateAppBarTabs(state, tabs) {
      state.appBarTabs = tabs

      if (tabs === null || tabs.length === 0) {
        state.selectedAppBarTab = null
      }

    },
    updateDiscounts(state, discounts) {
      state.discounts = discounts
    },
    updateCategories(state, categories) {
      state.categories = categories
    },
    updateSubscriptions(state, subscriptions) {

      for(let i in subscriptions) {
        if(subscriptions[i].recurringPrice === null || subscriptions[i].recurringPrice === undefined) {
          subscriptions[i].recurringPrice = subscriptions[i].item.price
        }
      }

      const subscriptionsSorted = subscriptions.sort((s1, s2) => {

        if (s1 === null || s2 === null || typeof s1 === 'undefined' || typeof s2 === 'undefined') {
          return 0
        }

        if (s1.payer.address === null || s2.payer.address === null) {
          return 0
        }

        if (s1.payer.address.name_line === null || s2.payer.address.name_line === null) {
          return 0
        }

        return s1.payer.address.name_line.toLowerCase().localeCompare(s2.payer.address.name_line.toLowerCase())
      })
      state.subscriptions = subscriptionsSorted
    },
    updateBookings(state, bookings) {
      state.bookings = []

      for (let i in bookings) {

        if (bookings[i] === null || typeof bookings[i] === 'undefined') {
          continue
        }
        state.bookings.push(bookings[i])
      }
    },
    updatePayments(state, payments) {
      state.payments = payments
    },
    updatePaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods
    },
    updateInvoices(state, invoices) {
      if(Array.isArray(invoices)) {
        state.invoices = invoices.sort((i1, i2) => i2.created - i1.created)
      } else {
        state.invoices = invoices
      }
    },
    updateOrders(state, orders) {
      state.orders = orders
    },
    addOrders(state, orders) {

      if (state.orders === null) {
        state.orders = []
      }

      state.orders.push(...orders)
    },
    updateItems(state, items) {
      state.items = items.filter(notNull => notNull)
      // state.items = items.map(item => item?.price?.data?.include_tax ? {...item, price: {amount: item.price.amount, currency: item.price.currency_code, taxRate: item.price.data.include_tax}} : item)
    },
    updateAppInstances(state, appInstances) {
      state.appInstances = appInstances
    },
    updateSingleItem(state, item) {
      for (let i in state.items) {
        if (state.items[i].uuid === item.uuid) {
          state.items[i] = Object.assign(state.items[i], item)
        }
      }
    },
    updateItemLocationBlocks(state, itemLocationBlocks) {
      state.itemLocationBlocks = itemLocationBlocks
    },
    initializeDashCollections(state, collections) {
      state.dashCollections = collections

      if (collections !== null && collections.length > 0) {

        try {
          collections[0].data = JSON.parse(collections[0].data)
        }
        catch (e) {
          collections[0].data = {}
        }

        state.dashCollection = collections[0]
      }
    },
    updateDashCollections(state, collections) {
      state.dashCollections = collections
    },
    updateStaff(state, staff) {

          if(Array.isArray(staff) && staff.length > 0) {
            staff.sort(function (a, b) {
              return ('' + a.address.name_line).localeCompare(b.address.name_line)
            })
          }

      state.staff = staff
    },
    updateItemTypes(state, itemTypes) {
      state.itemTypes = itemTypes
    },
    updateEtags(state, etags) {
      state.etags = etags
    },
    updateUnclaimedEtags(state, unclaimedEtags) {
      state.unclaimedEtags = unclaimedEtags
    },
    dashCreated(state, dash) {
      for (let i in state.dashCollections) {
        if (state.dashCollections[i].uuid === state.dashCollection.uuid) {
          state.dashCollections[i].dashes.push(dash)
          state.dashCollection = state.dashCollections[i]
        }
      }
    },
    dashDeleted(state, dash) {
      for (let i in state.dashCollections) {
        if (state.dashCollections[i].uuid === state.dashCollection.uuid) {

          for (let n in state.dashCollections[i].dashes) {
            if (state.dashCollections[i].dashes[n].uuid === dash.uuid) {
              state.dashCollections[i].dashes.splice(n, 1)
            }
          }

          state.dashCollection = state.dashCollections[i]
        }
      }
    },
    dashUpdated(state, dash) {
      for (let i in state.dashCollections) {
        if (state.dashCollections[i].uuid === state.dashCollection.uuid) {

          for (let n in state.dashCollections[i].dashes) {
            if (state.dashCollections[i].dashes[n].uuid === dash.uuid) {
              state.dashCollections[i].dashes[n] = dash
            }
          }

          state.dashCollection = state.dashCollections[i]
        }
      }
    },
    updateCustomers(state, customers) {

      customers.sort(function (a, b) {
        return ('' + a.address.name_line).localeCompare(b.address.name_line)
      })

      state.customers = customers
    },
    updateShowSidebar(state, visible) {
      state.showSidebar = visible
    },
    updatePlatforms(state, platforms) {
      state.platforms = platforms
    },
    updateShowPlatforms(state, show) {
      state.showPlatforms = show
    },
    updateRoutes: (state, routes) => {
      state.routes = routes
    },
    updateUser: (state, user) => {
      state.user = user
    },
    updateUsers: (state, users) => {

      users.sort(function (a, b) {
        return ('' + a.displayName).localeCompare(b.displayName)
      })

      state.users = users
    },
    updateOrganization: (state, organization) => {
      state.organization = organization
      if(organization !== null) {
        state.selectedOrganization = organization.uuid
        Vue.prototype.$db = getOrganizationDB(organization.uuid)
      }
    },
    updateOrganizationUUID: (state, organizationUUID) => {
      state.organizationUUID = organizationUUID
    },
    updateChannels: (state, channels) => {
      state.channels = channels
    },
    updateCurrencies: (state, currencies) => {
      state.currencies = currencies
    },
    updateAppProfileTypes(state, types) {
      state.appProfileTypes = types
    },
    setCurrentReleases(state, releases) {
			state.currentReleases = releases
		},
    setInstallAppResult(state, appInstallResult) {
      state.installAppResult = appInstallResult
    },
    setInstallingApps(state, appsInstalling) {
      state.installingApps = appsInstalling
    },
    setGettingAppReleases(state, gettingAppReleases) {
      state.gettingAppReleases = gettingAppReleases
    }
  },
  actions: {
    getCloudinarySignature({ state }, {folderName, uploadPreset}) {  
      const body = {
        query: `query CloudinarySignature($folderName: String!, $uploadPreset: String!) {
          cloudinarySignature(folderName: $folderName, uploadPreset: $uploadPreset) {
            signature
            timestamp
          }
        }`,
        variables: {
          folderName,
          uploadPreset
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response?.data?.data?.cloudinarySignature) {
          return response.data.data.cloudinarySignature
        }
        return null
      }).catch(() => {
        return null
      })
    },
    updateCustomerOnOrder({ state }, { order, customer }) {
      if(typeof order === 'undefined' || order === null) {
        return null
      }
      if(typeof order.uuid === 'undefined' || order.uuid === null) {
        return null
      }

      if(typeof customer === 'undefined') {
        return null
      }

      let targetOwnerUUID = null

      if(customer !== null && typeof customer.uuid !== 'undefined' && customer.uuid !== null) {
        targetOwnerUUID = customer.uuid
      }

      const body = {
        query: 'mutation UpdateOrderOwner($orderUUID: String!, $targetOwnerUUID: String) {\n' +
            ' updateOrderOwner(orderUUID: $orderUUID, targetOwnerUUID: $targetOwnerUUID) {\n' +
            '   success\n' +
            '   message\n' +
            '   data\n' +
            ' }\n' +
            '}',
        variables: {
          orderUUID: order.uuid,
          targetOwnerUUID
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.updateOrderOwner) {
          return response.data.data.updateOrderOwner
        }
        return null
      }).catch(() => {
        return null
      })
    },
    searchCustomers({ state }, searchValue) {
      const body = {
        query: 'query SearchCustomers($searchValue: String) {\n' +
            ' searchCustomers(searchValue: $searchValue) {\n' +
            '   uuid\n' +
            '   customer_id\n' +
            '   ssn\n' +
            '   phone\n' +
            '   email\n' +
            '   address {\n' +
            '     name_line\n' +
            '     postal_code\n' +
            '     thoroughfare\n' +
            '     locality\n' +
            '   }\n' +
            '   active\n' +
            ' }\n' +
            '}',
        variables: {
          searchValue
        }
      }
      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.searchCustomers) {
          return response.data.data.searchCustomers
        }
        return []
      }).catch(() => {
        return []
      })
    },
    checkPendingPayment: ({state}, payload) => {

			const orderStatusUrl = `${state.paymentRequestUrl}/${payload.organizationUUID}/${payload.channelUUID}/${payload.paymentInstanceUUID}/${payload.orderUUID}`

				return axios.get(orderStatusUrl).then(response => {
					const statusResponse = response.data

					return statusResponse
				}).catch(e => {
          console.error("[checkPendingPayment] error: ", e)
          return null
        })
		},

    getBookingsCountInTimePeriod({ state }, { itemUUID, locationUUID, startTimeInMillis, endTimeInMillis }) {
      const body = {
        query: 'query BookingsCountInTimePeriod($organization: String!, $item: String, $location: String, $startTimeInMillis: Float, $endTimeInMillis: Float) {\n' +
            ' bookingsCountInTimePeriod(organization: $organization, item: $item, location: $location, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis) \n' +
            '}',
        variables: {
          organization: state.organizationUUID,
          item: itemUUID,
          location: locationUUID,
          startTimeInMillis,
          endTimeInMillis
        }
      }
      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && typeof response.data.data.bookingsCountInTimePeriod === 'number') {
          return response.data.data.bookingsCountInTimePeriod
        }
        return null
      }).catch(() => {
        return null
      })
    },
    resendMessage({state, dispatch}, payload) {
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation retryMessage($uuid: String!, $namespace: String!) { retryMessage(uuid: $uuid, namespace: $namespace) { uuid, namespace, origin, destination, created, changed, state, content, remote_id, message, payload, context, type } }',
          variables: {
            uuid: payload.uuid,
            namespace: payload.namespace
          }
        }
      }).then(response => {
        return response.data.data.retryMessage
      }).catch(e => {
        return null
      }).finally(() => {

      })

    },
		enableSalesCloudPay({state, dispatch}) {
			return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation { enableSalesCloudPay { success, message, onboardingUrl } }',
        }
      }).then(response => {
        return response.data.data.enableSalesCloudPay
      }).catch(e => {
        return null
      }).finally(() => {
        dispatch('getInitialData')
      })
		},
    uninstallAppInstance({state, dispatch}, instanceUUID) {

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation uninstallAppInstance($instance: String!) { uninstallAppInstance(instance: $instance) { success, code, message, redirectUrl } }',
          variables: {
            instance: instanceUUID
          }
        }
      }).then(response => {
        return response.data.data.uninstallAppInstance
      }).catch(e => {
        return null
      }).finally(() => {
        dispatch('refreshAppInstances')
      })

    },
    updateAppInstanceSettings({state, dispatch}, payload) {

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation updateAppInstanceSettings($instance: String!, $settings: JSON) { updateAppInstanceSettings(instance: $instance, settings: $settings) }',
          variables: {
            instance: payload.appInstanceUUID,
            settings: payload.appInstanceSettings
          }
        }
      }).then(response => {
        if (response.data.data.updateAppInstanceSettings && response.data.data.updateAppInstanceSettings === true) {
          dispatch('refreshAppInstances')
        }
        return response.data.data.updateAppInstanceSettings
      }).catch(e => {
        return false
      })
    },
    updateApp({state, dispatch}, instanceUUID) {

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation updateApp($uuid: String!) { updateApp(uuid: $uuid) }',
          variables: {
            uuid: instanceUUID
          }
        }
      }).then(response => {
        if (response.data.data.updateApp && response.data.data.updateApp === true) {
          dispatch('refreshAppInstances')
        }
        return response.data.data.updateApp
      }).catch(e => {
        return false
      })

    },
    installApp({commit, state, dispatch}, application) {

			const installingApps = state.installingApps
			installingApps.push(application.uuid)

			commit('setInstallingApps', installingApps)

			axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: ' mutation installApp($app: InstallApp!) {installApp(app: $app) { success, installUUID, version, code, message, redirectUrl } }',
					variables: {
						app: {uuid: application.uuid, version: application.version, settings: application.data}
					}
				}
			})
			.then(response => {
				if(response.data.data.installApp) {
					commit('setInstallAppResult', response.data.data.installApp)
				}
			})
			.finally(() => {
        dispatch('refreshAppInstances')
				commit('setInstallingApps', [])
			})

		},
    currentAppReleases({commit, state}, applicationUUID) {
      commit('setGettingAppReleases', true)
			axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: '{appReleases(uuid: "' + applicationUUID + '") { version, created, changeNotes } }'
				}
			})
				.then(response => {
					commit('setCurrentReleases', response.data.data.appReleases)
				}).finally(() => {
          commit('setGettingAppReleases', false)
        })

		},
    createZone({commit, state}, zone) {

      const query = {
        query: 'mutation createLocationZone($zone: NewLocationZone!) { createLocationZone(zone: $zone) { uuid name } }',
        variables: {
          zone: zone
        }
      }

      return axios.post(state.graphUrl, query).then(response => {
        return response.data.data.createLocationZone
      }).catch(() => {
        return null
      })
    },
		getAppData({ commit, state }) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: '{apps {name, description, created, changed, icon { url }, images { url }, uuid, price { amount, currency_code } group, visibility, version, defines { configFields { label, type, required, key, placeholder, description, options { key, label } } }, roles { owner { displayName, email } }}, appGroups { key, label } platforms {name, path, icon}, installedApps {uuid, name, version, settings}, whoAmI { displayName, email, organization { label, uuid } } }'
				}
			}).then(function(response) {
				let applicationGroups = {}

				commit('setPlatforms', response.data.data.platforms)
				commit('setInstalledApps', response.data.data.installedApps)

				let appGroups = response.data.data.appGroups

				for(let i in response.data.data.apps) {

					let item = response.data.data.apps[i]

					if(applicationGroups[item.group] === undefined) {
						applicationGroups[item.group] = []
					}

					applicationGroups[item.group].push(item)
				}

				commit('setApplications', response.data.data.apps)
				commit('setApplicationGroups', applicationGroups)
				commit('setAppGroups', appGroups)
				commit('setUser', response.data.data.whoAmI)
				commit('setOrganization', response.data.data.whoAmI.organization)
			})
		},
		getApplications({state}) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: '{apps {name, description, created, changed, icon { url }, images { url }, uuid, price { amount, currency_code } group, visibility, version, defines { configFields { label, type, required, key, placeholder, description, options { key, label } } }, roles { owner { displayName, email } }}, appGroups { key, label } platforms {name, path, icon}, installedApps {uuid, name, version, settings}, whoAmI { displayName, email, organization { label, uuid } } }'
				}
			}).then((response) => {
				return response.data.data
			})
		},
		getPrinters({state}) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: '{ printers {uuid, model, ipAddress, macAddress, title, lastSeenInMillis, silenceUntil, categories, created, changed, printKitchenReceipt, channels, locations, excludeQrCode } }'
				}
			}).then((response) => {
				return response.data.data.printers
			})
		},
    updatePrinter({state}, printer) {
      delete printer.created
      delete printer.changed
      delete printer.lastSeenInMillis
      delete printer.silenceUntil
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation  updatePrinter($printer: UpdatedPrinter!) { updatePrinter(printer: $printer) { uuid, title }}',
          variables: {
            printer
          }
        },
      }).then((response) => {
        return response.data.data.updatePrinter
      })
    },
		getCustomTemplates({state}) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: 'query { customTemplates { uuid, name, description, namespace, type, contentType, trigger, content, subject, active } }',
				}
			}).then((res) => {
				if(res && res.data && res.data.data.customTemplates && res.data.data.customTemplates) {
					return res.data.data.customTemplates
				}
				return []
			}).catch(() => {
				return []
			})
		},
		getDefaultTemplateContents({state}) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: 'query { globalTemplates { namespace, content } }',
				}
			}).then((res) => {
				if(res && res.data && res.data.data.globalTemplates && res.data.data.globalTemplates) {
					return res.data.data.globalTemplates
				}
				return []
			}).catch(() => {
				return []
			})
		},
		updateCustomTemplate({state}, updatedCustomTemplate) {
			delete updatedCustomTemplate.active
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: 'mutation updateCustomTemplate($template: UpdatedTemplate!) { updateCustomTemplate(template: $template) { success, text, message}}',
					variables: {
						template: updatedCustomTemplate
					}
				}
			}).then((res) => {
				return res.data.data.updateCustomTemplate
			}).catch(() => {
				return null
			})
		},
		enableCustomTemplate({state}, templateUUID) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: { query: 'mutation enableCustomTemplate($templateUUID: String!) { enableCustomTemplate(templateUUID: $templateUUID) { success, text, message}}',
					variables: {
						templateUUID: templateUUID
					}
				}
			}).then((res) => {
				return res.data.data.enableCustomTemplate
			}).catch(() => {
				return null
			})
		},
		disableCustomTemplate({state}, templateUUID) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: { query: 'mutation disableCustomTemplate($templateUUID: String!) { disableCustomTemplate(templateUUID: $templateUUID) { success, text, message}}',
					variables: {
						templateUUID: templateUUID
					}
				}
			}).then((res) => {
				return res.data.data.disableCustomTemplate
			}).catch(() => {
				return null
			})
		},
		createCustomTemplate({state}, newCustomTemplate) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: 'mutation createCustomTemplate($template: NewTemplate!) { createCustomTemplate(template: $template) { success, text, message}}',
					variables: {
						template: newCustomTemplate
					}
				}
			}).then((res) => {
				return res.data.data.createCustomTemplate
			}).catch(() => {
				return null
			})
		},
		getEnumNameValues({state}, enumName) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: `query { __type(name: "${enumName}") { enumValues { name } } }`
				}
			}).then((res) => {
				if(res && res.data && res.data.data && res.data.data.__type && res.data.data.__type.enumValues) {
					return res.data.data.__type.enumValues.map(v => v.name)
				}
				return []
			}).catch(() => {
				return []
			})
		},
		getActiveProducts({state}, uuid) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: 'query getOrganization($uuid: String!) { organization(uuid: $uuid) { uuid, activeProducts } }',
					variables: {
						uuid: uuid
					}
				}
			}).then((res) => {
				if(res.data.data.organization && res.data.data.organization.activeProducts) {
					return res.data.data.organization.activeProducts
				}
			}).catch(() => {
				return null
			})
		},
		updateOrganizationData({state, commit, getters}, organization) {
			return axios({
				url: state.graphUrl,
				method: 'post',
				data: {
					query: 'mutation updateActiveProducts($organization: UpdatedOrganization) { updateOrganization(organization: $organization) { uuid, activeProducts } }',
					variables: {
						organization
					}
				}
			}).then((res) => {
				if(res.data.data.updateOrganization) {
					return res.data.data.updateOrganization
				}
			}).catch(() => {
				return null
			})
		},
    createPrinter({state}, printer) {
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation  createPrinter($printer: NewPrinter!) { createPrinter(printer: $printer) { uuid, title }}',
          variables: {
            printer: printer
          }
        },
      }).then((response) => {
        return response.data.data.createPrinter
      })
    },
    clearPrinterQueue({state}, macAddress ) {
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation  clearPrinterQueue($macAddress: String!) { clearPrinterQueue(macAddress: $macAddress) { matchedCount, modifiedCount }}',
          variables: {
            macAddress: macAddress
          }
        },
      }).then((response) => {
        return response.data.data.clearPrinterQueue
      })
    },
    getChannels({ state, commit }) {
      const body = {
        query: 'query GetChannels($organization: String!) {\n' +
            '  channelsList(organization: $organization) {\n' +
            '    uuid\n' +
            '    label\n' +
            '    description\n' +
            '    active\n' +
            '    acceptedPaymentMethodInstances {\n' +
            '       uuid\n' +
            '       title\n' +
            '       namespace\n' +
            '    }\n' +
            '    created\n' +
            '    changed\n' +
            '    data\n' +
            '    deliverSMSToCustomerOnOrderStateChanges\n' +
            '    deliverSMSToCustomerOnOrderConfirmation\n' +
            '    notifyCustomerOnStateChanges{\n' +
            '       orderState\n' +
            '       smsContent\n' +
            '    }\n' +
            '  }\n' +
            '}',
        variables: {
          organization: state.organization.uuid
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data && result.data.data.channelsList) {
          commit('updateChannels', result.data.data.channelsList)
          return result.data.data.channelsList
        }
        return []
      }).catch(() => {
        return []
      })
    },
		isSessionValid({ state }) {

			const body = {
        query: 'query {\n' +
            ' systemTimeInMillis\n' +
						' whoAmI {\n' +
						' 	email\n' +
						'	}\n' +
            '}\n'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.whoAmI.email !== null
      }).catch(() => {
        return false
      })
		},
    getKiosks({ state }) {
      const body = {
        query: 'query {\n' +
            ' kiosks {\n' +
            '   uuid\n' +
            '   shortId\n' +
            '   title\n' +
            '   organization\n' +
            '   channel\n' +
            '   locations\n' +
            '   categories\n' +
            '   active\n' +
            '   defaultLanguage\n' +
            '   showPricesWithCurrencyCode\n' +
            '   cardReaderPaymentMethodInstance\n' +
            '   allowDifferentPaymentMethods\n' +
            '   enableSubCategories\n' +
            '   packagingOptions\n' +
            '   payerInfoRequests\n' +
            '   allowedLanguages\n' +
            '   maintenanceMode\n' +
            '   receiptPrinter\n' +
            '   collapseAllVariationGroups\n' +
            '   openingHours {\n' +
            '     enabled\n' +
            '     useLocation\n' +
            '     weekdayHours {\n' +
            '       day\n' +
            '       starthours\n' +
            '       endhours\n' +
            '     }\n' +
            '     seasonalHours {\n' +
            '       startTimeInMillis\n' +
            '       endTimeInMillis\n' +
            '       starthours\n' +
            '       endhours\n' +
            '     }\n' +
            '     closedDates\n' +
            '     timezone\n' +
            '     openingRule\n' +
            '   }\n' +
            ' }\n' +
            '}'
      }
      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.kiosks) {
          return response.data.data.kiosks
        }
        return []
      }).catch(() => {
        return []
      })
    },
    createKiosk({ state }, newKiosk) {
      if(typeof newKiosk === 'undefined' || newKiosk === null) {
        return null
      }

      const valuesToCreate = {}
      valuesToCreate.organization = newKiosk.organization
      valuesToCreate.title = newKiosk.title
      valuesToCreate.channel = newKiosk.channel
      valuesToCreate.locations = newKiosk.locations
      valuesToCreate.categories = newKiosk.categories
      valuesToCreate.defaultLanguage = newKiosk.defaultLanguage
      valuesToCreate.showPricesWithCurrencyCode = newKiosk.showPricesWithCurrencyCode
      valuesToCreate.enableSubCategories = newKiosk.enableSubCategories
      valuesToCreate.packagingOptions = newKiosk.packagingOptions
      valuesToCreate.payerInfoRequests = newKiosk.payerInfoRequests
      valuesToCreate.allowedLanguages = newKiosk.allowedLanguages
      valuesToCreate.maintenanceMode = newKiosk.maintenanceMode
      valuesToCreate.allowDifferentPaymentMethods = newKiosk.allowDifferentPaymentMethods
      valuesToCreate.collapseAllVariationGroups = newKiosk.collapseAllVariationGroups
      valuesToCreate.openingHours = newKiosk.openingHours
      
      const body = {
        query: 'mutation CreateKiosk($widget: NewKiosk!) {\n' +
            ' createKiosk(widget: $widget) {\n' +
            '   uuid\n' +
            ' }\n' +
            '}',
        variables: {
          widget: newKiosk
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.createKiosk) {
          return response.data.data.createKiosk
        }
        return null
      }).catch(() => {
        return null
      })
    },
    updateKiosk({ state }, updatedKiosk) {
      if(typeof updatedKiosk === 'undefined' || updatedKiosk === null) {
        return null
      }

      const valuesToUpdate = {}
      valuesToUpdate.uuid = updatedKiosk.uuid
      valuesToUpdate.shortId = updatedKiosk.shortId
      valuesToUpdate.organization = updatedKiosk.organizations || updatedKiosk.organization

      if(typeof updatedKiosk.active === 'boolean') {
        valuesToUpdate.active = updatedKiosk.active
      }

      if(typeof updatedKiosk.title !== 'undefined' && updatedKiosk.title !== null) {
        valuesToUpdate.title = updatedKiosk.title
      }

      if(typeof updatedKiosk.channel !== 'undefined' && updatedKiosk.channel !== null) {
        valuesToUpdate.channel = updatedKiosk.channel
      }

      if(typeof updatedKiosk.locations !== 'undefined') {
        valuesToUpdate.locations = updatedKiosk.locations
      }

      if(typeof updatedKiosk.categories !== 'undefined') {
        valuesToUpdate.categories = updatedKiosk.categories
      }

      if(typeof updatedKiosk.defaultLanguage !== 'undefined') {
        valuesToUpdate.defaultLanguage = updatedKiosk.defaultLanguage
      }

      if(typeof updatedKiosk.showPricesWithCurrencyCode === 'boolean') {
        valuesToUpdate.showPricesWithCurrencyCode = updatedKiosk.showPricesWithCurrencyCode
      }

      if(typeof updatedKiosk.enableSubCategories === 'boolean') {
        valuesToUpdate.enableSubCategories = updatedKiosk.enableSubCategories
      }

      if(typeof updatedKiosk.packagingOptions !== 'undefined') {
        valuesToUpdate.packagingOptions = updatedKiosk.packagingOptions
      }

      if(typeof updatedKiosk.payerInfoRequests !== 'undefined') {
        valuesToUpdate.payerInfoRequests = updatedKiosk.payerInfoRequests
      }

      if(typeof updatedKiosk.allowedLanguages !== 'undefined') {
        valuesToUpdate.allowedLanguages = updatedKiosk.allowedLanguages
      }

      if(typeof updatedKiosk.maintenanceMode === 'boolean') {
        valuesToUpdate.maintenanceMode = updatedKiosk.maintenanceMode
      }

      if (typeof updatedKiosk.cardReaderPaymentMethodInstance !== 'undefined') {
        valuesToUpdate.cardReaderPaymentMethodInstance = updatedKiosk.cardReaderPaymentMethodInstance
      }

      if (typeof updatedKiosk.receiptPrinter !== 'undefined') {
        valuesToUpdate.receiptPrinter = updatedKiosk.receiptPrinter
      }

      if (typeof updatedKiosk.allowDifferentPaymentMethods !== 'undefined') {
        valuesToUpdate.allowDifferentPaymentMethods = updatedKiosk.allowDifferentPaymentMethods
      }

      if(typeof updatedKiosk.collapseAllVariationGroups === 'boolean') {
        valuesToUpdate.collapseAllVariationGroups = updatedKiosk.collapseAllVariationGroups
      }

      if(typeof updatedKiosk.openingHours !== 'undefined') {
        valuesToUpdate.openingHours = updatedKiosk.openingHours
      }

      const body = {
        query: 'mutation UpdateKiosk($widget: UpdateKiosk!) {\n' +
            ' updateKiosk(widget: $widget) {\n' +
            '   uuid\n' +
            ' }\n' +
            '}',
        variables: {
          widget: valuesToUpdate
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.updateKiosk) {
          return response.data.data.updateKiosk
        }
        return null
      }).catch(() => {
        return null
      })
    },
    getStoreWidgets({ state }) {
      const body = {
        query: 'query {\n' +
            ' storeWidgets {\n' +
            '   uuid\n' +
            '   label\n' +
            '   active\n' +
            '   created\n' +
            '   changed\n' +
            '   organization\n' +
            '   channel\n' +
            '   locations\n' +
            '   categories\n' +
            '   paymentMethodInstances\n' +
            '   deliveryMethodInstances\n' +
            '   darkTheme\n' +
            '   defaultLanguage\n' +
            '   showVariationPrices\n' +
            '   collapseAllVariationGroups\n' +
            '   backgroundColor\n' +
            '   primaryColor\n' +
            '   accentColor\n' +
            '   fontFamily\n' +
            '   skipLocationSelection\n' +
            '   showLocationDateSelection\n' +
            '   locationDateSelectionDays\n' +
            '   locationDateSelectionSpecificDays\n' +
            '   showLocationTimeSlotSelection\n' +
            '   selectLocationFirst\n' +
            '   metaPixelCode\n' +
            '   allowedLanguages\n' +
            '   applePayEnabled\n' +
            '   orderFulfillments\n' +
            ' }\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.storeWidgets) {
          return response.data.data.storeWidgets
        }
        return []
      }).catch(() => {
        return []
      })
    },
    createStoreWidget({ state }, widget) {
      if(typeof widget === 'undefined' || widget === null) {
        return null
      }

      const widgetValuesToCreate = {}
      widgetValuesToCreate.organization = widget.organization
      widgetValuesToCreate.label = widget.label
      widgetValuesToCreate.channel = widget.channel
      widgetValuesToCreate.locations = widget.locations
      widgetValuesToCreate.categories = widget.categories
      widgetValuesToCreate.paymentMethodInstances = widget.paymentMethodInstances
      widgetValuesToCreate.deliveryMethodInstances = widget.deliveryMethodInstances
      widgetValuesToCreate.darkTheme = widget.darkTheme
      widgetValuesToCreate.defaultLanguage = widget.defaultLanguage
      widgetValuesToCreate.showVariationPrices = widget.showVariationPrices
      widgetValuesToCreate.collapseAllVariationGroups = widget.collapseAllVariationGroups
      widgetValuesToCreate.backgroundColor = widget.backgroundColor
      widgetValuesToCreate.primaryColor = widget.primaryColor
      widgetValuesToCreate.accentColor = widget.accentColor
      widgetValuesToCreate.fontFamily = widget.fontFamily
      widgetValuesToCreate.showLocationDateSelection = widget.showLocationDateSelection
      widgetValuesToCreate.locationDateSelectionDays = widget.locationDateSelectionDays
      widgetValuesToCreate.locationDateSelectionSpecificDays = widget.locationDateSelectionSpecificDays
      widgetValuesToCreate.showLocationTimeSlotSelection = widget.showLocationTimeSlotSelection
      widgetValuesToCreate.selectLocationFirst = widget.selectLocationFirst
      widgetValuesToCreate.metaPixelCode = widget.metaPixelCode
      widgetValuesToCreate.allowedLanguages = widget.allowedLanguages
      widgetValuesToCreate.applePayEnabled = widget.applePayEnabled
      widgetValuesToCreate.orderFulfillments = widget.orderFulfillments

      const body = {
        query: 'mutation CreateStoreWidget($widget: NewStoreWidget!) {\n' +
            ' createStoreWidget(widget: $widget) {\n' +
            '   uuid\n' +
            ' }\n' +
            '}',
        variables: {
          widget: widgetValuesToCreate
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.createStoreWidget) {
          return response.data.data.createStoreWidget
        }
        return null
      }).catch(() => {
        return null
      })
    },
    updateStoreWidget({ state }, widget) {
      if(typeof widget === 'undefined' || widget === null) {
        return null
      }

      const widgetValuesToUpdate = {}
      widgetValuesToUpdate.uuid = widget.uuid
      widgetValuesToUpdate.organization = widget.organization

      if(widget.active === true || widget.active === false) {
        widgetValuesToUpdate.active = widget.active
      }

      if(typeof widget.label !== 'undefined' && widget.label !== null) {
        widgetValuesToUpdate.label = widget.label
      }

      if(typeof widget.channel !== 'undefined' && widget.channel !== null) {
        widgetValuesToUpdate.channel = widget.channel
      }

      if(typeof widget.locations !== 'undefined') {
        widgetValuesToUpdate.locations = widget.locations
      }

      if(typeof widget.categories !== 'undefined') {
        widgetValuesToUpdate.categories = widget.categories
      }

      if(typeof widget.paymentMethodInstances !== 'undefined') {
        widgetValuesToUpdate.paymentMethodInstances = widget.paymentMethodInstances
      }

      if(typeof widget.deliveryMethodInstances !== 'undefined') {
        widgetValuesToUpdate.deliveryMethodInstances = widget.deliveryMethodInstances
      }

      if(typeof widget.darkTheme === 'boolean') {
        widgetValuesToUpdate.darkTheme = widget.darkTheme
      }

      if(typeof widget.defaultLanguage !== 'undefined') {
        widgetValuesToUpdate.defaultLanguage = widget.defaultLanguage
      }

      if(typeof widget.showVariationPrices === 'boolean') {
        widgetValuesToUpdate.showVariationPrices = widget.showVariationPrices
      }

      if (typeof widget.collapseAllVariationGroups === 'boolean') {
        widgetValuesToUpdate.collapseAllVariationGroups = widget.collapseAllVariationGroups
      }

      if(typeof widget.backgroundColor !== 'undefined') {
        widgetValuesToUpdate.backgroundColor = widget.backgroundColor
      }

      if(typeof widget.primaryColor !== 'undefined') {
        widgetValuesToUpdate.primaryColor = widget.primaryColor
      }

      if(typeof widget.accentColor !== 'undefined') {
        widgetValuesToUpdate.accentColor = widget.accentColor
      }

      if(typeof widget.fontFamily !== 'undefined') {
        widgetValuesToUpdate.fontFamily = widget.fontFamily
      }

      if(typeof widget.showLocationDateSelection === 'boolean') {
        widgetValuesToUpdate.showLocationDateSelection = widget.showLocationDateSelection
      }

      if(typeof widget.locationDateSelectionDays !== 'undefined' && widget.locationDateSelectionDays !== null && !isNaN(Number(widget.locationDateSelectionDays))) {
        widgetValuesToUpdate.locationDateSelectionDays = Number(widget.locationDateSelectionDays)
      }

      if(typeof widget.locationDateSelectionSpecificDays !== 'undefined') {
        widgetValuesToUpdate.locationDateSelectionSpecificDays = widget.locationDateSelectionSpecificDays
      }

      if(typeof widget.showLocationTimeSlotSelection === 'boolean') {
        widgetValuesToUpdate.showLocationTimeSlotSelection = widget.showLocationTimeSlotSelection
      }

      if(typeof widget.selectLocationFirst === 'boolean') {
        widgetValuesToUpdate.selectLocationFirst = widget.selectLocationFirst
      }

      if(typeof widget.metaPixelCode !== 'undefined') {
        widgetValuesToUpdate.metaPixelCode = widget.metaPixelCode
      }

      if(typeof widget.allowedLanguages !== 'undefined') {
        widgetValuesToUpdate.allowedLanguages = widget.allowedLanguages
      }

      if(typeof widget.applePayEnabled === 'boolean') {
        widgetValuesToUpdate.applePayEnabled = widget.applePayEnabled
      }

      if(typeof widget.orderFulfillments !== 'undefined') {
        widgetValuesToUpdate.orderFulfillments = widget.orderFulfillments
      }

      if (typeof widget.skipLocationSelection !== 'undefined') {
        widgetValuesToUpdate.skipLocationSelection = widget.skipLocationSelection
      }

      const body = {
        query: 'mutation UpdateStoreWidget($widget: UpdateStoreWidget!) {\n' +
            ' updateStoreWidget(widget: $widget) {\n' +
            '   uuid\n' +
            ' }\n' +
            '}',
        variables: {
          widget: widgetValuesToUpdate
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.updateStoreWidget) {
          return response.data.data.updateStoreWidget
        }
        return null
      }).catch(() => {
        return null
      })
    },
    getScheduleWidgets({ state }) {
      const body = {
        query: 'query {\n' +
            ' scheduleWidgets {\n' +
            '   uuid\n' +
            '   label\n' +
            '   active\n' +
            '   created\n' +
            '   changed\n' +
            '   organization\n' +
            '   channel\n' +
            '   location\n' +
            '   items\n' +
            '   categories\n' +
            '   darkTheme\n' +
            '   showImages\n' +
            '   booking\n' +
            '   defaultLanguage\n' +
            '   hideExtraInfo\n' +
            '   showUpsellItemsAsSwitch\n' +
            '   mode\n' +
            '   mainButtonsRounded\n' +
            '   mainButtonsCapitalized\n' +
            '   mainButtonsColor\n' +
            '   backgroundColor\n' +
            '   textColor\n' +
            '   primaryColor\n' +
            '   minimalFooter\n' +
            '   hideItemPrice\n' +
            '   useSlotPrices\n' +
            '   activitiesCategory\n' +
            '   showAsCategories\n' +
            '   intervalBetweenSlotsInMinutes\n' +
            '   metaPixelCode\n' +
            '   allowSelectionOfMultipleItems\n' +
            ' }\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.scheduleWidgets) {
          return response.data.data.scheduleWidgets
        }
        return null
      }).catch(() => {
        return null
      })
    },
    createScheduleWidget({ state }, widget) {
      if(typeof widget === 'undefined' || widget === null) {
        return null
      }

      const widgetValuesToCreate = {}
      // Required
      widgetValuesToCreate.organization = widget.organization
      widgetValuesToCreate.label = widget.label
      // Optional (?) Strings
      widgetValuesToCreate.channel = widget.channel
      widgetValuesToCreate.location = widget.location
      widgetValuesToCreate.items = widget.items
      widgetValuesToCreate.categories = widget.categories
      widgetValuesToCreate.defaultLanguage = widget.defaultLanguage
      widgetValuesToCreate.mode = widget.mode
      widgetValuesToCreate.booking = widget.booking
      widgetValuesToCreate.activitiesCategory = widget.activitiesCategory
      if(!isNaN(Number(widget.intervalBetweenSlotsInMinutes))) {
        widgetValuesToCreate.intervalBetweenSlotsInMinutes = Number(widget.intervalBetweenSlotsInMinutes)
      } else {
        widgetValuesToCreate.intervalBetweenSlotsInMinutes = null
      }
      widgetValuesToCreate.metaPixelCode = widget.metaPixelCode
      // Optional Booleans
      widgetValuesToCreate.darkTheme = widget.darkTheme
      widgetValuesToCreate.showImages = widget.showImages
      widgetValuesToCreate.hideExtraInfo = widget.hideExtraInfo
      widgetValuesToCreate.showUpsellItemsAsSwitch = widget.showUpsellItemsAsSwitch
      widgetValuesToCreate.mainButtonsRounded = widget.mainButtonsRounded
      widgetValuesToCreate.mainButtonsCapitalized = widget.mainButtonsCapitalized
      widgetValuesToCreate.minimalFooter = widget.minimalFooter
      widgetValuesToCreate.hideItemPrice = widget.hideItemPrice
      widgetValuesToCreate.useSlotPrices = widget.useSlotPrices
      widgetValuesToCreate.showAsCategories = widget.showAsCategories
      widgetValuesToCreate.allowSelectionOfMultipleItems = Boolean(widget.allowSelectionOfMultipleItems)
      // Color values (optional)
      widgetValuesToCreate.mainButtonsColor = widget.mainButtonsColor
      widgetValuesToCreate.backgroundColor = widget.backgroundColor
      widgetValuesToCreate.textColor = widget.textColor
      widgetValuesToCreate.primaryColor = widget.primaryColor

      const body = {
        query: 'mutation CreateScheduleWidget($widget: NewScheduleWidget!) {\n' +
            '   createScheduleWidget(widget: $widget) {\n' +
            '     uuid\n' +
            '   }\n' +
            '}',
        variables: {
          widget: widgetValuesToCreate
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.createScheduleWidget) {
          return response.data.data.createScheduleWidget
        }
        return null
      }).catch(() => {
        return null
      })
    },
    updateScheduleWidget({ state }, widget) {
      if(typeof widget === 'undefined' || widget === null) {
        return null
      }

      const widgetValuesToUpdate = {}
      widgetValuesToUpdate.uuid = widget.uuid
      widgetValuesToUpdate.organization = widget.organization

      if(widget.active === true || widget.active === false) {
        widgetValuesToUpdate.active = widget.active
      }

      // Can not be null because it is required
      if(typeof widget.label !== 'undefined' && widget.label !== null) {
        widgetValuesToUpdate.label = widget.label
      }

      if(typeof widget.channel !== 'undefined') {
        widgetValuesToUpdate.channel = widget.channel
      }

      if(typeof widget.location !== 'undefined') {
        widgetValuesToUpdate.location = widget.location
      }

      if(typeof widget.categories !== 'undefined') {
        widgetValuesToUpdate.categories = widget.categories
      }

      if(typeof widget.items !== 'undefined') {
        widgetValuesToUpdate.items = widget.items
      }

      if(typeof widget.darkTheme !== 'undefined') {
        widgetValuesToUpdate.darkTheme = widget.darkTheme
      }

      if(typeof widget.showImages !== 'undefined') {
        widgetValuesToUpdate.showImages = widget.showImages
      }

      if(typeof widget.booking !== 'undefined') {
        widgetValuesToUpdate.booking = widget.booking
      }

      if(typeof widget.defaultLanguage !== 'undefined') {
        widgetValuesToUpdate.defaultLanguage = widget.defaultLanguage
      }

      if(typeof widget.hideExtraInfo !== 'undefined') {
        widgetValuesToUpdate.hideExtraInfo = widget.hideExtraInfo
      }

      if(typeof widget.showUpsellItemsAsSwitch !== 'undefined') {
        widgetValuesToUpdate.showUpsellItemsAsSwitch = widget.showUpsellItemsAsSwitch
      }

      if(typeof widget.mode !== 'undefined') {
        widgetValuesToUpdate.mode = widget.mode
      }

      if(typeof widget.mainButtonsRounded !== 'undefined') {
        widgetValuesToUpdate.mainButtonsRounded = widget.mainButtonsRounded
      }

      if(typeof widget.mainButtonsCapitalized !== 'undefined') {
        widgetValuesToUpdate.mainButtonsCapitalized = widget.mainButtonsCapitalized
      }

      if(typeof widget.mainButtonsColor !== 'undefined') {
        widgetValuesToUpdate.mainButtonsColor = widget.mainButtonsColor
      }

      if(typeof widget.backgroundColor !== 'undefined') {
        widgetValuesToUpdate.backgroundColor = widget.backgroundColor
      }

      if(typeof widget.textColor !== 'undefined') {
        widgetValuesToUpdate.textColor = widget.textColor
      }

      if(typeof widget.primaryColor !== 'undefined') {
        widgetValuesToUpdate.primaryColor = widget.primaryColor
      }

      if(typeof widget.minimalFooter !== 'undefined') {
        widgetValuesToUpdate.minimalFooter = widget.minimalFooter
      }

      if(typeof widget.hideItemPrice !== 'undefined') {
        widgetValuesToUpdate.hideItemPrice = widget.hideItemPrice
      }

      if(typeof widget.useSlotPrices !== 'undefined') {
        widgetValuesToUpdate.useSlotPrices = widget.useSlotPrices
      }

      if(typeof widget.activitiesCategory !== 'undefined') {
        widgetValuesToUpdate.activitiesCategory = widget.activitiesCategory
      }

      if(typeof widget.showAsCategories !== 'undefined') {
        widgetValuesToUpdate.showAsCategories = widget.showAsCategories
      }

      if(!isNaN(Number(widget.intervalBetweenSlotsInMinutes))) {
        widgetValuesToUpdate.intervalBetweenSlotsInMinutes = Number(widget.intervalBetweenSlotsInMinutes)
      }

      if(typeof widget.metaPixelCode !== 'undefined') {
        widgetValuesToUpdate.metaPixelCode = widget.metaPixelCode
      }

      widgetValuesToUpdate.allowSelectionOfMultipleItems = Boolean(widget.allowSelectionOfMultipleItems)

      const body = {
        query: 'mutation UpdateScheduleWidget($widget: UpdateScheduleWidget!) {\n' +
            '   updateScheduleWidget(widget: $widget) {\n' +
            '     uuid\n' +
            '   }\n' +
            '}',
        variables: {
          widget: widgetValuesToUpdate
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.updateScheduleWidget) {
          return response.data.data.updateScheduleWidget
        }
        return null
      }).catch(() => {
        return null
      })
    },
    getScheduledReports({state}, dashCollectionUUID) {
      const body = {
        query: 'query($organization: String!, $dashCollection: String!) {\n' +
            '   scheduledReports(organization: $organization, dashCollection: $dashCollection) {\n' +
            '     uuid\n' +
            '     channels\n' +
            '     interval\n' +
            '     intervalTrigger\n' +
            '     active\n' +
            '     messengerMethod\n' +
            '     messengerDestination\n' +
            '     dayOfTheMonth\n' +
            '     dayOfTheWeek\n' +
            '   }\n'+
            ' }\n',
        variables: {
          organization: state.organizationUUID,
          dashCollection: dashCollectionUUID
        }
      }
      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.scheduledReports
      }).catch(() => {
        return null
      })
    },
    updateScheduledReport({state}, scheduledReport) {

      const body = {
        query: 'mutation($scheduledReport: UpdatedScheduledReport!) {\n' +
          '     updateScheduledReport(scheduledReport: $scheduledReport){\n' +
          '       uuid\n' +
          '       active\n' +
          '     }\n' +
          '   }\n',
        variables: {
          scheduledReport: scheduledReport
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.updateScheduledReport
      }).catch(() => {
        return null
      })
    },
    createScheduledReport({state}, scheduledReport) {
      const body = {
        query: 'mutation($scheduledReport: NewScheduledReport!) {\n' +
          '     createScheduledReport(scheduledReport: $scheduledReport){\n' +
          '       uuid\n' +
          '     }\n' +
          '   }\n',
        variables: {
          scheduledReport: scheduledReport
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.createScheduledReport
      }).catch(() => {
        return null
      })
    },
    logout({state, commit}) {
      commit('updateAccessToken', null)
      commit('updateUser', null)
      commit('updateOrganization', null)
    },
		deletePayment({state}, payment) {

			const body = {
        query: 'mutation($namespace: String!, $route: String!, $uiValues: JSON, $payload: JSON) {\n' +
					' executeBulkOperation(namespace: $namespace, route: $route, uiValues: $uiValues, payload: $payload) { success }' +
					'}',
				variables: {
					namespace: 'delete_payment',
					route: 'payments',
					uiValues: {},
					payload: payment
				}
      }

			return axios.post(state.graphUrl, body).then(response => {
				return response.data.data.executeBulkOperation
			}).catch(() => {
				return null
			})
		},
		searchBookings({state}, query) {
			const body = {
        query: 'query {\n' +
            '  searchBookings(query: "' + query + '") {\n' +
						'    startsAtTime\n' +
						'    endsAtTime\n' +
            '    internalNote\n' +
            '    externalNote\n' +
						'    item{\n' +
						'       uuid\n' +
						'       title\n' +
						'       price{\n' +
						'         amount\n' +
						'         currency_code\n' +
						'       }\n' +
						'       images { \n' +
						'         file { \n' +
						'           sources { \n' +
						'             thumbnail \n' +
						'             original \n' +
						'           } \n' +
						'         } \n' +
						'       } \n' +
						'    }\n' +
						'    customer{\n' +
						'       uuid\n' +
						'       ssn\n' +
						'       email\n' +
						'       phone\n' +
						'       address{\n' +
						'          name_line\n' +
						'       }\n' +
						'    }\n' +
						'    location{\n' +
						'       uuid\n' +
						'       label\n' +
						'    }\n' +
						'    uuid\n' +
						'    namespace\n' +
						'    redeemedAt\n' +
						'    booking_id\n' +
						'    created\n' +
						'    changed\n' +
						'    quantity\n' +
						'    lineItem{\n' +
						'       line_item_label\n' +
						'       quantity\n' +
						'    }\n' +
						'    description\n' +
						'    status\n' +
						'    co{\n' +
						'       uuid\n' +
						'       ssn\n' +
						'       email\n' +
						'       phone\n' +
						'       address{\n' +
						'          name_line\n' +
						'       }\n' +
						'    }\n' +
						'    order\n' +
            '  } \n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.searchBookings
      }).catch(() => {
        return null
      })
		},
    updateWorkHours({ state, dispatch }, work) {
      if(!work) {
        return null
      }
      const body = {
        query: 'mutation updateWorkLegacy($updateWork: UpdateWork){\n' +
            '  updateWorkLegacy(work:$updateWork) {\n' +
            '       uuid ' +
            '  } \n' +
            '}',
        variables: {
          updateWork: work
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data) {
          return dispatch('getWork', {
            startTimeInMillis: moment().startOf('month').valueOf(),
            endTimeInMillis: moment().endOf('month').valueOf()
          }).then(() => {
            return result.data.data.updateWorkLegacy
          })
        }
        return null
      }).catch(() => {
        return null
      })
    },
    createNewWorkHours({ state, dispatch }, work) {
      if(!work) {
        return null
      }

      const body = {
        query: 'mutation createWorkLegacy($newWork: NewWork){\n' +
            '  createWorkLegacy(work:$newWork) {\n' +
            '       uuid ' +
            '  } \n' +
            '}',
        variables: {
          newWork: work
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data) {
          return dispatch('getWork', {
            startTimeInMillis: moment().startOf('month').valueOf(),
            endTimeInMillis: moment().endOf('month').valueOf()
          }).then(() => {
            return response.data.data.createWorkLegacy
          })
        }
        return null
      }).catch(() => {
        return null
      })
    },
    getCategoryByUUID({state}, uuid) {
      return this.$store.state.categories.find(category => category.uuid === uuid)
    },
    getCustomerByUUID({state}, uuid) {
      const body = {
        query : 'query {\n' +
            '    customer(uuid: "' + uuid + '") {\n' +
            '       uuid\n' +
            '       ssn\n' +
            '       phone\n' +
            '       email\n' +
            '       customer_id\n' +
            '       address {\n' +
            '         name_line\n' +
            '         postal_code\n' +
            '         thoroughfare\n' +
            '         locality\n' +
            '         country\n' +
            '       }\n' +
            '  }\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.customer
      }).catch(() => {
        return null
      })
    },
    getDashByNamespace({state}, payload) {

			/*
			   Implementing these two undefined checks as not every dashType from getDashByNamespace uses timeIntervalFrom & timeIntervalTo
			   So instead of making sure to pass in unused variables form everywhere this function is called we simply set them to null here.
			   Note that graphQL queries do not accept undefined variables but do accept null.
			*/

			if(typeof payload.timeIntervalFrom === 'undefined') {
				payload.timeIntervalFrom = null
			}
			if(typeof payload.timeIntervalTo === 'undefined') {
				payload.timeIntervalTo = null
			}

      const body = {
        query : 'query dashByNamespace($timeIntervalFrom: String, $timeIntervalTo: String, $config: JSON, $channels: [String], $locations: [String]) {\n' +
            '    dashByNamespace(namespace: "' + payload.namespace + '", startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: ' + payload.endTimeInMillis +  ', timeIntervalFrom: $timeIntervalFrom, timeIntervalTo: $timeIntervalTo, config: $config, channels: $channels, locations: $locations) {\n' +
            '       type\n' +
            '       content\n' +
						'       alerts {\n' +
						'       	type\n' +
						'       	message\n' +
						'       	content\n' +
						'       	path\n' +
						'       	context\n' +
						'       }\n' +
            '  }\n' +
            '}',
         variables: {
           channels: payload.channels,
           locations: payload.locations,
           config: payload.config,
					 timeIntervalFrom: payload.timeIntervalFrom,
					 timeIntervalTo: payload.timeIntervalTo
         }
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.dashByNamespace
      }).catch(() => {
        return null
      })
    },
    signInWithEmailAndPass({state, commit}, payload) {
      const body = {
        query: 'mutation { signInWithEmailAndPassword(email: "' + payload.email + '", password: "' + payload.password + '", state: "") { expiresIn, token, refreshToken } }'
      }

      return axios.post(state.graphUrl, body).then(response => {
        commit('updateAccessToken', response.data.data.signInWithEmailAndPassword)
        return response.data.data.signInWithEmailAndPassword
      }).catch(() => {
        return null
      })
    },
    getSubscriptionOrders({state}, uuid) {

      const body = {
        query: 'query { subscriptionOrders(uuid: "' + uuid + '") { channel { label } totalAmount { amount, currency_code } customer { address { name_line } } created, changed, orderId, balance { amount, currency_code }, lineItems { label }, payments { namespace, amount, currency_code, created, changed, status, message } } }'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.subscriptionOrders
      })
    },
    getReservationWidgets({ state, commit }) {
      const body = {
        query: `query ReservationWidgets {
          reservationWidgets {
            uuid
            label
            defaultLanguage
            darkTheme
            organization {
              label
              uuid
            }
            channel {
              label
              uuid
            }
            location {
              label
              uuid
            }
            item {
              title
              uuid
            }
            active
            created
            changed
          }
        }`
      }

      return axios.post(state.graphUrl, body).then(response => {
        commit('updateReservationWidgets', response.data.data.reservationWidgets)
        return response.data.data.reservationWidgets
      }).catch(() => {
        return null
      })
    },
    updateReservationWidget({ state }, payload) {
      const reservationWidget = {
        uuid: payload.uuid,
        label: payload.label,
        channel: payload.channel,
        location: payload.location,
        item: payload.item,
        defaultLanguage: payload.defaultLanguage,
        darkTheme: payload.darkTheme,
        active: payload.active
      }

      if(typeof reservationWidget.channel === 'object' && typeof reservationWidget.channel.uuid === 'string') {
        reservationWidget.channel = reservationWidget.channel.uuid
      }

      if(typeof reservationWidget.location === 'object' && typeof reservationWidget.location.uuid === 'string') {
        reservationWidget.location = reservationWidget.location.uuid
      }

      if(typeof reservationWidget.item === 'object' && typeof reservationWidget.item.uuid === 'string') {
        reservationWidget.item = reservationWidget.item.uuid
      }

	  const body = {
        query: `mutation UpdateReservationWidget($reservationWidget: UpdateReservationWidget) {
          updateReservationWidget(reservationWidget: $reservationWidget) {
            uuid
            label
            defaultLanguage
            darkTheme
            organization {
              uuid
              label
            }
            channel {
              uuid
              label
            }
            location {
              uuid
              label
            }
            item {
              uuid
              title
            }
            active
            created
            changed
          }
        }`,
        variables: {
          reservationWidget: reservationWidget
        }
	  }

	  return axios.post(state.graphUrl, body).then(response => {
		  return response.data.data.updateReservationWidget
	  }).catch(() => {
		  return null
	  })
	},
    createReservationWidget({ state }, payload) {
      const reservationWidget = {
        label: payload.label,
        channel: payload.channel,
        location: payload.location,
        item: payload.item,
        defaultLanguage: payload.defaultLanguage,
        darkTheme: payload.darkTheme,
        active: payload.active
      }

      if(typeof reservationWidget.channel === 'object' && typeof reservationWidget.channel.uuid === 'string') {
        reservationWidget.channel = reservationWidget.channel.uuid
      }

      if(typeof reservationWidget.location === 'object' && typeof reservationWidget.location.uuid === 'string') {
        reservationWidget.location = reservationWidget.location.uuid
      }

      if(typeof reservationWidget.item === 'object' && typeof reservationWidget.item.uuid === 'string') {
        reservationWidget.item = reservationWidget.item.uuid
      }

      const body = {
        query: `mutation CreateReservationWidget($reservationWidget: NewReservationWidget) {
          createReservationWidget(reservationWidget: $reservationWidget) {
            uuid
            label
            defaultLanguage
            darkTheme
            organization {
              uuid
              label
            }
            channel {
              uuid
              label
            }
            location {
              uuid
              label
            }
            item {
              uuid
              title
            }
            active
            created
            changed
          }
        }`,
        variables: {
          reservationWidget: reservationWidget
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.createReservationWidget
      }).catch(() => {
        return null
      })
    },
    getBillingCreditUseage({state}) {
      const body = {
        query: 'query { creditUsage { created, unitOfChange, namespace, creditsAfter { amount, currency_code }, creditsBefore { amount, currency_code } } }'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.creditUsage
      })
    },
    getCustomerData({ state }, customer) {

      const body = {
        query: `query {\n
          bookings(conditions: [{operator: equals, property: "customer.uuid", value: "` + customer.uuid + `"}]) {\n
            uuid\n
          }\n
          orders(startTimeInMillis: 0, endTimeInMillis: 0, conditions: [{operator: equals, property: "customer.uuid", value: "` + customer.uuid + `"}]) {\n
            uuid\n
          }\n
          invoices(startTimeInMillis: 0, endTimeInMillis: 0, conditions: [{operator: equals, property: "customer.uuid", value: "` + customer.uuid + `"}]) {\n
            uuid\n
          }\n
            subscriptions(conditions: [{operator: equals, property: "customer.uuid", value: "` + customer.uuid + `"}]) {\n
              uuid\n
            }\n
          payments(conditions: [{operator: equals, property: "customer.uuid", value: "` + customer.uuid + `"}]) {\n
            uuid\n
            amount\n
            currency_code\n
            namespace\n
            created\n
            changed\n
            status\n
          }\n
        }`
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data
      }).catch(() => {
        return null
      })
    },
    getCouponRedemptionsByCouponUUID({ state }, couponUUID) {
      const body = {
        query: `query {\n
          redeemedCouponsByUUID(uuid: "${couponUUID}") {\n
            uuid\n
            type\n
            coupon {\n
              uuid\n
              label\n
              description\n
              code\n
              maxRedemptions\n
              timesRedeemed\n
              validFrom\n
              validUntil\n
            }\n
            quantity\n
            location {\n
              uuid\n
              label\n
            }\n
            reference\n
            redeemedBy\n
            redeemedAt\n
            created\n
            changed\n
          }\n
        }`
      }

      return axios.post(state.graphUrl, body).then(results => {
        if(results && results.data && results.data.data && results.data.data.redeemedCouponsByUUID) {
          const { redeemedCouponsByUUID } = results.data.data
          return redeemedCouponsByUUID
        }
        return results
      }).catch(error => {
        if(error) {
          if(error.response) {
            if(error.response.status >= 500) {
              console.error('server error')
            }
            if(error.response.status >= 400) {
              console.error('request error')
            }
          }
        }
      })
    },
    getSitesAndTemplates({state, commit}) {
      const body = {
        query: 'query { siteTemplates { preview_url, template_id, template_name, desktop_thumbnail_url, thumbnail_url }, sites { site_name, preview_site_url, template_id, publish_status } }'
      }

      return axios.post(state.graphUrl, body).then(response => {
        commit('updateSiteTemplates', response.data.data.siteTemplates)
        commit('updateSites', response.data.data.sites)
        return response.data.data
      })
    },
    refetchOrganization({state}) {
      const body = {
        query: 'query { organization(organization: "' + state.organizationUUID + '") { title, description, namespace, enabled, step }}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.checkoutSectionInstances
      })
    },
    creditInvoice({state}, invoice) {
      const body = {
        query: 'mutation { creditInvoice(invoice: "' + invoice.uuid + '") { uuid } }'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.creditInvoice
      }).catch(() => {
        return null
      })
    },
    fetchCustomerByEmail({state}, email) {
      const body = {
        query: 'query { customers { uuid, phone, address { name_line }, email }}'
      }

      return axios.post(state.graphUrl, body).then(response => {

        if(response.data.data.customers !== null) {
          return response.data.data.customers.find(customer => customer.email === email)
        }

        return null
      }).catch(() => {
        return null
      })
    },
    getDevices({state, commit}) {

      const body = {
        query: 'query { devices(namespace: "tablet") { id, namespace, appVersion, lastSeenInMillis, hasSubscription, deviceLog { IpAddress DeviceSettings { booleans { key, value } } } }, deviceSettingCategories { key, name }, defaultDeviceSettings { booleans { title, category, key, defaultValue } } }'
      }

      return axios.post(state.graphUrl, body).then(response => {

        commit('updateDeviceSettingsCategories', response.data.data.deviceSettingCategories)
        commit('updateDefaultDeviceSettings', response.data.data.defaultDeviceSettings)
        if(response.data.data.devices !== null) {
          return response.data.data.devices
        }

        return null
      }).catch(() => {
        return null
      })
    },
    fetchCustomerByPhone({state}, phone) {
      const body = {
        query: 'query { customers { uuid, phone, address { name_line }, email }}'
      }

      return axios.post(state.graphUrl, body).then(response => {

        if(response.data.data.customers !== null) {
          return response.data.data.customers.find(customer => customer.phone === phone)
        }

        return null
      }).catch(() => {
        return null
      })
    },
    getCheckoutSectionInstancesByChannel({ state }, channel) {
      const body = {
        query: 'query { checkoutSectionInstances(organization: "' + state.organizationUUID + '", channel: "' + channel.uuid + '") { title, description, namespace, enabled, step }}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.checkoutSectionInstances
      })
    },
    updateChannel({ state }, channel) {
			const updateData = {
				uuid: channel.uuid,
				label: channel.label,
				description: channel.description
			}

			if(typeof channel.deliverSMSToCustomerOnOrderStateChanges !== 'undefined' && channel.deliverSMSToCustomerOnOrderStateChanges !== null) {
				updateData.deliverSMSToCustomerOnOrderStateChanges = channel.deliverSMSToCustomerOnOrderStateChanges
			}

			if(typeof channel.deliverSMSToCustomerOnOrderConfirmation !== 'undefined' && channel.deliverSMSToCustomerOnOrderConfirmation !== null) {
				updateData.deliverSMSToCustomerOnOrderConfirmation = channel.deliverSMSToCustomerOnOrderConfirmation
			}

			if(typeof channel.notifyCustomerOnStateChanges !== 'undefined' && Array.isArray(channel.notifyCustomerOnStateChanges)) {
				updateData.notifyCustomerOnStateChanges = channel.notifyCustomerOnStateChanges
			}

      const body = {
        query: 'mutation updateChannel($channel: UpdateChannel!) { updateChannel(channel: $channel) { label, description }}',
        variables: {
          channel: updateData
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.updateChannel
      })
    },
    getCheckoutSteps({ state }, channel) {
      const body = {
        query: 'query { checkoutSteps { title, description, namespace }}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.checkoutSteps
      })
    },
    sendPaylinkEmail({state}, {orderUUID, channelUUID, email, language, message}) {
      const body = {
        query : `mutation { sendPaylinkEmail(orderUUID: "${orderUUID}", channelUUID: "${channelUUID}",
          email: "${email}", language: "${language}", message: "${message}")}`
      }
      return axios.post(state.graphUrl, body).then(response => {

        return response.data.data.sendPaylinkEmail
      }).catch(() => {
        return null
      })
    },
    sendPaylinkSMS({state}, {orderUUID, channelUUID, phone, language, message}) {
      const body = {
        query : `mutation { sendPaylinkSMS(orderUUID: "${orderUUID}", channelUUID: "${channelUUID}",
          phone: "${phone}", language: "${language}", message: "${message}")}`
      }
      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.sendPaylinkSMS
      }).catch(() => {
        return null
      })
    },
    sendReceiptEmail({state}, {orderUUID, email, language}) {
      const body = {
        query : `mutation { sendReceipt(uuid: "${orderUUID}",
          email: "${email}", language: "${language}")}`
      }
      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.sendReceipt
      }).catch(() => {
        return null
      })
    },
    sendTicket({state}, bookingUUID, email) {
      let recipientEmail = ''
      if(email) {
        recipientEmail = email
      }
      const body = {
        query : `mutation { sendTicket(uuid: "${bookingUUID}", email: "${recipientEmail}")}`
      }
      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.sendTicket
      }).catch(() => {
        return null
      })
    },
    sendReservation({state}, bookingUUID, email) {
      let recipientEmail = ''
      if(email) {
        recipientEmail = email
      }
      const body = {
        query : `mutation { sendReservation(uuid: "${bookingUUID}", email: "${recipientEmail}")}`
      }
      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.sendReservation
      }).catch(() => {
        return null
      })
    },
    sendInvoice: ({state}, {email, message, invoice}) => {

      const body = {
        query: 'mutation sendInvoice ($email: String!, $message: String, $invoice: String!) { sendInvoice(email: $email, message: $message, invoice: $invoice) }',
        variables: {
          email: email,
          message: message,
          invoice: invoice.uuid
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.sendInvoice
      })
    },
    getNameBySSNFromNationalRegister: ({state}, ssn) => {
      const organizationUUID = state.organizationUUID
      return axios.get('https://europe-west1-salescloud.cloudfunctions.net/thjodskraLookup?kennitala=' + ssn + '&organization=' + organizationUUID).then(response => {
        return response.data
      }).catch(() => {
        return null
      })
    },
    getOrganizationTranslations: ({ state, commit }) => {
      const body= {
        query: '{\n' +
          '   organizationTranslations {\n' +
          '     _id\n' +
          '     uuid\n' +
          '     key\n' +
          '     value\n' +
          '     language\n' +
          '     type\n' +
          '   }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        const translationResponse = response.data.data.organizationTranslations
        if(translationResponse && Array.isArray(state.items)) {
          for(let i = 0; i < translationResponse.length; i++) {
            let translationItem = state.items.find(item => item.uuid === translationResponse[i].uuid)
            if(typeof translationItem === 'undefined' || translationItem === null) {
              translationItem = state.categories.find(item => item.uuid === translationResponse[i].uuid)
            }
            if(typeof translationItem === 'undefined' || translationItem === null) {
              translationItem = state.attributes.find(item => item.uuid === translationResponse[i].uuid)
            }
            if(typeof translationItem === 'undefined' || translationItem === null) {
              translationItem = state.variations.find(item => item.uuid === translationResponse[i].uuid)
            }
            translationResponse[i].item = translationItem
          }
          commit('setTranslations', translationResponse)
        }
        return translationResponse
      })
    },
    updateTranslation: ({ state, commit  }, translation) => {
      commit('updatingTranslation', true)

      const translationBody = {
        uuid: translation.uuid,
        key: translation.key,
        language: translation.language,
        type: translation.type,
        value: translation.value
      }

      let body = {
        query: 'mutation AddTranslation($translationBody: NewTranslation!) {\n' +
          ' addTranslation(translation: $translationBody) \n' +
          '}',
        variables: {
          translationBody
        }
      }
      return axios.post(state.graphUrl, body).then(response => {
        // dispatch('getOrganizationTranslations').then(() => {})
        return response.data.data.addTranslation
      }).finally(() => {
        commit('updatingTranslation', false)
      })
    },
    updateUserLanguage: ({state, commit}, language) => {
      const body = {
        query:
        'mutation {updateDefaultUserLanguage(languageKey: "' + language + '")}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.updateDefaultUserLanguage
      }).catch(() => {
        return null
      })
    },
    getCurrentSystemTime: ({ state, commit }) => {
      const body = {
        query: '{\n' +
          ' systemTimeInMillis\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data.systemTimeInMillis) {
          commit('updateSystemTime', result.data.data.systemTimeInMillis)
          return result.data.data.systemTimeInMillis
        } else {
          return null
        }
      })
    },
    processPaymentBatchItem: ({state}, paymentBatchItemUUID) => {

      const body = {
        query:
          'mutation {\n' +
          '  processPaymentBatchItem(uuid: "' + paymentBatchItemUUID + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.processPaymentBatchItem
      }).catch(() => {
        return null
      })
    },
    cancelPaymentBatchItem: ({state}, paymentBatchItemUUID) => {

      const body = {
        query:
          'mutation {\n' +
          '  cancelPaymentBatchItem(uuid: "' + paymentBatchItemUUID + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.cancelPaymentBatchItem
      }).catch(() => {
        return null
      })
    },
    pausePaymentBatch: ({state}, paymentBatchUUID) => {

      const body = {
        query:
          'mutation {\n' +
          '  pausePaymentBatch(uuid: "' + paymentBatchUUID + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.pausePaymentBatch
      }).catch(() => {
        return null
      })
    },
    resumePaymentBatch: ({state}, paymentBatchUUID) => {

      const body = {
        query:
          'mutation {\n' +
          '  resumePaymentBatch(uuid: "' + paymentBatchUUID + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.resumePaymentBatch
      }).catch(() => {
        return null
      })
    },
    cancelPaymentBatch: ({state}, paymentBatchUUID) => {

      const body = {
        query:
          'mutation {\n' +
          '  cancelPaymentBatch(uuid: "' + paymentBatchUUID + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.cancelPaymentBatch
      }).catch(() => {
        return null
      })
    },
    getPaymentBatchItems: ({state}, paymentBatchUUID) => {
      const body = {
        query:
          'query {\n' +
          '  paymentBatchItems(batch: "' + paymentBatchUUID + '") {\n' +
          '    uuid\n' +
          '    created\n' +
          '    changed\n' +
          '    state\n' +
          '    payment {\n' +
          '     status\n' +
          '     amount\n' +
          '     currency_code\n' +
          '     message\n' +
          '    }\n' +
          '    order {\n' +
          '     balance {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '     }\n' +
          '    }\n' +
          '    customer {\n' +
          '     address {\n' +
          '       name_line\n' +
          '     }\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.paymentBatchItems.sort((s1, s2) => s2.created - s1.created)
      })
    },
    getPaymentBatches: ({ state, commit }) => {
      const body = {
        query:
          'query {\n' +
          '  paymentBatches {\n' +
          '    uuid\n' +
          '    title\n' +
          '    description\n' +
          '    state\n' +
          '    created\n' +
          '    changed\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.paymentBatches.sort((s1, s2) => s2.created - s1.created)
      })
    },
    getPaymentMethodInstances: ({ state, commit }) => {
      const body = {
        query:
          'query {\n' +
          '  paymentMethodInstances(checkout: true, terminal: true) {\n' +
          '    uuid\n' +
          '    namespace\n' +
          '    title\n' +
          '    icon\n' +
          '    description\n' +
          '    status\n' +
          '    checkout\n' +
          '    terminal\n' +
          '    legacyId\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updatePaymentMethodInstances', result.data.data.paymentMethodInstances)
        }
      })
    },
    getCountries: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  countries {\n' +
          '    name\n' +
          '    code\n' +
          '  }\n' +
          '  defaultCountry {\n' +
          '    name\n' +
          '    code\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateCountries', result.data.data.countries)
          commit('updateDefaultCountry', result.data.data.defaultCountry)
        }
      })

    },
    getCustomersAndFop: ({commit, state}) => {
      const body = {
        query: 'query {\n' +
          '  customers {\n' +
          '    uuid\n' +
          '    customer_id\n' +
          '    label\n' +
          '    email\n' +
          '    phone\n' +
          '    ssn\n' +
          '    active\n' +
          '    exclusive\n' +
          '    invoiceable\n' +
          '    deliverInvoiceToInExchange\n' +
          '    deliverInvoice\n' +
          '    address {\n' +
          '     name_line\n' +
          '     locality\n' +
          '     thoroughfare\n' +
          '     postal_code\n' +
          '     country\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }
          return axios.post(state.graphUrl, body, {
            params: {
              time: new Date().getTime()
            }
          }).then(result => {
            if (result.data.data) {
              commit('updateCustomers', result.data.data.customers)
            }
            return result.data.data
          })
    },
    getCustomersMinified: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  customers {\n' +
          '    customer_id\n' +
          '    label\n' +
          '    email\n' +
          '    phone\n' +
          '    ssn\n' +
          '    active\n' +
          '    exclusive\n' +
          '    invoiceable\n' +
          '    deliverInvoiceToInExchange\n' +
          '    deliverInvoice\n' +
          '    address {\n' +
          '     name_line\n' +
          '     country\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateCustomers', result.data.data.customers)
        }
      })
    },
    getCustomersFragments: async ({ commit, dispatch }, { queryConditions }) => {
      const limit = 10000
      let skip = 0

      let customers = []
      let fetchedCustomers = await dispatch('getCustomers', { queryConditions, limit, skip })
      customers = customers.concat(...fetchedCustomers)
      while(Array.isArray(fetchedCustomers) && fetchedCustomers.length === limit) {
        fetchedCustomers = null
        skip += limit
        fetchedCustomers = await dispatch('getCustomers', { queryConditions, limit, skip })
        customers = customers.concat(...fetchedCustomers)
      }

      commit('updateCustomers', customers)

      return customers.sort((a, b) => a && a.address && b && b.address && a.address.name_line && a.address.name_line.localeCompare(b.address.name_line))
    },
    getCustomers: ({ commit, state }, { queryConditions, limit, skip }) => {
      // How do we check the payload for being undefined? actionName: (context, payload)

      let conditions = null
      if(typeof queryConditions !== 'undefined' && queryConditions !== null) {
        conditions = queryConditions
      }

      const body = {
        query: 'query customers($conditions: [QueryCondition], $limit: Int, $skip: Int) {\n' +
          '  customers(conditions: $conditions, limit: $limit, skip: $skip) {\n' +
          '    uuid\n' +
          '    customer_id\n' +
          '    label\n' +
          '    email\n' +
          '    phone\n' +
          '    ssn\n' +
          '    active\n' +
          '    exclusive\n' +
          '    invoiceable\n' +
          '    deliverInvoiceToInExchange\n' +
          '    deliverInvoice\n' +
          '    address {\n' +
          '     name_line\n' +
          '     country\n' +
          '    }\n' +
          '  }\n' +
          '}',
        variables: {
          conditions: conditions,
          limit,
          skip
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result && result.data && result.data.data) {
          if(!limit) {
            commit('updateCustomers', result.data.data.customers)
          }
          return result.data.data.customers
        }
      }).catch(() => {
        return []
      })
    },

    resumeSubscription: ({ state }, subscription) => {
      const body = {
        query: 'mutation {\n' +
          '  resumeSubscription(uuid: "' + subscription.uuid + '", nextPayment: ' + subscription.nextPayment + ')\n' +
          '}'
      }

      let graphUrl = state.graphUrl

      return axios.post(graphUrl, body).then(result => {
        return result.data.data.resumeSubscription
      })
    },
    pauseSubscription: ({ state }, subscription) => {

      const body = {
        query: 'mutation {\n' +
          '  pauseSubscription(uuid: "' + subscription.uuid + '")\n' +
          '}'
      }

      let graphUrl = state.graphUrl

      return axios.post(graphUrl, body).then(result => {
        return result.data.data.pauseSubscription
      })
    },
    expireSubscription: ({ state }, {uuid, expires}) => {

      const body = {
        query: 'mutation update($dm: UpdateRecurringSubscription!) {\n' +
          '  updateSubscription(subscription: $dm, notify: false) \n' +
          '}',
        variables: {
          dm: {
            uuid: uuid,
            expires: expires
          }
        },
      }

      let graphUrl = state.graphUrl

      return axios.post(graphUrl, body).then(result => {
        return result.data.data.updateSubscription
      })
    },
    cancelSubscription: ({ state }, subscription) => {

      const body = {
        query: 'mutation {\n' +
          '  cancelSubscription(uuid: "' + subscription.uuid + '")\n' +
          '}'
      }

      let graphUrl = state.graphUrl

      return axios.post(graphUrl, body).then(result => {
        return result.data.data.cancelSubscription
      })
    },
    getSubscriptionState: ({ state }, uuid) => {
      const body = {
        query: 'query subscriptionState($uuid: String!) {\n' +
          '  subscriptionState(uuid: $uuid) {\n' +
          '    uuid\n' +
          '    paidInFull\n' +
          '    lastPaymentAttempt\n' +
          '    paymentAttemptsCount\n' +
          '    message\n' +
          '  }\n' +
          '}',
        variables: {
          uuid: uuid
        }
      }

      let graphUrl = state.graphUrl

      return axios.post(graphUrl, body).then(result => {
        return result.data.data.subscriptionState
      })
    },
    getPayments: ({ commit, state }, payload) => {

      const sort = payload.sort || 'desc'
      const limit = payload.limit || 500
      const skip = payload.skip || 0

      const body = {
        query: 'query getPayments($conditions: [QueryCondition]) {\n' +
          '  payments(sort:' + sort + ', limit: ' + limit + ', skip: ' + skip + ', conditions: $conditions) {\n' +
          '    uuid\n' +
          '    amount\n' +
          '    currency_code\n' +
          '    status\n' +
          '    namespace\n' +
          '    created\n' +
          '    changed\n' +
          '    friendlyMessage\n' +
          '    entangleKey\n' +
          '    message\n' +
          '    orderId\n' +
          '    payload\n' +
          '    transactionId\n' +
          '    customer {\n' +
          '     uuid\n' +
          '     address {\n' +
          '       name_line\n' +
          '     }\n' +
          '    }\n' +
          '    instance {\n' +
          '     uuid\n' +
          '     title\n' +
          '     namespace\n' +
          '    }\n' +
          '    channel {\n' +
          '     uuid\n' +
          '     label\n' +
          '    }\n' +
          '    location {\n' +
          '     label\n' +
          '    }\n' +
          '    customer {\n' +
          '       address {\n' +
          '         name_line\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '}',
          variables: {
            conditions: payload.conditions
          }
      }

      return axios.post(state.graphUrl, body).then(result => {
        commit('updatePayments', result.data.data.payments)
        return result.data.data.payments
      })
    },
    creditPayment: ({state}, {uuid, amount}) => {

      const body = {
        query: 'mutation {\n' +
        '  creditPayment(uuid: "' + uuid + '", amount: ' + amount + '){\n' +
        '    uuid\n' +
        '    amount\n' +
        '    currency_code\n' +
        '    status\n' +
        '    namespace\n' +
        '    created\n' +
        '    changed\n' +
        '    friendlyMessage\n' +
        '    message\n' +
        '    orderId\n' +
        '    payload\n' +
        '    transactionId\n' +
        '    customer {\n' +
        '     address {\n' +
        '       name_line\n' +
        '     }\n' +
        '    }\n' +
        '    instance {\n' +
        '     namespace\n' +
        '    }\n' +
        '    channel {\n' +
        '     label\n' +
        '    }\n' +
        '    location {\n' +
        '     label\n' +
        '    }\n' +
        '  }\n' +
        '}\n'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.status !== 200) {
          return null
        }
        return result.data.data.creditPayment
      }).catch(() => {
        return null
      })
    },
    cancelPayment: ({state}, paymentUUID) => {

      const body = {
        query: 'mutation {\n' +
        '  cancelPayment(uuid: "' + paymentUUID + '"){\n' +
        '    uuid\n' +
        '    amount\n' +
        '    currency_code\n' +
        '    status\n' +
        '    namespace\n' +
        '    created\n' +
        '    changed\n' +
        '    friendlyMessage\n' +
        '    message\n' +
        '    orderId\n' +
        '    payload\n' +
        '    transactionId\n' +
        '    customer {\n' +
        '     address {\n' +
        '       name_line\n' +
        '     }\n' +
        '    }\n' +
        '    instance {\n' +
        '     namespace\n' +
        '    }\n' +
        '    channel {\n' +
        '     label\n' +
        '    }\n' +
        '    location {\n' +
        '     label\n' +
        '    }\n' +
        '  }\n' +
        '}\n'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.status !== 200) {
          return null
        }
        return result.data.data.cancelPayment
      })
    },
    getFormsOfPayment: ({ commit, state },  {conditions}) => {
      return axios.post(state.graphUrl, {
        query: 'query formsOfPaymentQuery($conditions: [QueryCondition]){\n' +
          '  formsOfPayment (conditions: $conditions){\n' +
          '       uuid ' +
          '       amount ' +
          '       remoteID ' +
          '       cardType ' +
          '       cardName ' +
          '       expiryYear ' +
          '       expiryMonth ' +
          '       created ' +
          '       changed ' +
          '       customer {\n' +
          '         customer_id' +
          '         email' +
          '         phone' +
          '         address {' +
          '           name_line ' +
          '         }\n' +
          '       }\n' +
          '       paymentMethod\n' +
          '       paymentMethodInstance {\n' +
          '         uuid' +
          '         namespace' +
          '         title' +
          '       }\n' +
          '       active ' +
          '       publicUrl ' +
					'				item {\n' +
					'					title\n' +
					'				}\n' +
          '  } \n' +
          '}',
        variables: {
          conditions: conditions
        }
      }).then(response => {
        commit('updateFormsOfPayment', response.data.data.formsOfPayment)
        return response.data.data.formsOfPayment
      })
    },
    getPointCards: ({ commit, state }) => {
      const body = {
        query: 'query {' +
            ' pointCards {' +
            '   uuid' +
            '   amount' +
            '   active' +
            '   expiryYear' +
            '   expiryMonth' +
            '   cardName' +
            '   cardType' +
            '   created' +
            '   changed' +
            '   remoteID' +
            '   customer {' +
            '     customer_id' +
            '     email' +
            '     phone' +
            '     address {' +
            '       name_line' +
            '     }' +
            '   }' +
            ' }' +
            '}'
      }

      return axios.post(state.graphUrl, body)
          .then(response => {
            commit('updatePointCards', response.data.data.pointCards)
            return response.data.data.pointCards
          })
          .catch(() => {
            throw new Error('Something went wrong while fetching point cards')
          })
    },
    enableFormOfPayment: ({ state, dispatch }, formOfPaymentUUID) => {
      const body = {
        query: 'mutation UpdatedFormOfPayment($updatedGiftCard: UpdateGiftcard!) {\n' +
            ' updateGiftcard(giftcard: $updatedGiftCard) {\n' +
            '   remoteID\n' +
            ' }\n' +
            '}',
        variables: {
          updatedGiftCard: {
            uuid: formOfPaymentUUID,
            active: true
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return dispatch('getFormsOfPayment', {}).then(() => {
          return result
        })
      })
    },
    updateBooleanDeviceSetting: ({ state }, booleanDeviceSetting) => {

      const body = {
        query: 'mutation {\n' +
            ' updateBooleanDeviceSetting(device: "' + booleanDeviceSetting.device + '", key: "' + booleanDeviceSetting.key + '", value: ' + booleanDeviceSetting.value + ')' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.updateBooleanDeviceSetting
      })
    },
    createPointCard: ({state, dispatch}, {pc}) => {

      const body = {
        query: 'mutation ($newPointCard: NewPointCard!) {\n' +
          '  createPointCard(newPointCard: $newPointCard) {\n' +
          '   remoteID\n' +
          ' }\n' +
          '}',
        variables: {
          newPointCard: pc
        }
      }

     return axios.post(state.graphUrl, body).then(result => {
        if(result) {
          return dispatch('getPointCards').then(() => {
            return result.data.data
          })
        }
      }).catch(e => {
        console.error(e)
      })
    },
    enablePointCard: ({state, dispatch}, pointCardUUID) => {
      const body = {
        query: 'mutation UpdatePointCard($updatedPointCard: UpdatedPointCard!) {\n' +
          ' updatePointCard(updatedPointCard: $updatedPointCard) {\n' +
          '   uuid\n' +
          '   active\n' +
          '   remoteID\n' +
          ' }\n' +
          '}',
        variables: {
          updatedPointCard: {
            uuid: pointCardUUID,
            active: true,
            status: true
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return dispatch('getPointCards').then(() => {
          return result
        })
      })
    },
    disablePointCard: ({state, dispatch}, pointCardUUID) => {
      const body = {
        query: 'mutation UpdatePointCard($updatedPointCard: UpdatedPointCard!) {\n' +
          ' updatePointCard(updatedPointCard: $updatedPointCard) {\n' +
          '   uuid\n' +
          '   active\n' +
          '   remoteID\n' +
          ' }\n' +
          '}',
        variables: {
          updatedPointCard: {
            uuid: pointCardUUID,
            active: false,
            status: false
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return dispatch('getPointCards').then(() => {
          return result
        })
      })
    },
    editPointCard: ({state, dispatch}, { pointCardUUID, amount, cardName, expiryMonth, expiryYear }) => {

      const body = {
        query: 'mutation UpdatePointCard($updatedPointCard: UpdatedPointCard!) {\n' +
          ' updatePointCard(updatedPointCard: $updatedPointCard) {\n' +
          '   uuid\n' +
          '   active\n' +
          '   remoteID\n' +
          ' }\n' +
          '}',
        variables: {
          updatedPointCard: {
            uuid: pointCardUUID,
            amount: amount,
            cardName: cardName,
            expiryMonth: expiryMonth,
            expiryYear: expiryYear
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return dispatch('getPointCards').then(() => {
          return result
        })
      })

    },
    disableFormOfPayment: ({ state, dispatch }, formOfPaymentUUID) => {
      const body = {
        query: 'mutation UpdatedFormOfPayment($updatedGiftCard: UpdateGiftcard!) {\n' +
            ' updateGiftcard(giftcard: $updatedGiftCard) {\n' +
            '   remoteID\n' +
            ' }\n' +
            '}',
        variables: {
          updatedGiftCard: {
            uuid: formOfPaymentUUID,
            active: false
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return dispatch('getFormsOfPayment', {}).then(() => {
          return result.data.data.updateGiftcard
        })
      })
    },
    editGiftCard: ({state, dispatch}, { giftCardUUID, expiryMonth, expiryYear }) => {

      const body = {
        query: 'mutation UpdateGiftCard($updatedGiftCard: UpdateGiftcard!) {\n' +
          ' updateGiftcard(giftcard: $updatedGiftCard) {\n' +
          '   remoteID\n' +
          ' }\n' +
          '}',
        variables: {
          updatedGiftCard: {
            uuid: giftCardUUID,
            expiryMonth: expiryMonth,
            expiryYear: expiryYear
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result) {
          return dispatch('getFormsOfPayment', {}).then(() => {
            return result.data.data.updateGiftcard
          })
        }

        return result.data.data.updateGiftcard

      }).catch(e => {
        return null
      })
    },
    addAmountToGiftcard: ({ state }, { giftcardUUID, amount }) => {
      if(typeof giftcardUUID === 'undefined' || giftcardUUID === null) {
        return null
      }

      if(typeof amount === 'undefined' || amount === null || isNaN(Number(amount))) {
        return null
      }

      const body = {
        query: 'mutation AddAmountToGiftcard($uuid: String!, $amount: Float!) {\n' +
            ' addAmountToGiftcard(uuid: $uuid, amount: $amount) {\n' +
            '   remoteID\n' +
            ' }\n' +
            '}',
        variables: {
          uuid: giftcardUUID,
          amount: Number(amount)
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (!result) {
          return null
        }
        return result.data.data.addAmountToGiftcard
      }).catch(() => {
        return null
      })
    },
    createMultipleGiftcards: ({state, dispatch}, {giftcard, quantity}) => {

      const body = {

        query: 'mutation ($giftcard: NewGiftcards, $quantity: Int) {\n' +
          '  createMultipleGiftcards(giftcard: $giftcard, quantity: $quantity) {\n' +
          '   remoteID\n' +
          '   uuid\n' +
          ' }\n' +
          '}',
        variables: {
          giftcard: giftcard,
          quantity: quantity
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result) {
          return dispatch('getFormsOfPayment', {}).then(() => {
            return result.data.data
          })
        }
      })
    },
    createGiftcard: ({state, dispatch}, {fop}) => {

      try {
        fop.amount = parseFloat(fop.amount.toString())
      } catch (e) {
        fop.amount = 0.000
      }

      try {
        fop.remoteID = fop.remoteID.toUpperCase()
      } catch (e) {
      }

      try {
        let uuid = fop.customer.uuid
        fop.customer = uuid
      } catch (e) {

      }

      const body = {
        query: 'mutation ($giftcard: NewGiftcard!) {\n' +
          '  createGiftcard(giftcard: $giftcard) {\n' +
          '   remoteID\n' +
          ' }\n' +
          '}',
        variables: {
          giftcard: fop
        }
      }

     return axios.post(state.graphUrl, body).then(result => {
        if(result) {
          return dispatch('getFormsOfPayment', {}).then(() => {
            return result.data.data
          })
        }
      })
    },

    sendGiftcardEmail: ({state}, {uuid, email}) => {

      const body = {
        query : `mutation { sendGiftcardEmail(giftcardUUID: "${uuid}", email: "${email}")}`
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.sendGiftcardEmail
      }).catch(() => {
        return null
      })
    },
    sendCouponEmail: ({state}, {uuid, email}) => {

      const body = {
        query : `mutation { sendCouponEmail(uuid: "${uuid}", email: "${email}")}`
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.sendCouponEmail
      }).catch(() => {
        return null
      })
    },
    resetPassword: ({ state }, payload) => {

      const body = {
        query: 'mutation {\n' +
          '  resetPasswordByEmail(email: "' + payload.email + '") \n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.resetPassword
      })

    },
    getActiveWork: ({state}) => {
      const body = {
        query: 'query {\n' +
          '  activeWork (organization: "' + state.organizationUUID + '") {\n' +
          '    uuid\n' +
          '    label\n' +
          '    type\n' +
          '    staff {\n' +
          '      uuid\n' +
          '      person_id\n' +
          '      ssn\n' +
          '      address {\n' +
          '        name_line\n' +
          '      }\n' +
          '    }\n' +
          '    start_date\n' +
          '    end_date\n' +
          '    standard_hours\n' +
          '    evening_hours\n' +
          '    weekend_hours\n' +
          '    total_hours\n' +
          '  }' +
          '}'
      }
      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.activeWork
      })
    },
    getWorkByStaff: ({ state }, payload) => {

      const body = {
        query: 'query work($conditions: [QueryCondition]) {\n' +
          '  work (startTimeInMillis: ' + payload.startTimeInMillis  + ', endTimeInMillis: ' + payload.endTimeInMillis + ', conditions: $conditions, sort: desc) {\n' +
          '    staff {\n' +
          '      uuid\n' +
          '      person_id\n' +
          '      ssn\n' +
          '      address {\n' +
          '        name_line\n' +
          '      }\n' +
          '      employment\n' +
          '    }\n' +
          '    start_date\n' +
          '    end_date\n' +
          '    standard_hours\n' +
          '    evening_hours\n' +
          '    weekend_hours\n' +
          '    festive_hours\n' +
          '    public_holiday_hours\n' +
          '    total_hours\n' +
          '    uuid\n' +
          '    status\n' +
          '    type\n' +
          '    changed\n' +
          '    requiresApproval\n' +
          '  }' +
          '}',
        variables: {
          conditions: [
            {
              operator: 'equals',
              property: 'staff.uuid',
              value: payload.uuid
            }
          ]
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.work
      })
    },
    getStreamEntries: ({ state }, payload) => {

      const body = {
        query: 'query {\n' +
          '  stream {\n' +
          '    timestampInMillis\n' +
          '    namespace\n' +
          '    message\n' +
          '  }' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.stream
      }).catch(() => {
        return null
      })
    },
    getWork: ({ commit, state }, payload) => {

      const body = {
        query: 'query {\n' +
          '  work (startTimeInMillis: ' + payload.startTimeInMillis  + ', endTimeInMillis: ' + payload.endTimeInMillis + ', sort: desc) {\n' +
          '    staff {\n' +
          '      uuid\n' +
          '      person_id\n' +
          '      ssn\n' +
          '      address {\n' +
          '        name_line\n' +
          '      }\n' +
          '      employment\n' +
          '    }\n' +
          '    start_date\n' +
          '    end_date\n' +
          '    standard_hours\n' +
          '    evening_hours\n' +
          '    weekend_hours\n' +
          '    festive_hours\n' +
          '    public_holiday_hours\n' +
          '    total_hours\n' +
          '    uuid\n' +
          '    status\n' +
          '    type\n' +
          '    changed\n' +
          '    requiresApproval\n' +
          '  }' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateWork', result.data.data.work)
					if (typeof payload.returnWork === "boolean" && payload.returnWork === true) {
						return result.data.data.work
					}
        }
      })

    },
    approveWork: ({ state }, work) => {
      const body = {
        query: 'mutation approveWork($o: String!, $w: String!) {\n' +
          ' approveWork(organization: $o, workId: $w)\n' +
          '}',
        variables: {
          o: state.organization.uuid,
          w: work.uuid
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.approveWork
      })
    },
    cancelWork({ state }, work) {
      const response = {
        success: false,
        message: i18n.t('startCancellingWork')
      }

      if(typeof work === 'undefined' || work === null) {
        return response
      }
      response.message = i18n.t('startCancellingWorkWithId', {id: work.uuid})

      const body = {
        query: `mutation CancelWork($organization: String!, $workId: String!) {\n
          cancelWork(organization: $organization, workId: $workId) {\n
            success
            message
            text
          }\n
        }`,
        variables: {
          organization: state.organization.uuid,
          workId: work.uuid
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data && result.data.data.cancelWork) {
          return result.data.data.cancelWork
        }
        response.message = i18n.t('workIdIdWasNotCancelled', {id: work.uuid})
        return response
      }).catch(error => {
        if(error) {
          if(error.response) {
            response.message = error.message
            return response
          } else if (error.request) {
            response.message = i18n.t('didNotGetAResponseFromServeIfThisProblemPersistsPleaseContactSupport')
            return response
          } else {
            response.message = i18n.t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
            response.text = error.message
            return response
          }
        }
      })
    },
    createPuck: ({ state }, puck) => {

      const body = {
        query: 'mutation ($puck: NewPuck!) {\n' +
          '  createPuck(puck: $puck) {\n' +
          '    uuid\n' +
          '    name\n' +
          '    active\n' +
          '  }\n' +
          '}',
        variables: {
          puck: puck
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.createPuck
      })
    },
    getPucks: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  pucks {\n' +
          '    uuid\n' +
          '    name\n' +
          '    active\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updatePucks', result.data.data.pucks)
        }
      })
    },
    getStaff: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  staff {\n' +
          '    uuid\n' +
          '    person_id\n' +
          '    active\n' +
          '    phone\n' +
          '    email\n' +
          '    is_pos_admin\n' +
          '    allowed_discounts\n' +
          '    pin\n' +
          '    ssn\n' +
          '    hideInEpos\n' +
          '    address {\n' +
          '     name_line\n' +
          '    }\n' +
          '    standardRate {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    eveningRate {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    weekendRate {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    publicHolidayRate {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    majorPublicHolidayRate {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {

        if (result.data.data) {
          commit('updateStaff', result.data.data.staff)
        }

        return result.data.data.staff
      })
    },
    createCategory: ({ state, dispatch }, category) => {

      let categoryToCreate = category

      // TODO: Fix once we figure out how we want to upload images in new admin
      delete categoryToCreate.image

      // Making sure weight is an integer
      categoryToCreate.weight = categoryToCreate.weight * 1

      if(Array.isArray(categoryToCreate.parent)) {
        // Making sure we do not send in empty array because it may mess up the category in legacy
        if(categoryToCreate.parent.length < 1) {
          categoryToCreate.parent = null
        } else {
          // We need to remove legacy id from each parent category because the schema does not expect it
          categoryToCreate.parent.forEach(parentCategory => {
            if(parentCategory) {
              delete parentCategory.legacyId
            }
          })
        }
      }

      return axios.post(state.graphUrl, {
        query: 'mutation createCategory($dm: CreateCategory!){\n' +
          '  createCategory(category:$dm) {\n' +
          '       uuid ' +
          '       name ' +
          '  } \n' +
          '}',
        variables: {
          dm: categoryToCreate
        },
      }).then(response => {
        let createCategoryResponse = response.data.data.createCategory
        return dispatch('getCategories').then(() => {
          return createCategoryResponse
        })
      })
    },
    updateCategory: ({ state, dispatch }, category) => {

      let categoryToUpdate = category

      // TODO: Fix once we figure out how we want to upload images in new admin
      delete categoryToUpdate.image
      delete categoryToUpdate.legacyId

      // Making sure weight is an integer
      categoryToUpdate.weight = categoryToUpdate.weight * 1

      if(Array.isArray(categoryToUpdate.parent)) {
        // Making sure we do not send in empty array because it may mess up the category in legacy
        if(categoryToUpdate.parent.length < 1) {
          categoryToUpdate.parent = null
        } else {
          // We need to remove legacy id from each parent category because the schema does not expect it
          categoryToUpdate.parent.forEach(parentCategory => {
            if(parentCategory) {
              delete parentCategory.legacyId
            }
          })
        }
      }

      return axios.post(state.graphUrl, {
        query: 'mutation updateCategory($dm: UpdateCategory!){\n' +
          '  updateCategory(category:$dm) {\n' +
          '       uuid ' +
          '       name ' +
          '  } \n' +
          '}',
        variables: {
          dm: categoryToUpdate
        },
      }).then(response => {
        let updateCategoryResponse = response.data.data.updateCategory
        return dispatch('getCategories').then(() => {
          return updateCategoryResponse
        })
      })
    },
		sendDashReport: ({state}, payload) => {
			const body = {
        query:
          'mutation sendDashCollectionToEmailRecipient($uuid: String!, $startTimeInMillis: Float!, $endTimeInMillis: Float!, $email: String!) {\n' +
          '   sendDashCollectionToEmailRecipient(uuid: $uuid, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis, email: $email)\n' +
          '}',
        variables: {
          uuid: payload.dashCollectionUUID,
					startTimeInMillis: payload.startTimeInMillis,
					endTimeInMillis: payload.endTimeInMillis,
					email: payload.email
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
				return response.data.data.sendDashCollectionToEmailRecipient
			})
		},
    updateCategoriesOrder: ({ state, dispatch }, categories) => {

      const body = {
        query:
          'mutation updateCategoriesOrder($categories: [UpdateCategory!]!) {\n' +
          '   updateCategoriesOrder(categories: $categories) {\n' +
          '      uuid\n' +
          '      name\n' +
          '      weight\n' +
          '   }\n' +
          '}',
        variables: {
          categories: categories
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
          const categoriesOrder = response.data.data.updateCategoriesOrder
          if(categoriesOrder) {
            return dispatch('getCategories').finally(() => { return categoriesOrder })
          }
        })
    },
    getCategories: ({ state, commit }) => {
      let body = {
        query: 'query {\n'+
          '  categories(type: "products") {\n' +
          '    name\n' +
          '    uuid\n' +
          '    weight\n' +
          '    description\n' +
          '    required\n' +
          '    single\n' +
          '    administrative\n' +
          '    ept\n' +
          '    legacyId\n' +
          '    primary_color\n' +
          '    allowSplitByHalf\n' +
          '    accountingKey\n' +
          '    parent{\n' +
          '       uuid\n' +
          '       name\n' +
          '    }\n' +
          '    opening_hours{\n' +
          '       day\n' +
          '       starthours\n' +
          '       endhours\n' +
          '       comment\n' +
          '    }\n' +
          '    image {\n' +
          '       title\n' +
          '       alt\n' +
          '       file{\n' +
          '         sources{\n' +
          '           original\n' +
          '         }\n' +
          '       }\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          commit('updateCategories', response.data.data.categories)
          return response.data.data.categories
        })
    },
    getItems: ({ state, commit }) => {

      const body = {
        query: 'query {\n' +
          '  items {\n' +
          '    uuid\n' +
          '    title\n' +
          '    sku\n' +
          '    barcode\n' +
          '    type\n' +
          '    exclude_discounts\n' +
          '    accountingKey\n' +
          '    payWhatYouWant\n' +
          '    popup\n' +
          '    status\n' +
          '    changed\n' +
          '    uiValues\n' +
          '    minimumOrderQuantity\n' +
          '    maximumOrderQuantity\n' +
          '    maximumGroupedQuantity\n' +
          '    durationInMinutes\n ' +
          '    defaultQuantity\n' +
          '    startDate\n' +
          '    endDate\n' +
          '    availabilityMode\n' +
          '    redeemableItems\n' +
          '    max_redemptions\n' +
          '    validity\n' +
          '    sorting_weight\n' +
          '    payment_instance\n' +
          '    send_to_customer\n' +
					'    locationPrices {\n' +
					'    	location\n' +
					'    	price {\n' +
					'    		amount\n' +
					'    		currency_code\n' +
					'    	}\n' +
					'    }\n' +
					'    locationQuantities {\n' +
					'    	location\n' +
					'    	quantity\n' +
					'    }\n' +
          '    mainCategory {\n' +
          '      uuid\n' +
					'      name\n' +
          '    }\n' +
          '    description {\n' +
          '      format\n' +
          '      summary\n' +
          '      value\n' +
          '    }\n' +
          '    availability {\n' +
          '      current\n' +
          '      target\n' +
          '      threshold\n' +
          '      infinite\n' +
          '    }\n' +
          '    sale_price {\n' +
          '      amount\n' +
          '      currency_code\n' +
          '    }\n' +
          '    cost_price {\n' +
          '      amount\n' +
          '      currency_code\n' +
          '    }\n' +
          '    images {\n' +
          '      file {\n' +
          '        sources {\n' +
          '          thumbnail\n' +
          '          original\n' +
          '          large\n' +
          '        }\n' +
          '      }\n' +
          '    }\n' +
          '    variations {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    default_variations {\n' +
          '      uuid\n' +
          '      label\n' +
          '      attribute{\n' +
          '        name\n' +
          '      }\n' +
          '      price{\n' +
          '        amount\n' +
          '      }\n' +
          '    }\n' +
          '    variation_sets {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    categories {\n' +
          '      name\n' +
          '      uuid\n' +
          '    }\n' +
          '    price {\n' +
          '      amount\n' +
          '      currency_code\n' +
          '      taxRate {\n' +
          '       uuid\n' +
          '      }\n' +
          '      data {\n' +
          '         include_tax\n' +
          '      }\n' +
          '    }\n' +
          '    location {\n' +
          '      uuid\n' +
          '      label\n' +
          '      active\n' +
          '    }\n' +
          '    categories {\n' +
          '      name\n' +
          '      uuid\n' +
          '    }\n' +
          '    legacyId\n' +
          '    isAutoRenewalEnabled\n' +
          '    sellOnline\n' +
          '    sellInStore\n' +
          '    sellInEtags\n' +
          '    sellInKiosk\n' +
          '    sellInLocations {\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    weekdayPrices {\n' +
          '       weekday\n' +
          '       hourStart\n' +
          '       hourEnd\n' +
          '       price {\n' +
          '         amount\n' +
          '         currency_code\n' +
          '       }\n' +
          '       active\n' +
          '    }\n' +
          '    seasonalPrices {\n' +
          '       startInMillis\n' +
          '       endInMillis\n' +
          '       price {\n' +
          '         amount\n' +
          '         currency_code\n' +
          '       }\n' +
          '       active\n' +
          '    }\n' +
          '    weekdayDurations {\n' +
          '       weekday\n' +
          '       hourStart\n' +
          '       hourEnd\n' +
          '       durationInMinutes\n' +
          '       active\n' +
          '    }\n' +
          '    seasonalDurations {\n' +
          '       startInMillis\n' +
          '       endInMillis\n' +
          '       durationInMinutes\n' +
          '       active\n' +
          '    }\n' +
          '    isDeleted\n' +
          '    openingHours {\n' +
          '       day\n' +
          '       starthours\n' +
          '       endhours \n' +
          '       comment \n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {

        if (result.data.data) {
          commit('updateItems', result.data.data.items)
        }

        return result.data.data.items
      })
    },
    createItem: ({ state, dispatch }, item) => {

      delete item.uiValues
      delete item.images

      return axios.post(state.graphUrl, {
        query: 'mutation createItem($dm: NewItem!) {\n' +
          '   createItem(item: $dm) {\n' +
          '     uuid\n' +
          '     title\n' +
          '     status\n' +
          '   }\n' +
          '}',
        variables: {
          dm: item
        }
      }).then(response => {
        let newItem = response.data.data.createItem
        if (newItem) {
          return dispatch('getItems').then(() => {
            return newItem
          })
        }
      })
    },
    bulkUpdateItem: ({ state, dispatch }, item) => {

      const itemToUpdate = {
        uuid: item.uuid
      }

      if(item.title) {
        itemToUpdate.title = item.title.trim()
      }

      if(item.sku) {
        itemToUpdate.sku = item.sku.trim()
      }

      if(Array.isArray(item.categories) && item.categories.length > 0) {
        // Using for-loop to get category uuids allows us to jump over null values, which might cause faults in PAAS
        // The same effect could have been achieved with map and filter
        itemToUpdate.categories = []
        for(let categoryIndex = 0; categoryIndex < item.categories.length; categoryIndex++) {
          const category = item.categories[categoryIndex]

          // We do not to send an empty array value, e.g. [null, null]
          if(typeof category !== 'undefined' && category !== null) {
            // Making sure to handle if item payload includes an array of category objects rather than array of category uuids
            if(typeof category !== 'string' && typeof category.uuid !== 'undefined') {
              itemToUpdate.categories.push(category.uuid)
            } else {
              // Here we assume `category` is a uuid string
              itemToUpdate.categories.push(category)
            }
          }
        }
      }

      if(item.seasonalPrices) {
        itemToUpdate.seasonalPrices = item.seasonalPrices
      }

      if(item.weekdayPrices) {
        itemToUpdate.weekdayPrices = item.weekdayPrices
      }

      if(item.openingHours) {
        itemToUpdate.openingHours = item.openingHours
      }

			if(item.sellOnline !== null && item.sellOnline !== undefined) {
				itemToUpdate.sellOnline = item.sellOnline
			}

			if(item.sellInStore !== null  && item.sellInStore !== undefined) {
				itemToUpdate.sellInStore = item.sellInStore
			}

      if(item.sellInKiosk !== null && item.sellInKiosk !== undefined) {
        itemToUpdate.sellInKiosk = item.sellInKiosk
      }

			if(item.sellInEtags !== null && item.sellInEtags !== undefined) {
				itemToUpdate.sellInEtags = item.sellInEtags
			}

			if(item.locationPrices !== null && item.locationPrices !== undefined && item.locationPrices.length > 0) {

				for(let i in item.locationPrices) {
					if(typeof item.locationPrices[i].price.amount === 'string') {
						item.locationPrices[i].price.amount = parseFloat(item.locationPrices[i].price.amount)
					}
				}

				itemToUpdate.locationPrices = item.locationPrices
			}

			if(item.locationQuantities !== null && item.locationQuantities !== undefined && item.locationQuantities.length > 0) {

				for(let i in item.locationQuantities) {
					if(typeof item.locationQuantities[i].quantity === 'string') {
						item.locationQuantities[i].quantity = parseInt(item.locationQuantities[i].quantity)
					}
				}

				itemToUpdate.locationQuantities = item.locationQuantities
			}

      if(!item.sellInLocations) {
        itemToUpdate.sellInLocations = []
      }

      if(Array.isArray(item.sellInLocations)) {
				let sellInLocations = []
        if(item.sellInLocations.length > 0) {
          if(typeof item.sellInLocations[0].uuid !== 'undefined') {
            sellInLocations = item.sellInLocations.map(l => {
              if(l) {
                return l.uuid
              }
              return null
            }).filter(notNull => notNull)
          } else {
            sellInLocations = item.sellInLocations
          }
        }
				itemToUpdate.sellInLocations = sellInLocations
      }

			if(itemToUpdate.isDeleted !== null && itemToUpdate.isDeleted !== undefined) {
				itemToUpdate.isDeleted = item.isDeleted
			}

			if(item.price && item.price.amount) {

				itemToUpdate.price = item.price
				itemToUpdate.price.amount = parseFloat(item.price.amount)

				if(!item.price.currency_code) {
					itemToUpdate.price.currency_code = 'ISK'
				}else {
          itemToUpdate.price.currency_code = item.price.currency_code
        }

        // We do not update prices without taxRates.
        if (item.price.taxRate) {
          itemToUpdate.price.taxRate = item.price.taxRate
        }else {
          return null
        }

			}

      if(typeof item.status === 'boolean') {
        itemToUpdate.status = item.status 
      }

      if(item.sale_price !== null && item.sale_price !== undefined && item.sale_price.amount && item.sale_price.taxRate) {
        itemToUpdate.sale_price = {
          amount: parseFloat(item.sale_price.amount),
          taxRate: item.sale_price.taxRate
        }

        if(!item.sale_price.currency_code) {
					itemToUpdate.sale_price.currency_code = 'ISK'
				}else {
          itemToUpdate.sale_price.currency_code = item.sale_price.currency_code
        }
      }

      if(item.cost_price !== null && item.cost_price !== undefined && item.cost_price.amount && item.cost_price.taxRate) {
        itemToUpdate.cost_price = {
          amount: parseFloat(item.cost_price.amount),
          taxRate: item.cost_price.taxRate
        }

        if(!item.cost_price.currency_code) {
					itemToUpdate.cost_price.currency_code = 'ISK'
				}else {
          itemToUpdate.cost_price.currency_code = item.cost_price.currency_code
        }
      }

      if (item.description) {
        itemToUpdate.description = item.description
      }
    
      // Make sure to re-save the image if it is a cloudinary image and therefore not at all connected to the legacy system. Without this line we would overwrite the image to null when saving the item in legacy.
      if (!item.imageUrl && Array.isArray(item.images) && item.images.length > 0 && item.images[0].file?.sources?.original && item.images[0].file?.sources?.original.includes('https://res.cloudinary.com/salescloud/')) {
        itemToUpdate.imageUrl = item.images[0].file.sources.original
      }

      return axios.post(state.graphUrl, {
        query: 'mutation updateItem($dm: UpdateItem){\n' +
          '  updateItem(item:$dm) {\n' +
          '       uuid\n' +
          '       title\n' +
          '       status\n' +
          '       weekdayPrices {\n' +
          '         weekday\n' +
          '         hourStart\n' +
          '         hourEnd\n' +
          '         price {\n' +
          '           amount\n' +
          '           currency_code\n' +
          '         }\n' +
          '         active\n' +
          '       }\n' +
          '       seasonalPrices {\n' +
          '         startInMillis\n' +
          '         endInMillis\n' +
          '         price {\n' +
          '           amount\n' +
          '           currency_code\n' +
          '         }\n' +
          '         active\n' +
          '       }\n' +
          '  } \n' +
          '}',
        variables: {
          dm: itemToUpdate
        },
      }).then(response => {
        dispatch('getItems')
        return response.data.data.updateItem
      })

    },
    updateItem: async ({ state, dispatch }, item) => {

      const itemToUpdate = {
        uuid: item.uuid,
        legacyId: item.legacyId,

        type: item.type,

        title: item.title ? item.title.trim() : undefined,
        sku: item.sku ? item.sku.trim() : undefined,

        description: item.description,

        categories: Array.isArray(item.categories) ? item.categories.filter(cat => cat !== null && cat !== undefined).map(cat => typeof cat === 'string' ? cat : cat.uuid) : [],

        seasonalPrices: item.seasonalPrices,
        weekdayPrices: item.weekdayPrices,
        openingHours: item.openingHours,

        imageUrl: item.imageUrl,

        sellOnline: typeof item.sellInEtags === 'boolean' ? item.sellOnline : true,
        sellInStore: typeof item.sellInEtags === 'boolean' ? item.sellInStore : true,
        sellInEtags: typeof item.sellInEtags === 'boolean' ? item.sellInEtags : false,
        sellInKiosk: typeof item.sellInKiosk === 'boolean' ? item.sellInKiosk : true,

        locationPrices: item.locationPrices && item.locationPrices.length > 0 ? item.locationPrices.map(loc => {
          if (!isNaN(parseFloat(loc.price.amount))) {
            loc.price.amount = parseFloat(loc.price.amount)
          } else {
            loc.price.amount = null
          }
          return loc
        }) : [],

        sellInLocations: Array.isArray(item.sellInLocations) ? item.sellInLocations.filter(loc => loc !== null && loc !== undefined).map(location => typeof location === 'string' ? location : location.uuid) : [],

        isDeleted: typeof item.isDeleted === 'boolean' ? item.isDeleted : undefined,

        price: item.price?.amount ? item.price : undefined,

        sale_price: item.sale_price ? { ...item.sale_price, taxRate: item.price.taxRate } : undefined,
        cost_price: item.cost_price ? { ...item.cost_price, taxRate: item.price.taxRate } : undefined,

        status: typeof item.status === 'boolean' ? item.status : true,
        exclude_discounts: typeof item.exclude_discounts === 'boolean' ? item.exclude_discounts : false,

        removeLegacyImage: typeof item.removeLegacyImage === 'boolean' ? item.removeLegacyImage : false,

        accountingKey: item.accountingKey ? item.accountingKey : undefined,
        barcode: item.barcode ? item.barcode : undefined,

        mainCategory: typeof item.mainCategory === 'string' ? item.mainCategory : typeof item.mainCategory === 'object' && item.mainCategory !== null ? item.mainCategory.uuid : undefined,
      }

      // Two item types that support variations
      if (item.type === 'product') {
        itemToUpdate.variation_sets = Array.isArray(item.variation_sets) ? item.variation_sets.filter(set => set !== null && set !== undefined).map(set => typeof set === 'string' ? set : set.uuid) : []
        itemToUpdate.default_variations = Array.isArray(item.default_variations) ? item.default_variations.filter(defaultVariation => defaultVariation !== null && defaultVariation !== undefined).map(defaultVariation => typeof defaultVariation === 'string' ? defaultVariation : defaultVariation.uuid).filter(variation => itemToUpdate.variation_sets.includes(state.variations.find(defaultVaration => defaultVaration?.uuid === variation).owner_id)) : []
        
        itemToUpdate.popup = typeof item.popup === 'boolean' ? item.popup : false
        itemToUpdate.payWhatYouWant = typeof item.payWhatYouWant === 'boolean' ? item.payWhatYouWant : false
      }

      if (item.type === 'event') {
        itemToUpdate.variation_sets = Array.isArray(item.variation_sets) ? item.variation_sets.filter(set => set !== null && set !== undefined).map(set => typeof set === 'string' ? set : set.uuid) : []
        itemToUpdate.default_variations = Array.isArray(item.default_variations) ? item.default_variations.filter(defaultVariation => defaultVariation !== null && defaultVariation !== undefined).map(defaultVariation => typeof defaultVariation === 'string' ? defaultVariation : defaultVariation.uuid).filter(variation => itemToUpdate.variation_sets.includes(state.variations.find(defaultVaration => defaultVaration?.uuid === variation).owner_id)) : []
        delete itemToUpdate.exclude_discounts
      }

      // Event items do not support 'exclude_discounts'
      if (item.type === 'coupon') {
        delete itemToUpdate.exclude_discounts
        itemToUpdate.max_redemptions = item.max_redemptions ? item.max_redemptions : 0
        itemToUpdate.redeemableItems = item.redeemableItems ? item.redeemableItems : null
      }

      if (item.type === 'giftcard') {
        itemToUpdate.validity = item.validity ? item.validity : 24
        itemToUpdate.payment_instance = item.payment_instance ? item.payment_instance : null
        itemToUpdate.send_to_customer = typeof item.send_to_customer === 'boolean' ? item.send_to_customer : false
        itemToUpdate.payWhatYouWant = typeof item.payWhatYouWant === 'boolean' ? item.payWhatYouWant : false
      }

      // Make sure to re-save the image if it is a cloudinary image and therefore not at all connected to the legacy system. Without this line we would overwrite the image to null when saving the item in legacy.
      if (item.imageUrl === null && Array.isArray(item.images) && item.images.length > 0 && item.images[0].file?.sources?.original && item.images[0].file?.sources?.original.includes('https://res.cloudinary.com/salescloud/')) {
        itemToUpdate.imageUrl = item.images[0].file.sources.original
      }

      try {
        const response = await axios.post(state.graphUrl, {
          query: `
            mutation updateItem($dm: UpdateItem){
              updateItem(item:$dm) {
                uuid
                title
                status
                weekdayPrices {
                  weekday
                  hourStart
                  hourEnd
                  price {
                    amount
                    currency_code
                  }
                  active
                }
                seasonalPrices {
                  startInMillis
                  endInMillis
                  price {
                    amount
                    currency_code
                  }
                  active
                }
              }
            }
          `,
          variables: { dm: itemToUpdate },
        })
    
        dispatch('getItems')
        return response.data.data.updateItem
      } catch (error) {
        // Handle error
        console.error("Error updating item:", error)
        return null
      }
    },
    deleteLineItem: ({state}, legacyId) => {
      let body = {
        query: 'mutation {\n' +
          ' deleteLineItem(legacyId: "' + legacyId + '" )\n' +
          '}'
      }

      return axios.post(state.graphUrl, body)
    },
    updateLineItem: ({state}, lineItem) => {


      let body = {
        query: 'mutation updateLineItem($newItem: UpdateLineItem!){\n' +
         '   updateLineItem(lineItem: $newItem) {\n' +
         '      uuid\n' +
         '  }\n' +
         '}',
         variables: {
          newItem: lineItem
         }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.updateLineItem
      })
    },
    getItemLocationBlocks: ({ state, commit }, location) => {
      let body = {
        query: 'query {\n' +
          ' itemLocationBlocks(organization: "' + state.organization.uuid + '") {\n' +
          '   item\n' +
          '   location\n' +
          ' }'+
          '}'
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          commit('updateItemLocationBlocks', response.data.data.itemLocationBlocks)
          return response.data.data.itemLocationBlocks.filter(itemBlock => itemBlock.location === location.uuid)
        })
    },
		releaseItemBlock: ({ state, commit }, itemBlock) => {

      let body = {
        query: 'mutation {\n' +
          ' releaseItemForLocation(organization: "' + state.organization.uuid + '", item: "' + itemBlock.item + '", location: "' + itemBlock.location + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.releaseItemForLocation
        })
    },
    getVariationLocationBlocks: ({ state, commit }, location) => {
      if(typeof location === 'undefined' || location === null || typeof location.uuid === 'undefined' || location.uuid === null) {
        return null
      }

      const body = {
        query: 'query {\n' +
            ' variationLocationBlocks(organization: "' + state.organization.uuid + '", location: "' + location.uuid + '") {\n' +
            '   location\n' +
            '   item\n' +
            '   variation\n' +
            ' }\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && Array.isArray(response.data.data.variationLocationBlocks)) {
          commit('updateVariationLocationBlocks', response.data.data.variationLocationBlocks)
          return response.data.data.variationLocationBlocks
        }
        return null
      }).catch(() => {
        return null
      })
    },
    blockVariationForLocation: ({ state }, { variation, location }) => {
      if(typeof variation === 'undefined' || variation === null || typeof location === 'undefined' || location === null) {
        return false
      }

      const body = {
        query: 'mutation {\n' +
            ' blockVariationForLocation(organization: "' + state.organization.uuid + '", location: "' + location.uuid + '", variation: "' + variation.uuid + '")\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response && response.data && response.data.data && response.data.data.blockVariationForLocation
      }).catch(() => {
        return false
      })
    },
    unblockVariationForLocation: ({ state }, { variation, location }) => {
      if(typeof variation === 'undefined' || variation === null || typeof location === 'undefined' || location === null) {
        return false
      }

      const body = {
        query: 'mutation {\n' +
            ' unblockVariationForLocation(organization: "' + state.organization.uuid + '", location: "' + location.uuid + '", variation: "' + variation.uuid + '")\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response && response.data && response.data.data && response.data.data.unblockVariationForLocation
      }).catch(() => {
        return false
      })
    },
    updateItemTranslations: ({ state, commit }, { item, translations }) => {
      if(item === null || typeof item === 'undefined') {
        return {
          success: false,
          message: i18n.t('missingItemInformation')
        }
      }
      if(translations === null || typeof translations === 'undefined') {
        return {
          success: false,
          message: i18n.t('missingTranslationsInformation')
        }
      }

      commit('updatingItem', true)

      if(Array.isArray(translations)) {
        let translationPromises = []
        for(let i = 0; i < translations.length; i++) {
          const translation = translations[i]
          if(typeof translation.value === 'undefined' || translation.value === null) {
            translation.value = ''
          }

          const translationItem = {
            uuid: item.uuid,
            translatedContent: translation
          }

          const translationPromise = axios.post(state.graphUrl, {
            query: 'mutation updateItemTranslations($dm: TranslationItem){\n' +
              '  updateItemTranslations(item:$dm)' +
              '}',
            variables: {
              dm: translationItem
            }
          }, {
            params: {
              time: new Date().getTime()
            }
          })

          translationPromises.push(translationPromise)
        }
        return Promise.all(translationPromises).then(response => {
          if(Array.isArray(response)) {
            let falsies = 0
            for(let i = 0; i < response.length; i++) {
              const result = response[i].data.data.updateItemTranslations
              if(result === false || result === null || typeof result === 'undefined') {
                falsies += 1
              }
            }
            if(falsies === response.length) {
              return {
                success: false,
                message: i18n.t('unableToSaveTranslations')
              }
            } else if(falsies > 0) {
              return {
                success: false,
                message: i18n.t('someTranslationsWereNotSaved')
              }
            } else {
              return {
                success: true,
                message: i18n.t('translationsWereSaved')
              }
            }
          }
          return {
            success: false,
            message: i18n.t('unknownError')
          }
        }).catch(() => {
          return {
            success: false,
            message: i18n.t('unknownError')
          }
        }).finally(() => {
          commit('updatingItem', false)
        })
      } else {
        const translationItem = {
          uuid: item.uuid,
          translatedContent: translations
        }

        return axios.post(state.graphUrl, {
          query: 'mutation updateItemTranslations($dm: TranslationItem){\n' +
            '  updateItemTranslations(item:$dm)' +
            '}',
          variables: {
            dm: translationItem
          },
        }, {
          params: {
            time: new Date().getTime()
          }
        }).then(response => {
          const success = response.data.data.updateItemTranslations
          return {
            success: success,
            message: success ? i18n.t('translationsUpdated') : i18n.t('translationsCouldNotBeUpdated')
          }
        }).catch(() => {
          return {
            success: false,
            message: i18n.t('unknownError')
          }
        }).finally(() => {
          commit('updatingItem', false)
        })
      }
    },
    updateItemOrder: ({ state }, itemArray) => {
      const body = {
        query:
          'mutation updateItemsSortingWeight ($items: [UpdateItemSortingWeight!]) {\n' +
          '   updateItemsSortingWeight(items: $items) {\n' +
          ' uuid\n' +
          ' sorting_weight\n' +
          ' title\n' +
          ' legacyId\n' +
          ' }\n' +
          '}',
      variables: {
        items: itemArray
      }
    }
    return axios.post(state.graphUrl, body, {
      params: {
        time: new Date().getTime()
      }
    })
      .then(response => {
        for(let i = 0; i < state.items; i++) {
          state.items[i].sorting_weight = i
        }
        return response.data.data
      })
    },
    updateEtags: ({ state }, { etagsToUpdate, unitID }) => {
      for (let i in etagsToUpdate) {
        axios.post(state.graphUrl, {
          query: `mutation addNewEtag ($dm : NewEtag!){
            newEtag (tag: $dm) {
              type
              uuid
              property
              organization
            }
          }`
          ,
          variables: {
            dm: {
              "type": "url",
              "property": state.etagURL + '&unitID=' + unitID,
              "organization": state.organization.uuid,
              "tagID": etagsToUpdate[i],
              "unitID": "" + unitID
            }
          },
        }, {
          params: {
            time: new Date().getTime()
          }
        }).then(response => {
          return response
        })
      }
    },
    createDeliveryService: ({ state }, deliveryMethodInstance) => {

      return axios.post(state.graphUrl, {
        query: 'mutation createDeliveryMethodInstance($dm: NewDeliveryMethodInstance){\n' +
          '  createDeliveryMethodInstance(deliveryMethodInstance:$dm) {\n' +
          '       uuid ' +
          '       namespace ' +
          '       title ' +
          '       description ' +
          '       enabled ' +
          '       data ' +
            '       configFields {\n' +
            '         key\n' +
            '         required\n' +
            '         value\n' +
            '         type\n' +
            '         cols\n' +
            '         label\n' +
            '         placeholder\n' +
            '         multiple\n' +
            '         options {\n' +
            '           key\n' +
            '           label\n' +
            '         }\n' +
            '         group {\n' +
            '           key\n' +
            '           label\n' +
            '           multiple\n' +
            '         }\n' +
            '       }\n' +
          '  } \n' +
          '}',
        variables: {
          dm: {
            title: deliveryMethodInstance.title,
            description: deliveryMethodInstance.description,
            namespace: deliveryMethodInstance.namespace,
            enabled: true,
            data: JSON.stringify(deliveryMethodInstance.data)
          }
        },
      }).then(response => {
        return response.data.data.updateDeliveryMethodInstance
      })
    },
    updateDeliveryService: ({ commit, state }, deliveryMethodInstance) => {

      return axios.post(state.graphUrl, {
        query: 'mutation updateDeliveryMethodInstance($dm: UpdateDeliveryMethodInstance){\n' +
          '  updateDeliveryMethodInstance(deliveryMethodInstance:$dm) {\n' +
          '       uuid\n' +
          '       namespace\n' +
          '       title\n' +
          '       description\n' +
          '       enabled\n' +
          '       data\n' +
            '       configFields {\n' +
            '         key\n' +
            '         required\n' +
            '         value\n' +
            '         type\n' +
            '         cols\n' +
            '         label\n' +
            '         placeholder\n' +
            '         multiple\n' +
            '         options {\n' +
            '           key\n' +
            '           label\n' +
            '         }\n' +
            '         group {\n' +
            '           key\n' +
            '           label\n' +
            '           multiple\n' +
            '         }\n' +
            '       }\n' +
          '  }\n' +
          '}',
        variables: {
          dm: {
            "title": deliveryMethodInstance.title,
            "description": deliveryMethodInstance.description,
            "uuid": deliveryMethodInstance.uuid,
            "enabled": deliveryMethodInstance.enabled,
            "data": JSON.stringify(deliveryMethodInstance.data)
          }
        },
      }).then(response => {
        commit('updateDeliveryMethodInstance', response.data.data.updateDeliveryMethodInstance)
      })
    },
    getDeliveryServices: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  deliveryMethods{\n' +
          '    namespace\n' +
          '    title\n' +
          '    description\n' +
          '    uiFields {\n' +
          '      key\n' +
          '      required\n' +
          '      value\n' +
          '      type\n' +
          '      cols\n' +
          '      label\n' +
          '      multiple\n' +
          '      placeholder\n' +
          '    }\n' +
          '    configFields {\n' +
          '      key\n' +
          '      required\n' +
          '      value\n' +
          '      type\n' +
          '      cols\n' +
          '      label\n' +
          '      multiple\n' +
          '      placeholder\n' +
          '      options {\n' +
          '        key\n' +
          '        label\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '  deliveryMethodInstances {\n' +
          '       uuid\n' +
          '       namespace\n' +
          '       title\n' +
          '       description\n' +
          '       enabled\n' +
          '       data\n' +
          '       configFields {\n' +
          '         key\n' +
          '         required\n' +
          '         value\n' +
          '         type\n' +
          '         cols\n' +
            '         label\n' +
            '         placeholder\n' +
            '         multiple\n' +
            '         options {\n' +
            '           key\n' +
            '           label\n' +
            '         }\n' +
            '         group {\n' +
            '           key\n' +
            '           label\n' +
            '           multiple\n' +
            '         }\n' +
          '       }\n' +
            '}\n' +
          '}'
      }

      axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateDeliveryMethods', result.data.data.deliveryMethods)
          commit('updateDeliveryMethodInstances', result.data.data.deliveryMethodInstances)
        }
      })

    },
    getMessengerMethodInstances: ({ commit, state }) => {
      const body = {
        query: 'query MessengerMethodInstances {\n' +
            '  messengerMethodInstances {\n' +
            '    uuid\n' +
            '    label\n' +
            '    description\n' +
            '    method_id\n' +
            '    created\n' +
            '    changed\n' +
            '    is_default\n' +
            '  }\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateMessengerMethodInstances', result.data.data.deliveryMethodInstances)
          return result.data.data.deliveryMethodInstances
        }
        return null
      })
    },
    getItemsByOrganizationUUID: ({ state }, organizationUUID) => {
      const body = {
        query: 'query {\n' +
          '  itemsList(organization: "' + organizationUUID + '") {\n' +
          '    uuid\n' +
          '    title\n' +
          '    type\n' +
          '    status\n' +
          '    categories {\n' +
          '       uuid\n' +
          '    }\n' +
          '    translatedContent {\n' +
          '       key\n' +
          '       language\n' +
          '       value\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.itemsList
      })
    },
    getLocations: ({ commit, state }) => {
      const body = {
        query: 'query {\n' +
          '  locations {\n' +
          '    uuid\n' +
          '    label\n' +
          '    description\n' +
          '    phone\n' +
          '    email\n' +
          '    taxId\n' +
          '    active\n' +
					'    timezone\n' +
          '    address{\n' +
          '       name_line\n' +
          '       country\n' +
          '       thoroughfare\n' +
          '       locality\n' +
          '       postal_code\n' +
          '    }\n' +
          '    gps {\n' +
          '      geom\n' +
          '      geo_type\n' +
          '      lat\n' +
          '      lon\n' +
          '      left\n' +
          '      top\n' +
          '      right\n' +
          '      bottom\n' +
          '      geohash\n' +
          '    }' +
          '    opening_hours{\n' +
          '       day\n' +
          '       starthours\n' +
          '       endhours\n' +
          '       maximumSalesPerSlot\n' +
          '       intervalBetweenSlotsInMillis\n' +
          '       pauseBetweenSlotsInMillis\n' +
          '       comment\n' +
          '    }\n' +
          '    seasonalHours{\n' +
          '       endTimeInMillis\n' +
          '       endhours\n' +
          '       startTimeInMillis\n' +
          '       starthours\n' +
          '    }\n' +
          '    closedDates \n' +
          '    excludedZonesFromBookings{\n' +
          '       uuid\n' +
          '       name\n' +
          '    }\n' +
          '    excludedLocationVariationsFromBookings{\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    excludedZonesFromOnlineBookings{\n' +
          '       uuid\n' +
          '       name\n' +
          '    }\n' +
          '    opening_rule\n' +
          '    ept\n' +
          '    maximumSalesPerSlot\n' +
          '    intervalBetweenSlotsInMillis\n' +
          '    pauseBetweenSlotsInMillis\n' +
          '    variations {\n' +
          '       label\n' +
          '       status\n' +
          '       uuid\n' +
          '       x\n' +
          '       y\n' +
          '       shape\n' +
          '       zone {\n' +
          '         uuid\n' +
          '         name\n' +
          '       }\n' +
          '       standardPersons\n' +
          '       minimumPersons\n' +
          '       maximumPersons\n' +
          '       sorting_weight\n' +
          '    }\n' +
          '     preferredChannel {\n' +
          '       uuid\n' +
          '       label\n' +
          '       active\n' +
          '     }\n' +
          '  }\n' +
          '  zones: categories(type: "zones") {\n' +
          '    name\n' +
          '    uuid\n' +
          '  }\n' +
          '  locationVariationCombinations {\n' +
          '    uuid\n' +
          '    label\n' +
          '    active\n' +
          '    location {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    locationVariations {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    createdTimeInMillis\n' +
          '    changedTimeInMillis\n' +
          '  }\n' +
          '}'
      }
      return axios.post(state.graphUrl, body).then(result => {

        if (result.data.data) {
          if (state.selectedLocationProfile) {
            commit('updateSelectedLocationProfile', result.data.data.locations.find(location => location.uuid === state.selectedLocationProfile.uuid))
          }
          commit('updateLocations', result.data.data.locations)
          commit('updateZones', result.data.data.zones)
          commit('updateLocationVariationCombinations', result.data.data.locationVariationCombinations)
        }

        return result.data.data.locations
      })
    },
    getLocationVariationCombinations: ({ commit, state }) => {
      const body = {
        query: 'query {\n' +
          '  locationVariationCombinations {\n' +
          '    uuid\n' +
          '    label\n' +
          '    active\n' +
          '    location {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    locationVariations {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    createdTimeInMillis\n' +
          '    changedTimeInMillis\n' +
          '  }' +
          '}'
      }
      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateLocationVariationCombinations', result.data.data.locationVariationCombinations)
          return result.data.data.locationVariationCombinations
        }
      })
    },
    getLocationsByOrganizationUUID: ({ state }, organizationUUID) => {
      const body = {
        query: 'query {\n' +
          '  locationOptions(organization: "' + organizationUUID + '") {\n' +
          '    uuid\n' +
          '    label\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.locationOptions
      })
    },
    getCategoriesByOrganizationUUID: ({ state }, organizationUUID) => {

      const body = {
        query: 'query {\n' +
          '  categories(type: "products", uuid: "' + organizationUUID + '") {\n' +
          '    uuid\n' +
          '    name\n' +
          '    legacyId\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.categories
      })
    },
    getChannelsByOrganizationUUID: ({ state }, organizationUUID) => {

      const body = {
        query: 'query {\n' +
          '  channelsList(organization: "' + organizationUUID + '") {\n' +
          '    uuid\n' +
          '    label\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.channelsList
      })
    },
    getChannelByUUID: ({ state }, payload) => {

      const body = {
        query: 'query {\n' +
          '  channel(organization: "' + payload.organization + '", uuid: "' + payload.uuid + '") {\n' +
          '    uuid\n' +
          '    label\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.channel
      })
    },
    blockAvailableTimeSlot: ({state}, payload) => {
      const body = {
        query: 'mutation {\n' +
          '  blockAvailableTimeSlot(startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: '+ payload.endTimeInMillis + ', organization: "' + state.organization.uuid + '", location: "' + payload.location + '", channel: "", item: "' + payload.item + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          return result.data.data.blockAvailableTimeSlot
        }
      })
    },
    bookingsGetBlockedDays: ({ state }, location) => {
      const body = {
        query: 'query bookingsGetBlockedDays($organization: String, $location: String) {\n' +
            '  bookingsGetBlockedDays (organization: $organization, location: $location) {\n' +
            '    location\n' +
            '    date\n' +
            '  }\n' +
            '}\n',
        variables: {
          organization: state.organization.uuid,
          location: location
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.bookingsGetBlockedDays
      })
    },
    toggleBlockedBooking: ({ state }, payload) => {
      const body = {
        query: 'mutation bookingsToggleBlockedDay($location: String!, $date: String!) {\n' +
            '  bookingsToggleBlockedDay(location: $location, date: $date)' +
            '}',
        variables: {
          location: payload.location,
          date: payload.date
        }
      }
      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          return result.data.data.bookingToggleBlockedDays
        }
      })
    },
    getBookings: ({ commit, state }) => {

      commit('setIsUpdatingBookings', true)

      const body = {
        query: 'query {\n' +
          '  bookings {\n' +
          '    startsAtTime\n' +
          '    endsAtTime\n' +
          '    item{\n' +
          '       uuid\n' +
          '       title\n' +
          '       price{\n' +
          '         amount\n' +
          '         currency_code\n' +
          '       }\n' +
          '       images { \n' +
          '         file { \n' +
          '           sources { \n' +
          '             thumbnail \n' +
          '             original \n' +
          '           } \n' +
          '         } \n' +
          '       } \n' +
          '    }\n' +
          '    customer{\n' +
          '       uuid\n' +
          '       ssn\n' +
          '       email\n' +
          '       phone\n' +
          '       address{\n' +
          '          name_line\n' +
          '       }\n' +
          '    }\n' +
          '    location{\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    uuid\n' +
          '    namespace\n' +
          '    redeemedAt\n' +
          '    booking_id\n' +
          '    created\n' +
          '    changed\n' +
          '    quantity\n' +
          '    lineItem{\n' +
          '       line_item_label\n' +
          '       quantity\n' +
          '    }\n' +
          '    description\n' +
          '    status\n' +
          '    co{\n' +
          '       uuid\n' +
          '       ssn\n' +
          '       email\n' +
          '       phone\n' +
          '       address{\n' +
          '          name_line\n' +
          '       }\n' +
          '    }\n' +
          '    order\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateBookings', result.data.data.bookings.sort((a, b) => b.created - a.created))
        }
      }).finally(() => {
        commit('setIsUpdatingBookings', false)
      })

    },
    redeemBooking: ({state}, booking) => {
      const body = {
        query: 'mutation { redeemBooking(organization: "' + state.organization.uuid + '", uuid: "' + booking.uuid + '") }'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.redeemBooking
      })
    },
    unRedeemBooking: ({state}, booking) => {
      const body = {
        query: 'mutation { unRedeemBooking(organization: "' + state.organization.uuid + '", uuid: "' + booking.uuid + '") }'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.unRedeemBooking
      })
    },
    getBookingsByCustomer: ({commit, state}, payload) => {
      const body = {
        query: 'query ($conditions: [QueryCondition]){\n' +
            '   bookings(item: "", conditions: $conditions) {\n' +
            '    startsAtTime\n' +
            '    endsAtTime\n' +
            '    quantity\n' +
            '    item{\n' +
            '       uuid\n' +
            '       title\n' +
            '       minimumOrderQuantity\n' +
            '       maximumOrderQuantity\n' +
            '       price{\n' +
            '         amount\n' +
            '         currency_code\n' +
            '       }\n' +
            '       images { \n' +
            '         file { \n' +
            '           sources { \n' +
            '             thumbnail \n' +
            '             original \n' +
            '           } \n' +
            '         } \n' +
            '       } \n' +
            '    }\n' +
            '    customer{\n' +
            '       uuid\n' +
            '       customer_id\n' +
            '       ssn\n' +
            '       email\n' +
            '       phone\n' +
            '       address{\n' +
            '          name_line\n' +
            '          first_name\n' +
            '          last_name\n' +
            '       }\n' +
            '    }\n' +
            '    location {\n' +
            '       uuid\n' +
            '       label\n' +
            '    }\n' +
            '    locationVariation {\n' +
            '       uuid\n' +
            '       label\n' +
            '    }\n' +
            '    locationVariations {\n' +
            '       uuid\n' +
            '       label\n' +
            '    }\n' +
            '    internalNote\n' +
            '    externalNote\n' +
            '    namespace\n' +
            '    redeemedAt\n' +
            '    booking_id\n' +
            '    created\n' +
            '    changed\n' +
            '    lineItem{\n' +
            '       line_item_label\n' +
            '       quantity\n' +
            '    }\n' +
            '    description\n' +
            '    status\n' +
            '    state\n' +
            '    co{\n' +
            '       uuid\n' +
            '       ssn\n' +
            '       email\n' +
            '       phone\n' +
            '       address{\n' +
            '          name_line\n' +
            '       }\n' +
            '    }\n' +
            '    uuid\n' +
            '    order\n' +
            '  }\n' +
            '}',
            variables: {
              conditions: {
                operator: "equals",
                property: "customer.uuid",
                value: payload
              }
            }
          }
          return axios.post(state.graphUrl, body).then(result => {
            if (result && result.data && result.data.data && result.data.data.bookings) {
              return result.data.data.bookings
            }
            return []
          }).catch(() => {
            return []
          }).finally(() => {
            commit('setIsUpdatingBookings', false)
          })
        },
    getBookingsByConditions: ({commit, state}, {conditions}) => {

      commit('setIsUpdatingBookings', true)

      const body = {
        query: 'query bookingsQuery($conditions: [QueryCondition]) {\n' +
          '  bookings(conditions: $conditions) {\n' +
          '    startsAtTime\n' +
          '    endsAtTime\n' +
          '    item{\n' +
          '       uuid\n' +
          '       title\n' +
          '       price{\n' +
          '         amount\n' +
          '         currency_code\n' +
          '       }\n' +
          '       images { \n' +
          '         file { \n' +
          '           sources { \n' +
          '             thumbnail \n' +
          '             original \n' +
          '           } \n' +
          '         } \n' +
          '       } \n' +
          '    }\n' +
          '    customer{\n' +
          '       uuid\n' +
          '       customer_id\n' +
          '       ssn\n' +
          '       email\n' +
          '       phone\n' +
          '       address{\n' +
          '          name_line\n' +
          '       }\n' +
          '    }\n' +
          '    location{\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    locationVariation {\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    locationVariations {\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    internalNote\n' +
          '    externalNote\n' +
          '    uuid\n' +
          '    namespace\n' +
          '    redeemedAt\n' +
          '    booking_id\n' +
          '    created\n' +
          '    changed\n' +
          '    quantity\n' +
          '    lineItem{\n' +
          '       line_item_label\n' +
          '       quantity\n' +
          '    }\n' +
          '    description\n' +
          '    status\n' +
          '    state\n' +
          '    co{\n' +
          '       uuid\n' +
          '       ssn\n' +
          '       email\n' +
          '       phone\n' +
          '       address{\n' +
          '          name_line\n' +
          '       }\n' +
          '    }\n' +
          '    order\n' +
          '  }\n' +
          '}',
          variables: {
            conditions: conditions
          }
      }
      return axios.post(state.graphUrl, body, {
        params: {
          time: new Date().getTime()
        }
      }).then(result => {
        if (result.data.data) {
          return result.data.data.bookings
        }
      }).finally(() => {
        commit('setIsUpdatingBookings', false)
      })
    },
    getBookingsByItem: ({commit, state}, payload) => {

      commit('setIsUpdatingBookings', true)

      const body = {
        query: 'query ($conditions: [QueryCondition]){\n' +
            '   bookings(item: "", conditions: $conditions) {\n' +
            '    uuid\n' +
            '    created\n' +
            '    startsAtTime\n' +
            '    endsAtTime\n' +
            '    status\n' +
						'    externalNote\n' +
            '    quantity\n' +
            '    item{\n' +
            '       title\n' +
            '    }\n' +
            '    customer{\n' +
            '       email\n' +
            '       phone\n' +
            '       label\n' +
            '       exclusive\n' +
						'       ssn\n' +
            '       address{\n' +
            '          name_line\n' +
            '       }\n' +
            '    }\n' +
            '    location {\n' +
            '       uuid\n' +
            '       label\n' +
            '    }\n' +
            '  }\n' +
            '}',
        variables: {
          conditions: {
            operator: "equals",
            property: "item.uuid",
            value: payload
          }
        }
          }
          return axios.post(state.graphUrl, body).then(result => {
            if (result && result.data && result.data.data) {
              return result.data.data.bookings
            }
          }).finally(() => {
            commit('setIsUpdatingBookings', false)
          })
    },
    getBookingsByDate: ({commit, state}, payload) => {

      commit('setIsUpdatingBookings', true)

      const body = {
        query : 'query ($item: String, $startTimeInMillis: Float, $endTimeInMillis: Float){\n' +
          '  blockedTimeSlots(startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: ' + payload.endTimeInMillis + ') { uuid, startTimeInMillis, endTimeInMillis, item }\n' +
          '  bookings(item: $item, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis) {\n' +
          '    channel\n' +
          '    startsAtTime\n' +
          '    endsAtTime\n' +
          '    quantity\n' +
          '    item{\n' +
          '       uuid\n' +
          '       title\n' +
          '       minimumOrderQuantity\n' +
          '       maximumOrderQuantity\n' +
          '       price{\n' +
          '         amount\n' +
          '         currency_code\n' +
          '       }\n' +
          '       images { \n' +
          '         file { \n' +
          '           sources { \n' +
          '             thumbnail \n' +
          '             original \n' +
          '           } \n' +
          '         } \n' +
          '       } \n' +
          '    }\n' +
          '    customer{\n' +
          '       uuid\n' +
          '       customer_id\n' +
          '       ssn\n' +
          '       email\n' +
          '       phone\n' +
          '       address{\n' +
          '          name_line\n' +
          '          first_name\n' +
          '          last_name\n' +
          '       }\n' +
          '    }\n' +
          '    location {\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    locationVariation {\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    locationVariations {\n' +
          '       uuid\n' +
          '       label\n' +
          '    }\n' +
          '    internalNote\n' +
          '    externalNote\n' +
          '    namespace\n' +
          '    redeemedAt\n' +
          '    booking_id\n' +
          '    created\n' +
          '    changed\n' +
          '    lineItem{\n' +
          '       line_item_label\n' +
          '       quantity\n' +
          '    }\n' +
          '    description\n' +
          '    status\n' +
          '    state\n' +
          '    co{\n' +
          '       uuid\n' +
          '       ssn\n' +
          '       email\n' +
          '       phone\n' +
          '       address{\n' +
          '          name_line\n' +
          '       }\n' +
          '    }\n' +
          '    uuid\n' +
          '    order\n' +
          '    upsellLineItems {\n' +
          '       label\n' +
          '       uuid\n' +
          '       quantity\n' +
          '       item {\n' +
          '           uuid\n' +
          '           title\n' +
          '       }\n' +
          '    }\n' +
          '  }\n' +
          '}',
        variables: {
          item: payload.itemUUID,
          startTimeInMillis: payload.startTimeInMillis,
          endTimeInMillis: payload.endTimeInMillis
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data) {
          if(Array.isArray(result.data.data.bookings)) {
            commit('updateBookings', result.data.data.bookings.sort((a,b) => a.startsAtTime - b.startsAtTime))
          }
          commit('updateBlockedTimeSlots', result.data.data.blockedTimeSlots)
        }
      }).finally(() => {
        commit('setIsUpdatingBookings', false)
      })

    },
    createBooking: ({ state, getters, commit, dispatch }, booking) => {
      commit('updateProcessingBooking', true)

      if(booking.locationVariation === null || booking.locationVariation === 'null') {
        delete booking.locationVariation
      }

      const body = {
        query:
          'mutation createBooking($booking: NewBooking!){\n' +
          '   createBooking(booking: $booking) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      status\n' +
          '      booking_id\n' +
          '      description\n' +
          '      created\n' +
          '      changed\n' +
          '      startsAtTime\n' +
          '      endsAtTime\n' +
          '      redeemedAt\n' +
          '      notifyCustomer\n' +
          '      quantity\n' +
          '      item{\n' +
          '         uuid\n' +
          '         title\n' +
          '         images { \n' +
          '           file { \n' +
          '             sources { \n' +
          '               thumbnail \n' +
          '               original \n' +
          '             } \n' +
          '           } \n' +
          '         } \n' +
          '      }\n' +
          '      lineItem{\n' +
          '         line_item_label\n' +
          '         quantity\n' +
          '      }\n' +
          '      customer{\n' +
          '         uuid\n' +
          '         ssn\n' +
          '         email\n' +
          '         phone\n' +
          '         address{\n' +
          '           name_line\n' +
          '         }\n' +
          '      }\n' +
          '      co{\n' +
          '         uuid\n' +
          '         ssn\n' +
          '         email\n' +
          '         phone\n' +
          '         address{\n' +
          '           name_line\n' +
          '         }\n' +
          '      }\n' +
          '   }\n' +
          '}',
        variables: {
          booking: booking
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
          const createdData = result.data.data.createBooking
          if (createdData) {
            const date = new Date(getters.systemTime)
            let startTimeInMillis = date.setUTCHours(0,0,0,0)
            let endTimeInMillis = date.setUTCHours(23,59,59,999)
            return dispatch('getBookingsByDate', { item: null, startTimeInMillis: startTimeInMillis, endTimeInMillis: endTimeInMillis }).then(() => {
              return createdData
            })
          }
        }).finally(() => {
          commit('updateProcessingBooking', false)
        })
    },
    createMultipleBookings: ({ state, commit, dispatch }, { bookings, bookingDate}) => {
      commit('updateProcessingBooking')
      const body = {
        query:
          'mutation createMultipleBookings($bookings: [NewBooking!]!){\n' +
          '   createMultipleBookings(bookings: $bookings) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      status\n' +
          '      booking_id\n' +
          '      description\n' +
          '      created\n' +
          '      changed\n' +
          '      startsAtTime\n' +
          '      endsAtTime\n' +
          '      redeemedAt\n' +
          '      item{\n' +
          '         uuid\n' +
          '         title\n' +
          '         images { \n' +
          '           file { \n' +
          '             sources { \n' +
          '               thumbnail \n' +
          '               original \n' +
          '             } \n' +
          '           } \n' +
          '         } \n' +
          '      }\n' +
          '      lineItem{\n' +
          '         line_item_label\n' +
          '         quantity\n' +
          '      }\n' +
          '      customer{\n' +
          '         uuid\n' +
          '         ssn\n' +
          '         email\n' +
          '         phone\n' +
          '         address{\n' +
          '           name_line\n' +
          '         }\n' +
          '      }\n' +
          '      co{\n' +
          '         uuid\n' +
          '         ssn\n' +
          '         email\n' +
          '         phone\n' +
          '         address{\n' +
          '           name_line\n' +
          '         }\n' +
          '      }\n' +
          '   }\n' +
          '}',
        variables: {
          bookings: bookings
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        const createdData = result.data.data.createMultipleBookings
        if (createdData) {
          let timestamp = state.systemTime
          if(bookingDate) { timestamp = bookingDate }
          const date = new Date(timestamp)
          let startTimeInMillis = date.setUTCHours(0,0,0,0)
          let endTimeInMillis = date.setUTCHours(23,59,59,999)
          return dispatch('getBookingsByDate', { item: null, startTimeInMillis: startTimeInMillis, endTimeInMillis: endTimeInMillis }).then(() => {
            return createdData
          })
        }
      }).finally(() => {
        commit('updateProcessingBooking', false)
      })
    },
    updateBooking: ({ state, dispatch }, booking) => {
      const body = {
        query:
          'mutation updateBooking($booking: UpdateBooking!){\n' +
          '   updateBooking(booking: $booking) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      status\n' +
          '      booking_id\n' +
          '      description\n' +
          '      created\n' +
          '      changed\n' +
          '      startsAtTime\n' +
          '      endsAtTime\n' +
          '      redeemedAt\n' +
          '      notifyCustomer\n' +
          '      quantity\n' +
          '      item{\n' +
          '         uuid\n' +
          '         title\n' +
          '         images { \n' +
          '           file { \n' +
          '             sources { \n' +
          '               thumbnail \n' +
          '               original \n' +
          '             } \n' +
          '           } \n' +
          '         } \n' +
          '      }\n' +
          '      lineItem{\n' +
          '         line_item_label\n' +
          '         quantity\n' +
          '      }\n' +
          '      customer{\n' +
          '         uuid\n' +
          '         ssn\n' +
          '         email\n' +
          '         phone\n' +
          '         address{\n' +
          '           name_line\n' +
          '         }\n' +
          '      }\n' +
          '      co{\n' +
          '         uuid\n' +
          '         ssn\n' +
          '         email\n' +
          '         phone\n' +
          '         address{\n' +
          '           name_line\n' +
          '         }\n' +
          '      }\n' +
          '   }\n' +
          '}',
        variables: {
          booking: booking
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
          const updateData = result.data.data.updateBooking
          if (updateData) {
            let startTimeInMillis = state.startTimeInMillis
            let endTimeInMillis = state.endTimeInMillis

            if(state.selectedDate) {
              const momentDateValue = moment(state.selectedDate)
              startTimeInMillis = momentDateValue.startOf('day').valueOf()
              endTimeInMillis = momentDateValue.endOf('day').valueOf()
            }

            return dispatch('getBookingsByDate', { item: null, startTimeInMillis: startTimeInMillis, endTimeInMillis: endTimeInMillis })
              .then(() => {
                return updateData
              })
          }
        })
    },
    cancelBooking: ({ state, commit, dispatch }, booking) => {
      commit('updateCancellingBooking', true)

      let bookingToCancel = {}

      bookingToCancel.uuid = booking.uuid
      bookingToCancel.status = false
      bookingToCancel.namespace = booking.namespace

      if(booking.item && booking.item.uuid) {
        bookingToCancel.item = booking.item.uuid
      }
      if(booking.customer && booking.customer.uuid) {
        bookingToCancel.customer = booking.customer.uuid
      }

      const body = {
        query:
          'mutation updateBooking($booking: UpdateBooking!){\n' +
          '   updateBooking(booking: $booking) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      status\n' +
          '      booking_id\n' +
          '      changed\n' +
          '   }\n' +
          '}',
        variables: {
          booking: bookingToCancel
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
          const updateData = result.data.data.updateBooking
          if (updateData) {
            let timestamp = state.systemTime
            if(booking.changed) { timestamp = booking.changed * 1000 }
            const date = new Date(timestamp)
            let startTimeInMillis = date.setUTCHours(0,0,0,0)
            let endTimeInMillis = date.setUTCHours(23,59,59,999)
            return dispatch('getBookingsByDate', { item: null, startTimeInMillis: startTimeInMillis, endTimeInMillis: endTimeInMillis }).then(() => {
              return updateData
            })
          }
        }).finally(() => {
          commit('updateCancellingBooking', false)
        })
    },
    cancelMultipleBookings: ({ state, commit, dispatch }, bookings) => {
      commit('updateCancellingBooking', true)

      if(typeof bookings === 'undefined' || bookings === null || !Array.isArray(bookings) || bookings.length <= 0) {
        commit('updateCancellingBooking', false)
        return
      }

      let bookingsToCancel = []

      for(let i = 0; i < bookings.length; i++) {
        let booking = bookings[i]
        let b = {}

        b.uuid = booking.uuid
        b.status = false
        b.namespace = booking.namespace

        if(booking.item && booking.item.uuid) {
          b.item = booking.item.uuid
        }
        if(booking.customer && booking.customer.uuid) {
          b.customer = booking.customer.uuid
        }

        bookingsToCancel.push(b)
      }

      const body = {
        query:
          'mutation updateMultipleBookings($bookings: [UpdateBooking!]!){\n' +
          '   updateMultipleBookings(bookings: $bookings) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      status\n' +
          '      booking_id\n' +
          '      changed\n' +
          '   }\n' +
          '}',
        variables: {
          bookings: bookingsToCancel
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
          const updateData = result.data.data.updateMultipleBookings
          if (updateData && Array.isArray(updateData) && updateData.length > 0) {
            let timestamp = state.systemTime
            if(bookings[0].changed) { timestamp = bookings[0].changed * 1000 }
            const date = new Date(timestamp)
            let startTimeInMillis = date.setUTCHours(0,0,0,0)
            let endTimeInMillis = date.setUTCHours(23,59,59,999)
            return dispatch('getBookingsByDate', { item: null, startTimeInMillis: startTimeInMillis, endTimeInMillis: endTimeInMillis }).then(() => {
              return updateData
            })
          }
        }).finally(() => {
          commit('updateCancellingBooking', false)
        })
    },
    getAvailableBookingStates: ({ state, commit }) => {
      const body = {
        query: `query {
          __type(name: "BookingState") {
            enumValues {
              name
            }
          }
        }`
      }

			const salesCloudColors = state.salesCloudColors

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data) {
          if(result.data.data['__type'] && Array.isArray(result.data.data['__type'].enumValues)) {
            commit('updateAvailableBookingStates', result.data.data['__type'].enumValues.map(value => {
              if(value) {

                let color
                let name = value.name
                let weight = 0
                switch (value.name) {
                  case 'active':
                    color = salesCloudColors.blue
                    break
                  case 'reserved':
                    color = salesCloudColors.indigo
                    weight = 2
                    break
                  case 'arrived':
                    color = salesCloudColors.yellow
                    weight = 3
                    break
                  case 'seated':
                    color = salesCloudColors.orange
                    weight = 4
                    break
                  case 'completed':
                    color = salesCloudColors.mossGreen
                    weight = 5
                    break
									case 'canceled':
										color = salesCloudColors.brightRed
										weight = 1
										break
									case 'cancelled':
										color = salesCloudColors.brightRed
										weight = 1
										break
									case 'noshow':
										color = salesCloudColors.pink
										name = 'no-show'
										weight = 6
										break
									default:
										color = salesCloudColors.blue
                }

                return {
                  text: name.substring(0, 1).toUpperCase() + name.substring(1),
                  value: value.name,
                  color: color,
                  weight: weight
                }
              }
              return null
            }).filter(noNull => noNull).sort((a,b) => a.weight - b.weight))
          }
        }
      })
    },
    updateBookingState: ({ state, dispatch }, { bookingUUID, newBookingState }) => {
      if(typeof bookingUUID === 'undefined' || bookingUUID === null || typeof newBookingState === 'undefined' || newBookingState === null) {
        return false
      }

      const body = {
        query: 'mutation {\n' +
            ' updateBookingState(uuid: "' + bookingUUID + '", state: ' + newBookingState + ')\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data) {
          if(result.data.data.updateBookingState) {
            let startTimeInMillis = state.startTimeInMillis
            let endTimeInMillis = state.endTimeInMillis

            if(state.selectedDate) {
              if(state.selectedDate) {
                const momentDateValue = moment(state.selectedDate)
                startTimeInMillis = momentDateValue.startOf('day').valueOf()
                endTimeInMillis = momentDateValue.endOf('day').valueOf()
              }

              return dispatch('getBookingsByDate', { item: null, startTimeInMillis: startTimeInMillis, endTimeInMillis: endTimeInMillis }).then(() => {
                return true
              })
            }
          }
        }
        return false
      }).catch(() => {
        return false
      })
    },
    getBookingsCountByItem: ({ state }, { itemUUID }) => {
      const body = {
        query: 'query {\n' +
            '   bookingsCountByItem(organization: "' + state.organization.uuid + '", item: "' + itemUUID + '")' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data && result.data.data.bookingsCountByItem) {
          return result.data.data.bookingsCountByItem
        }
        return 0
      }).catch(() => {
        return 0
      })
    },
    unblockSelectedTimeSlot: ({ state }, payload) => {
      const body = {
        query: 'mutation {\n' +
        '  unblockAvailableTimeSlot(startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: '+ payload.endTimeInMillis + ', organization: "' + state.organization.uuid + '", location: "' + payload.location + '", channel: "", item: "' + payload.item + '")\n' +
        '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          return result.data.data.unblockAvailableTimeSlot
        }
      })
    },
    reprintPrintjob: ({state}, uuid) => {
      const body = {
        query: 'mutation reprintPrintjob($uuid: String) {\n' +
            '  reprintPrintJob(uuid: $uuid)\n' +
            '}',
        variables: {
          "uuid": uuid
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          return result.data.data.reprintPrintJob
        }
        return null
      })
    },
    getMessengerMessages: ({state}, {startTimeInMillis, endTimeInMillis}) => {
      const body = {
        query: 'query($startTimeInMillis: Float, $endTimeInMillis: Float) {\n' +
            '  messages(startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis) {\n' +
            '    uuid\n' +
            '    namespace\n' +
            '    destination\n' +
            '    state\n' +
            '    content\n' +
            '    context\n' +
            '    created\n' +
            '  }\n' +
            '}\n',
        variables: {
          startTimeInMillis: startTimeInMillis,
          endTimeInMillis: endTimeInMillis
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          return result.data.data.messages
        }
        return null
      })
    },
    createYessLink: ({ state }, profile) => {

      if (profile.logo !== null && typeof profile.logo !== 'undefined') {
        delete profile.logo.url
        delete profile.logo.mime
        delete profile.logo.size
        delete profile.logo.created
        delete profile.logo.changed
      }

      if (profile.cover !== null && typeof profile.cover !== 'undefined') {
        delete profile.cover.url
        delete profile.cover.mime
        delete profile.cover.size
        delete profile.cover.created
        delete profile.cover.changed
      }

      if(profile.discountPercent) {
        profile.discountPercent = profile.discountPercent * 1
      }

      if(profile.groups !== null && typeof profile.groups !== 'undefined' && profile.groups !== '') {
        if(!Array.isArray(profile.groups) && typeof profile.groups === 'string') {
          profile.groups = profile.groups.trim().split(',')
        }
      } else {
        profile.groups = []
      }

      const categories = []

      if(profile.categories && Array.isArray(profile.categories)) {
        for(let i = 0; i < profile.categories.length; i++) {
          if(profile.categories[i].uuid !== null && typeof profile.categories[i].uuid !== 'undefined') {
            categories.push(profile.categories[i].uuid)
          }
          else {
            categories.push(profile.categories[i])
          }
        }
      }

      profile.categories = categories

      if(Array.isArray(profile.otherPaymentMethodInstances) && profile.otherPaymentMethodInstances.length > 0) {
        // We just want to store the uuids as an array and make sure there are no null values in it
        profile.otherPaymentMethodInstances = profile.otherPaymentMethodInstances.map(instance => instance.uuid).filter(i => i)
      } else {
        profile.otherPaymentMethodInstances = []
      }

      if(Array.isArray(profile.paymentMethodInstances) && profile.paymentMethodInstances.length > 0) {
        // We just want to store the uuids as an array and make sure there are no null values in it
        profile.paymentMethodInstances = profile.paymentMethodInstances.map(instance => instance.uuid).filter(i => i)
      } else {
        profile.paymentMethodInstances = []
      }

      // This namespace is required, this should change depending on "marketplace" selection
      let namespace = 'yess'
      if(typeof profile.namespace === 'undefined' || profile.namespace === null) {
        profile.namespace = namespace
      }

      const body = {
        query: 'mutation createYessProfile($profile: NewYessProfile!) {\n' +
          '  createYessProfile(profile: $profile) {\n' +
          '    title\n' +
          '  }\n' +
          '}',
        variables: {
          profile: profile
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.createYessProfile
      })
    },
    updateYessLink: ({ state }, profile) => {

      delete profile.organization

      if (profile.logo !== null && typeof profile.logo !== 'undefined') {
        delete profile.logo.url
        delete profile.logo.mime
        delete profile.logo.size
        delete profile.logo.created
        delete profile.logo.changed
      }

      if (profile.cover !== null && typeof profile.cover !== 'undefined') {
        delete profile.cover.url
        delete profile.cover.mime
        delete profile.cover.size
        delete profile.cover.created
        delete profile.cover.changed
      }

      if(profile.discountPercent) {
        profile.discountPercent = profile.discountPercent * 1
      }

      if(profile.groups !== null && typeof profile.groups !== 'undefined' && profile.groups !== '') {
        if(!Array.isArray(profile.groups) && typeof profile.groups === 'string') {
          profile.groups = profile.groups.trim().split(',')
        }
      }
      else {
        profile.groups = []
      }

      const categories = []

      if(profile.categories && Array.isArray(profile.categories)) {
        for(let i = 0; i < profile.categories.length; i++) {
          if(profile.categories[i].uuid !== null && typeof profile.categories[i].uuid !== 'undefined') {
            categories.push(profile.categories[i].uuid)
          }
          else {
            categories.push(profile.categories[i])
          }
        }
      }

      profile.categories = categories

      if(Array.isArray(profile.otherPaymentMethodInstances) && profile.otherPaymentMethodInstances.length > 0) {
        // We just want to store the uuids as an array and make sure there are no null values in it
        profile.otherPaymentMethodInstances = profile.otherPaymentMethodInstances.map(instance => instance.uuid).filter(i => i)
      } else {
        profile.otherPaymentMethodInstances = []
      }

			if(Array.isArray(profile.paymentMethodInstances) && profile.paymentMethodInstances.length > 0) {
        // We just want to store the uuids as an array and make sure there are no null values in it
        profile.paymentMethodInstances = profile.paymentMethodInstances.map(instance => instance.uuid).filter(i => i)
      } else {
        profile.paymentMethodInstances = []
      }

      // This namespace is required, this should change depending on "marketplace" selection

      const body = {
        query: 'mutation updateYessProfile($profile: UpdateYessProfile!) {\n' +
          '  updateYessProfile(profile: $profile) {\n' +
          '    title\n' +
          '  }\n' +
          '}',
        variables: {
          profile: profile
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.updateYessProfile
      })
    },
    getMarketplaces: ({ state }) => {
          const body = {
            query: 'query GetMarketplaces {\n' +
                ' marketplaces {\n' +
                '   id\n' +
                '   name\n' +
                '   namespace\n' +
                '   intro\n' +
                '   urlScheme\n' +
                '   phoneExtensions\n' +
                '   defaultPhoneExtension\n' +
                '   description\n' +
                '   defaultLanguage\n' +
                '   defaultCountry\n' +
                '   defaultCurrency\n' +
                '   brightLogo\n' +
                '   darkLogo\n' +
                '   bundleId\n' +
                '   appleAppId\n' +
                '   homeImage\n' +
                '   heroImage\n' +
                '   appIcon\n' +
                '   welcomeMessage\n' +
                '   welcomeSubMessage\n' +
                '   primaryColor\n' +
                '   secondaryColor\n' +
                '   baseFontFamily\n' +
                '   baseFontColor\n' +
                '   headingFontFamily\n' +
                '   headingFontColor\n' +
                '   buttonFontFamily\n' +
                '   buttonFontColor\n' +
                '   displayName\n' +
                '   organization {\n' +
                '     uuid\n' +
                '   }\n' +
                '   tabs {\n' +
                '     key\n' +
                '     path\n' +
                '     title\n' +
                '     showInApp\n' +
                '     icon\n' +
                '   }\n' +
                ' }\n' +
                '}\n'
          }

          return axios.post(state.graphUrl, body).then(result => {
            if(result && result.data && result.data.data && Array.isArray(result.data.data.marketplaces)) {
              return result.data.data.marketplaces.filter(marketplace => marketplace && marketplace.organization && marketplace.organization.uuid === state.organization.uuid)
            }
            return []
          }).catch(error => {
            return []
          })
    },
    getGoogleFonts: ({state}) => {
      const body = {
        query: 'query getGoogleFonts{\n' +
          ' googleFonts {\n' +
          '   uuid\n' +
          '   family' +
          ' }\n' +
          '}\n' ,
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data && Array.isArray(result.data.data.googleFonts)) {
          return result.data.data.googleFonts
        }
      }).catch(() => {
        return []
      })
    },
    updateMarketplace: ({state}, marketplace) => {
      const body = {
        query: 'mutation updateMarketplace($marketplace: UpdateMarketplace!){\n' +
          ' updateMarketplace(marketplace: $marketplace) {\n' +
          '   id\n' +
          '   name\n' +
          '   namespace\n' +
          '   intro\n' +
          '   urlScheme\n' +
          '   phoneExtensions\n' +
          '   description\n' +
          '   defaultLanguage\n' +
          '   brightLogo\n' +
          '   darkLogo\n' +
          '   bundleId\n' +
          '   appleAppId\n' +
          '   homeImage\n' +
          '   heroImage\n' +
          '   appIcon\n' +
          '   welcomeMessage\n' +
          '   welcomeSubMessage\n' +
          '   primaryColor\n' +
          '   secondaryColor\n' +
          '   baseFontFamily\n' +
          '   baseFontColor\n' +
          '   headingFontFamily\n' +
          '   headingFontColor\n' +
          '   buttonFontFamily\n' +
          '   buttonFontColor\n' +
          '   displayName\n' +
          '   organization {\n' +
          '     uuid\n' +
          '   }\n' +
          '   tabs {\n' +
          '     key\n' +
          '     path\n' +
          '     title\n' +
          '     showInApp\n' +
          '     icon\n' +
          '   }\n' +
          ' }\n' +
          '}',
        variables: {
          marketplace: marketplace
        },
      }
      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data && result.data.data.updateMarketplace) {
          return result.data.data.updateMarketplace
        }
        return null
      }).catch(() => {
        return null
      })
    },
    createMarketplace: ({state}, marketplace) => {
      const body = {
        query: 'mutation createMarketplace($marketplace: NewMarketplace!){\n' +
          ' createMarketplace(marketplace: $marketplace) {\n' +
          '   id\n' +
          '   name\n' +
          '   namespace\n' +
          '   intro\n' +
          '   urlScheme\n' +
          '   phoneExtensions\n' +
          '   description\n' +
          '   defaultLanguage\n' +
          '   brightLogo\n' +
          '   darkLogo\n' +
          '   bundleId\n' +
          '   appleAppId\n' +
          '   homeImage\n' +
          '   heroImage\n' +
          '   appIcon\n' +
          '   welcomeMessage\n' +
          '   welcomeSubMessage\n' +
          '   primaryColor\n' +
          '   secondaryColor\n' +
          '   baseFontFamily\n' +
          '   baseFontColor\n' +
          '   headingFontFamily\n' +
          '   headingFontColor\n' +
          '   buttonFontFamily\n' +
          '   buttonFontColor\n' +
          '   displayName\n' +
          '   organization {\n' +
          '     uuid\n' +
          '   }\n' +
          '   tabs {\n' +
          '     key\n' +
          '     path\n' +
          '     title\n' +
          '     showInApp\n' +
          '     icon\n' +
          '   }\n' +
          ' }\n' +
          '}',
        variables: {
          marketplace: marketplace
        },
      }
      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data && result.data.data.createMarketplace) {
          return result.data.data.createMarketplace
        }
        return null
      }).catch(() => {
        return null
      })
    },
    getYessProfiles: ({ commit, state }, marketplaceNamespace) => {

      commit('updateGettingYessProfiles', true)

      // This namespace is required, this should change depending on "marketplace" selection
      let namespace = 'yess'
      if(marketplaceNamespace) {
        namespace = marketplaceNamespace
      }

      const body = {
        query: 'query {\n' +
          '  yessProfiles(namespace: "' +  namespace + '") {\n' +
          '    slug\n' +
          '    title\n' +
          '    description\n' +
          '    type\n' +
          '    groups\n' +
          '    active\n' +
          '    namespace\n' +
          '    discountPercent\n' +
          '    location {\n' +
          '     uuid\n' +
          '     label\n' +
          '    }\n' +
          '    bookTableItem {\n' +
          '     uuid\n' +
          '     title\n' +
          '    }\n' +
          '    channel {\n' +
          '     uuid\n' +
          '     label\n' +
          '    }\n' +
          '    categories {\n' +
          '     uuid\n' +
          '     name\n' +
          '    }\n' +
          '    organization {\n' +
          '     uuid\n' +
          '     label\n' +
          '    }\n' +
          '    availableActivities {\n' +
          '     uuid\n' +
          '     title\n' +
          '    }\n' +
          '    paymentMethodInstances {\n' +
          '     namespace\n' +
          '     title\n' +
          '     description\n' +
          '     uuid\n' +
          '    }\n' +
          '    otherPaymentMethodInstances {\n' +
          '     namespace\n' +
          '     title\n' +
          '     description\n' +
          '     uuid\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        commit('updateYessProfiles', result.data.data.yessProfiles)
        return result.data.data.yessProfiles
      }).finally(() => {
        commit('updateGettingYessProfiles', false)
      })
    },
    disableYessProfile: ({ state }, profile) => {
      if(typeof profile === 'undefined' || profile === null || Object.keys(profile).length < 1) {
        return false
      }

      const profileSlug = profile.slug
      const namespace = 'yess'

      const body = {
        query: 'mutation {\n' +
          '   disableYessProfile(profile: "' + profileSlug + '", namespace: "' + namespace + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data) {
          return result.data.data.disableYessProfile
        }
        return false
      })
    },
    enableYessProfile: ({ state }, profile) => {
      if(typeof profile === 'undefined' || profile === null || Object.keys(profile).length < 1) {
        return false
      }

      const profileSlug = profile.slug
      const namespace = 'yess'

      const body = {
        query: 'mutation {\n' +
          '   enableYessProfile(profile: "' + profileSlug + '", namespace: "' + namespace + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data) {
          return result.data.data.enableYessProfile
        }
        return false
      })
    },
    getYessGroups: ({ state }) => {
      // This namespace is required, this should change depending on "marketplace" selection
      let namespace = 'yess'

      const body = {
        query: 'query {\n' +
          '  yessGroups(namespace: "' + namespace + '") {\n' +
          '    slug\n' +
          '    title\n' +
          '    description\n' +
          '    primaryColor\n' +
          '    accentColor\n' +
          '    redirect\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.yessGroups
      })
    },
    /**
     * Updates Yess profiles cache
     * NOTE: Only available to SalesCloud staff
     * ATTN: Will always return true
     */
    updateYessProfilesCache: ({ state }) => {
      const body = {
        query: `mutation {\n
          updateYessProfilesCache
        }`
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.updateYessProfilesCache
      })
    },
    getInvoices: ({ commit, state }, { skip, limit, conditions }) => {

      if(!skip) {
        skip = 0
      }

      if(!limit) {
        limit = 10000
      }

      const body = {
        query: 'query invoices($conditions: [QueryCondition]) {\n' +
          '  invoices(skip: ' + skip + ',limit: ' + limit + ', conditions: $conditions) {\n' +
          '    uuid\n' +
          '    state\n' +
          '    created\n' +
          '    isCredited\n' +
          '    creditInvoice\n' +
          '    creditInvoiceMessage\n' +
          '    comment\n' +
          '    reference\n' +
          '    invoiceNumber\n' +
          '    channel {\n' +
          '     uuid\n' +
          '     label\n' +
          '    }\n' +
          '    lineItems {\n' +
          '     uuid\n' +
          '     label\n' +
          '     created\n' +
          '     quantity\n' +
          '     discountPercent\n' +
          '     unitPrice {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '     }\n' +
          '     totalPrice {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '     }\n' +
          '    }\n' +
          '    location {\n' +
          '     uuid\n' +
          '     label\n' +
          '    }\n' +
          '    orderId\n' +
          '    customer {\n' +
          '     uuid \n' +
          '     email \n' +
          '     ssn \n' +
          '     address {\n' +
          '       name_line\n' +
          '     }\n' +
          '    }\n' +
          '    balance {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    orderTotal {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    channel {\n' +
          '     label\n' +
          '    }\n' +
          '    payments {\n' +
          '     namespace\n' +
          '     amount\n' +
          '     status\n' +
          '    }\n' +
          '    externalDeliveryResults {\n' +
          '     namespace\n' +
          '     message\n' +
          '     success\n' +
          '     created\n' +
          '    }\n' +
          '  }\n' +
          '}',
        variables: {
          conditions: conditions
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        commit('updateInvoices', result.data.data.invoices)
        return result.data.data.invoices
      }).catch(() => {
        return []
      })
    },
    getOrders: ({ commit, state }, { skip, limit, conditions, sort, startTimeInMillis, endTimeInMillis }) => {

      sort = sort || 'desc'
      limit = limit || 500
      skip = skip || 0
      startTimeInMillis = startTimeInMillis || null
      endTimeInMillis = endTimeInMillis || null

      const body = {
        query: 'query ordersQuery($conditions: [QueryCondition]) {\n' +
          '  orders(skip: ' + skip + ', limit: ' + limit + ', startTimeInMillis: ' + startTimeInMillis + ', endTimeInMillis: ' + endTimeInMillis + ', conditions: $conditions) {\n' +
          '    uuid\n' +
          '    state\n' +
          '    created\n' +
          '    orderId\n' +
          '    comment\n' +
          '    reference\n' +
          '    entangleKey\n' +
          '    balance {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    totalAmount {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '     locationVariation {\n' +
          '       label\n' +
          '     }\n' +
          '    lineItems {\n' +
          '     uuid\n' +
          '     label\n' +
          '     quantity\n' +
          '     created\n' +
          '     line_item_id\n' +
          '     comment\n' +
          '     discountPercent\n' +
          '     unitPrice {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '       taxRate {\n' +
          '         uuid\n' +
          '         rate\n' +
          '         displayTitle\n' +
          '       }\n' +
          '     }\n' +
          '     totalPrice {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '     }\n' +
          '     variations {\n' +
          '       label\n' +
          '     }\n' +
          '    }\n' +
          '    payments {\n' +
          '     uuid\n' +
          '     amount\n' +
          '     currency_code\n' +
          '     namespace\n' +
          '     created\n' +
          '     message\n' +
          '     status\n' +
          '    }\n' +
          '    channel {\n' +
          '     uuid\n' +
          '     label\n' +
          '    }\n' +
          '    customer {\n' +
          '     uuid\n' +
          '     customer_id\n' +
          '     email\n' +
          '     phone\n' +
          '     ssn\n' +
          '     active\n' +
          '     address {\n' +
          '       name_line\n' +
          '     }\n' +
          '    }\n' +
          '    externalDeliveryResults {\n' +
          '      namespace\n' +
          '      message\n' +
          '      created\n' +
          '      success\n' +
          '     }\n' +
          '     fulfillment\n' +
          '     origin\n' +
          '     sequence\n' +
          '     coupon {\n' +
          '       uuid\n' +
          '       code\n' +
          '       label\n' +
          '     }\n' +
          '     couponCalculation {\n' +
          '       lineItems {\n' +
          '         uuid\n' +
          '         quantity\n' +
          '         apply\n' +
          '         discountPercent\n' +
          '       }\n' +
          '       coupon {\n' +
          '         uuid\n' +
          '         code\n' +
          '         label\n' +
          '       }\n' +
          '     }\n' +
          '  }\n' +
          '}',
          variables: {
            conditions: conditions
          }
      }

      return axios.post(state.graphUrl, body).then(result => {
        commit('addOrders', result.data.data.orders)
        return result.data.data.orders
      })
    },
    getOrderByUUID: ({ commit, state }, { uuid }) => {
      const body = {
        query: 'query {\n' +
          '  order(uuid: "' + uuid + '") {\n' +
          '    uuid\n' +
          '    state\n' +
          '    created\n' +
          '    orderId\n' +
          '    reference\n' +
          '    comment\n' +
          '    entangleKey\n' +
          '    balance {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    payments {\n' +
          '     namespace\n' +
          '     amount\n' +
          '     currency_code\n' +
          '     message\n' +
          '     status\n' +
          '    }\n' +
          '     locationVariation {\n' +
          '       label\n' +
          '     }\n' +
          '    lineItems {\n' +
          '     uuid\n' +
          '     label\n' +
          '     quantity\n' +
          '     namespace\n' +
          '     created\n' +
          '     line_item_id\n' +
          '     comment\n' +
          '     dynamicDescription\n' +
          '     discountPercent\n' +
          '     variations {\n' +
          '       label\n' +
          '     }\n' +
          '     unitPrice {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '       taxRate {\n' +
          '         uuid\n' +
          '         rate\n' +
          '         displayTitle\n' +
          '       }\n' +
          '     }\n' +
          '     totalPrice {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '     }\n' +
          '     item {\n' +
          '       uuid\n' +
          '       title\n' +
          '     }\n' +
          '    }\n' +
          '    totalAmount {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    channel {\n' +
          '     label\n' +
          '    }\n' +
          '    customer {\n' +
          '     address {\n' +
          '       name_line\n' +
          '     }\n' +
          '    }\n' +
          '    data\n' +
          '    fulfillment\n' +
          '    origin\n' +
          '    sequence\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        commit('addOrders', [result.data.data.order])
        return result.data.data.order
      })
    },
    updateOrderData: ({ state }, payload) => {
      const query = {
        query: 'mutation UpdateOrderData($uuid: String!, $data: JSON) {\n' +
          ' updateOrderData(uuid: $uuid, data: $data) \n' +
          '}',
        variables: {
          uuid: payload.uuid,
          data: payload.data
        }
      }
      return axios.post(state.graphUrl, query).then(result => {
        return result.data.data.updateOrderData
      })
    },
    getTasksByEntangleKey: ({ state }, payload) => {

      const conditions = [{
        operator: 'equals',
        property: 'entangleKey',
        value: payload.entangleKey,
      }]

      const body = {
        query : 'query tasks($conditions: [QueryCondition]) {\n' +
            '  tasks(conditions: $conditions) {\n' +
            '    uuid\n' +
            '    namespace\n' +
            '    staff {\n' +
            '       uuid\n' +
            '       address {\n' +
            '           name_line\n' +
            '       }\n' +
            '    }\n' +
            '    line_item\n {' +
            '      line_item_label\n' +
            '    }\n' +
            '    entangleKey\n' +
            '    created\n' +
            '    changed\n' +
            '    status\n' +
            '    user {\n' +
            '     uid\n' +
            '     displayName\n' +
            '    }\n' +
            '    message\n' +
            '  }\n' +
            '}',
        variables: {
          conditions: conditions
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data && result.data.data.tasks) {
          return result.data.data.tasks
        }
        return null
      })

    },
    getAvailableTimeSlotsForBookings: ({state}, payload) => {

      const date = new Date(payload.selectedDate)
      const startTimeInMillis = date.setUTCHours(0,0,0,0)
      const endTimeInMillis = date.setUTCHours(23,59,59,999)

      const body = {
        query : 'query {\n' +
          '  availableTimeSlots(organization: "' + state.user.organization.uuid + '", item: "' + payload.itemUUID + '", channel: "' + payload.channelUUID + '", location: "' + payload.locationUUID + '", startTimeInMillis: ' + startTimeInMillis + ', endTimeInMillis: ' + endTimeInMillis + ', sessionToken: "") {\n' +
          '     label\n' +
          '     timestampInMillis\n' +
          '     paddingInMillis\n' +
          '     availableCount\n' +
          '     bookingCount\n' +
          '  }\n' +
          '}',
        variables: {
          startTimeInMillis: startTimeInMillis,
          endTimeInMillis: endTimeInMillis
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.availableTimeSlots
      })
    },
    getAvailableTimeSlots: ({ state, commit }, payload) => {

      commit('updateGettingAvailableTimeSlots', true)

      const query = {
        query: 'query {\n' +
          '    availableTimeSlots(sessionToken: "' + state.sessionToken + '", item: "' + payload.itemUUID + '", channel: "' + payload.channelUUID + '",  organization: "' + state.organization.uuid + '", location: "' + payload.locationUUID + '", startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: ' + payload.endTimeInMillis + ') {\n' +
          '     label\n' +
          '     timestampInMillis\n' +
          '     paddingInMillis\n' +
          '     reservedForCurrentSession\n' +
          '     reservationCount\n' +
          '     bookingCount\n' +
          '     availableCount\n' +
          '     isBlocked\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, query).then(result => {
        let slots = result.data.data.availableTimeSlots
        if(slots) {
          commit('updateAvailableTimeSlots', slots)
        }
        return slots
      }).finally(() => {
        commit('updateGettingAvailableTimeSlots', false)
      })
    },
    reserveAvailableTimeSlot: ({ state, commit }, payload) => {
      commit('updateReservingSlot', true)
      const query = {
        query: 'mutation {\n' +
          '  reserveAvailableTimeSlot(sessionToken: "' + state.sessionToken + '", organization: "' + state.organization.uuid + '", item: "' + payload.itemUUID + '", startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: ' + payload.endTimeInMillis + ', quantity: ' + payload.quantity + ')\n' +
          '}'
      }

      return axios.post(state.graphUrl, query)
        .then(result => {
          return result
        })
        .finally(() => {
          commit('updateReservingSlot', false)
        })
    },
    releaseAvailableTimeSlot: ({ state, commit }, payload) => {
      commit('updateReleasingSlot', true)

      const query = {
        query: 'mutation {\n' +
          '  releaseAvailableTimeSlot(sessionToken: "' + state.sessionToken + '", organization: "' + state.organization.uuid + '", item: "' + payload.itemUUID + '", startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: ' + payload.endTimeInMillis + ')\n' +
          '}'
      }

      return axios.post(state.graphUrl, query)
        .then(result => {
          return result
        })
        .finally(() => {
          commit('updateReleasingSlot', false)
        })
    },
    getBills: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  billing {\n' +
          '    uuid\n' +
          '    invoiceNumber\n' +
          '    totalAmount {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '    }\n' +
          '    balance {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '    }\n' +
          '    created\n' +
          '    state\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateBills', result.data.data.billing)
          return result.data.data.billing
        }
      })

    },
    getStockEntries: ({ commit, state }, {entityType, entityId}) => {

      const body = {
        query: 'query {\n' +
          '  stockEntries(entityType: "' + entityType + '", entityId: "' + entityId + '") {\n' +
          '    priorAmount\n' +
          '    newAmount\n' +
          '    unitOfChange\n' +
          '    unitOfMeasure\n' +
          '    timestamp\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.stockEntries.sort((s1, s2) => s2.timestamp - s1.timestamp)
      })
    },
    getSites: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  sites {\n' +
          '    site_name\n' +
          '    preview_site_url\n' +
          '  }\n' +
          '}'
      }

      axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateSites', result.data.data.sites)
        }
      })

    },
    createCustomer: ({ state, dispatch }, customer) => {
      if (!customer.address) {
        customer.address = {}
      }
      customer.address.name_line = customer.name_line
      customer.address.country = customer.country

      delete customer.name_line
      delete customer.country
      delete customer.currency

      const body = {
        query: 'mutation createCustomer($customer: NewCustomer!) {\n' +
          '  createCustomer(customer: $customer) {\n' +
          '    uuid\n' +
          '  }\n' +
          '}',
        variables: {
          customer: customer
        }
      }

      return axios.post(state.graphUrl, body)
        .then(result => {
          const createdCustomer = result.data?.data?.createCustomer
          if (createdCustomer) {
            dispatch('getCustomers', {})
          } else {
            console.warn('[store.createCustomer] No customer created', result)
            if(result.data.errors) {
              throw new Error(result.data.errors[0]?.message || 'Unknown error')
            } else {
              console.warn('[store.createCustomer] Unknown data in response', result)              
            }
          }
          return createdCustomer
        })
    },
    updateCustomer: ({ state, dispatch }, customer) => {

      if (typeof customer.name_line !== 'undefined') {
        customer.address.name_line = customer.name_line
        delete customer.name_line
      }

      if (typeof customer.country !== 'undefined') {
        customer.address.country = customer.country
        delete customer.country
      }

      const body = {
        query: 'mutation updateCustomer($customer: UpdateCustomer!) {\n' +
          '  updateCustomer(customer: $customer) {\n' +
          '    uuid\n' +
          '    address{\n' +
          '      name_line\n' +
          '    }\n' +
          '  }\n' +
          '}',
        variables: {
          customer: customer
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        const updatedCustomer = result.data.data.updateCustomer
        if (updatedCustomer) {
          return dispatch('getCustomers', {}).then(() => {
            return updatedCustomer
          })
        }
      })
    },
    createStaff: ({ state, commit, dispatch }, staff) => {
      commit('setIsUpdatingStaff', true)

      const staffToCreate = {
        uuid: staff.uuid,
        label: staff.label,
        ssn: staff.ssn ? staff.ssn.replace(/\D/g, '') : null,
        phone: staff.phone,
        email: staff.email,
        pin: staff.pin,
        is_pos_admin: staff.is_pos_admin,
        allowed_discounts: staff.allowed_discounts,
        active: staff.active,
        address: {
          name_line: staff.address.name_line
        },
        hideInEpos: Boolean(staff.hideInEpos)
      }

      if (staff.standardRate) {
        staffToCreate.standardRate = staff.standardRate
      }

      if (staff.eveningRate) {
        staffToCreate.eveningRate = staff.eveningRate
      }

      if (staff.weekendRate) {
        staffToCreate.weekendRate = staff.weekendRate
      }

      if (staff.publicHolidayRate) {
        staffToCreate.publicHolidayRate = staff.publicHolidayRate
      }

      if (staff.majorPublicHolidayRate) {
        staffToCreate.majorPublicHolidayRate = staff.majorPublicHolidayRate
      }

      const body = {
        query: 'mutation createStaffLegacy($staff: NewStaff!) {\n' +
          '  createStaffLegacy(staff: $staff) {\n' +
          '    uuid\n' +
          '  }\n' +
          '}',
        variables: {
          staff: staffToCreate
        }
      }

      return axios.post(state.graphUrl, body)
        .then(result => {
          const { createStaffLegacy } = result.data.data
          if (createStaffLegacy && createStaffLegacy.uuid) {
            return dispatch('getStaff').then(() => {
              return createStaffLegacy.uuid
            })
          }
        }).finally(() => {
          commit('setIsUpdatingStaff', false)
        })
    },
    updateStaffStatus: ({ state, commit }, staff) => {
      commit('setIsUpdatingStaff', true)

      const body = {
        query:
          'mutation updateStaff($staff: UpdateStaff!) {\n' +
          '   updateStaff(staff: $staff) {\n' +
          '      uuid\n' +
          '      active\n' +
          '   }\n' +
          '}',
        variables: {
          staff: staff
        }
      }

      return axios.post(state.graphUrl, body)
        .then(result => {
          const updateData = result.data.data.updateStaff
          const currentStaff = state.staff
          const index = currentStaff.findIndex(s => s.uuid === updateData.uuid)
          currentStaff[index].active = updateData.active
          commit('updateStaff', currentStaff)
          return result.data.data.updateStaff
        })
        .finally(() => {
          commit('setIsUpdatingStaff', false)
        })
    },
    updateLocation:  ({ state, commit }, { location }) => {
      const locationFormatted = location

      delete locationFormatted.variations

      for (let l = 0; l < locationFormatted.opening_hours.length; l++) {
        if (!locationFormatted.opening_hours[l].starthours && !locationFormatted.opening_hours[l].endhours) {
          delete locationFormatted.opening_hours[l]
        }
      }
      if (locationFormatted.gps) {
        locationFormatted.gps.geom = null
      }

      if(locationFormatted.excludedLocationVariationsFromBookings !== null && locationFormatted.excludedLocationVariationsFromBookings !== undefined && locationFormatted.excludedLocationVariationsFromBookings.length > 0) {
        for(let i in locationFormatted.excludedLocationVariationsFromBookings) {
          const locationVariation = locationFormatted.excludedLocationVariationsFromBookings[i]

          if(typeof locationVariation === 'object') {
            locationFormatted.excludedLocationVariationsFromBookings[i] = locationVariation.uuid + ''
          }
          else {
            locationFormatted.excludedLocationVariationsFromBookings[i] = locationVariation + ''
          }

        }
      }

      if(locationFormatted.excludedZonesFromBookings !== null && locationFormatted.excludedZonesFromBookings !== undefined && locationFormatted.excludedZonesFromBookings.length > 0) {
        for(let i in locationFormatted.excludedZonesFromBookings) {
          const excludedZone = locationFormatted.excludedZonesFromBookings[i]

          if(typeof excludedZone === 'object') {
            locationFormatted.excludedZonesFromBookings[i] = excludedZone.uuid
          }
        }
      }

      if(locationFormatted.excludedZonesFromOnlineBookings !== null && locationFormatted.excludedZonesFromOnlineBookings !== undefined && locationFormatted.excludedZonesFromOnlineBookings.length > 0) {
        for(let i in locationFormatted.excludedZonesFromOnlineBookings) {
          const excludedZone = locationFormatted.excludedZonesFromOnlineBookings[i]

          if(typeof excludedZone === 'object') {
            locationFormatted.excludedZonesFromOnlineBookings[i] = excludedZone.uuid
          }
        }
      }

      if(locationFormatted.preferredChannel && locationFormatted.preferredChannel.uuid) {
        locationFormatted.preferredChannel = locationFormatted.preferredChannel.uuid
      }

      const body = {
        query: 'mutation updateLocation($location: UpdateLocation!) {\n' +
          '  updateLocation(location: $location) {\n' +
          '    uuid\n' +
          '  }\n' +
          '}',
        variables: {
          location: locationFormatted
        }
      }

      return axios.post(state.graphUrl, body, {
        params: {
          time: new Date().getTime()
        }
      }).then(result => {
        return result.data.data.updateLocation
      }).catch(() => {
        return null
      })
    },
    createLocation: ({ state, commit }, { location, ept }) => {
      if(!location) {
        return null
      }

      if(Array.isArray(location.opening_hours)) {
        location.opening_hours = location.opening_hours.filter(opening_hour => opening_hour && opening_hour.starthours && opening_hour.endhours)
      }

      if(location.gps) {
        location.gps.geom = null
      }

      if(Array.isArray(location.excludedLocationVariationsFromBookings)) {
        location.excludedLocationVariationsFromBookings = location.excludedLocationVariationsFromBookings.reduce((excludeArray, variation) => {
          if(variation) {
            if(typeof variation === 'object' && variation.uuid) {
              excludeArray.push(variation.uuid.toString())
            } else {
              excludeArray.push(variation.toString())
            }
          }
          return excludeArray
        }, [])
      }

      if(Array.isArray(location.excludedZonesFromBookings)) {
        location.excludedZonesFromBookings = location.excludedZonesFromBookings.reduce((excludeArray, zone) => {
          if(zone) {
            if(typeof zone === 'object') {
              excludeArray.push(zone.uuid.toString())
            }
          }
          return excludeArray
        }, [])
      }

      if(Array.isArray(location.excludedZonesFromOnlineBookings)) {
        location.excludedZonesFromOnlineBookings = location.excludedZonesFromOnlineBookings.reduce((excludeArray, zone) => {
          if(zone) {
            if(typeof zone === 'object') {
              excludeArray.push(zone.uuid.toString())
            }
          }
          return excludeArray
        }, [])
      }

      if(location.preferredChannel && location.preferredChannel.uuid) {
        location.preferredChannel = location.preferredChannel.uuid
      }

      const body = {
        query: 'mutation CreateNewLocation($location: NewLocation!) {\n' +
            ' createLocation(location: $location) {\n' +
            '   uuid\n' +
            ' }\n' +
            '}',
        variables: {
          location: location
        }
      }
      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data) {
          return response.data.data.createLocation
        }
        return null
      })
    },
    updateLocationVariation: ({state}, { locationVariation }) => {
      const data = {
        query: `mutation updateLocationVariation ($locationVariation: UpdateLocationVariation!){
          updateLocationVariation(locationVariation: $locationVariation) {
            label
            status
            uuid
            x
            y
            shape
            zone {
              uuid
              name
            }
            standardPersons
            minimumPersons
            maximumPersons
            sorting_weight
          }
        }`,
        variables: {
          locationVariation: locationVariation
        }
      }

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: data
      }).then(res => {
        return res.data.data.updateLocationVariation
      })
    },
    createLocationVariation: ({state}, { locationVariation }) => {
      const data = {
        query: `mutation createLocationVariation ($locationVariation: NewLocationVariation!){
          createLocationVariation(locationVariation: $locationVariation) {
            label
            status
            uuid
            x
            y
            shape
            zone {
              uuid
              name
            }
            standardPersons
            minimumPersons
            maximumPersons
            sorting_weight
          }
        }`,
        variables: {
          locationVariation: locationVariation
        }
      }

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: data
      }).then(res => {
        return res.data.data.createLocationVariation
      })
    },
    createLocationVariationCombination: ({state}, { combination }) => {
      const data = {
        query: 'mutation createLocationVariationCombination($variationCombination: NewLocationVariationCombination!) {\n' +
          '  createLocationVariationCombination(combination: $variationCombination) {\n' +
          '    uuid\n' +
          '    label\n' +
          '    location {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    locationVariations {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    changedTimeInMillis\n' +
          '    createdTimeInMillis\n' +
          '    active\n' +
          '  }\n' +
          '}',
        variables: {
          variationCombination: combination
        }
      }
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: data
      }).then(res => {
        return res.data.data.createLocationVariationCombination
      })
    },
    createMultipleLocationVariationCombinations: ({state}, { combinations }) => {
      const data = {
        query: 'mutation createMultipleLocationVariationCombinations($variationCombinations: [NewLocationVariationCombination]!) {\n' +
            '  createMultipleLocationVariationCombinations(combinations: $variationCombinations) {\n' +
            '    uuid\n' +
            '    label\n' +
            '    location {\n' +
            '      uuid\n' +
            '      label\n' +
            '    }\n' +
            '    locationVariations {\n' +
            '      uuid\n' +
            '      label\n' +
            '    }\n' +
            '    changedTimeInMillis\n' +
            '    createdTimeInMillis\n' +
            '    active\n' +
            '  }\n' +
            '}',
        variables: {
          variationCombinations: combinations
        }
      }
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: data
      }).then(res => {
        return res.data.data.createMultipleLocationVariationCombinations
      })
    },
    updateLocationVariationCombination: ({state}, { combination }) => {
      const data = {
        query: 'mutation updateLocationVariationCombination($variationCombination: UpdatedLocationVariationCombination!) {\n' +
          '  updateLocationVariationCombination(combination: $variationCombination) {\n' +
          '    uuid\n' +
          '    label\n' +
          '    location {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    locationVariations {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    changedTimeInMillis\n' +
          '    createdTimeInMillis\n' +
          '    active\n' +
          '  }\n' +
          '}',
        variables: {
          variationCombination: combination
        }
      }
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: data
      }).then(res => {
        return res.data.data.updateLocationVariationCombination
      })
    },
    releaseEtag: ({ state }, etag) => {

      const body = {
        query:
          'mutation releaseEtag {\n' +
          '   releaseEtag(tagID: "' + etag.tagID + '") \n' +
          '}',
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.releaseEtag
        })
    },
    updateEtag: ({ state }, etag) => {

      const url = new URL(etag.property)
      const channel = url.searchParams.get('channel')
      const paymentInstance = url.searchParams.get('paymentInstanceUUID')
      const location = url.searchParams.get('l')
      const unitID = url.searchParams.get('unitID')
      const landingPage = url.searchParams.get('landingPage') === 'true'
      const showSubCategoryMenu = url.searchParams.get('showSubCategoryMenu') === 'true'
      const singlePageCategories = url.searchParams.get('singlePageCategories') === 'true'
      const hideListPictures = url.searchParams.get('hideListPictures') === 'true'
      const showWaitingTime = url.searchParams.get('showWaitingTime') === 'true'
      const showOtherItems = url.searchParams.get('showOtherItems') === 'true'
      let categories = url.searchParams.get('category')
      if (categories) {
        categories = categories.split(',')
      }
      const disableVariationSelection = url.searchParams.get('disableVariationSelection') === 'true'
      const language = url.searchParams.get('language')
      const currencyFormatRule = url.searchParams.get('currencyFormatRule')
      const hideClosedCategories = url.searchParams.get('hideClosedCategories') === 'true'
      const hideItemDetails = url.searchParams.get('hideItemDetails') === 'true'
      let exclude = url.searchParams.get('exclude')
      if (exclude) {
        exclude = exclude.split(',')
      }
      let include = url.searchParams.get('include')
      if (include) {
        include = include.split(',')
      }
      const singlePageVariations = url.searchParams.get('singlePageVariations') === 'true'
      const primaryColor = url.searchParams.get('primaryColor')
      const accentColor = url.searchParams.get('accentColor')

      let excludeCategories = url.searchParams.get('excludeCategories')
      if (excludeCategories) {
        excludeCategories = excludeCategories.split(',')
      }

      let limitCategoryOpeningHoursCheck = url.searchParams.get('limitCategoryOpeningHoursCheck')
      if(limitCategoryOpeningHoursCheck === 'false') {
        limitCategoryOpeningHoursCheck = false
      } else {
        limitCategoryOpeningHoursCheck = null
      }

      const mandatoryCartItems = url.searchParams.getAll('addItem')

      const followLocationOpeningHours = url.searchParams.get('followLocationOpeningHours') === 'true'

      const hideBlockedItems = url.searchParams.get('hideBlockedItems') === 'true'

      const enableReservationMode = url.searchParams.get('enableReservationMode') === 'true'
      const slug = url.searchParams.get('slug')
      const widgetUUID = url.searchParams.get('widgetUUID')
      const eventsMode = url.searchParams.get('eventsMode') === 'true'
      const isApplePayDisabled = url.searchParams.get('dap') === 'true'

      let property = url.searchParams.get('property')

      const body = {
        query:
          'mutation updateEtag($etag: UpdateEtag!) {\n' +
          '   updateEtag(etag: $etag) {\n' +
          '      uuid\n' +
          '      property\n' +
          '      tagID\n' +
          '   }\n' +
          '}',
        variables: {
          etag: {
            tagID: etag.tagID,
            channel: channel,
            paymentInstance: paymentInstance,
            location: location,
            unit: unitID,
            landingPage: landingPage,
            showSubCategoryMenu: showSubCategoryMenu,
            singlePageCategories: singlePageCategories,
            hideListPictures: hideListPictures,
            showWaitingTime: showWaitingTime,
            showOtherItems: showOtherItems,
            disableVariationSelection: disableVariationSelection,
            language: language,
            currencyFormatRule: currencyFormatRule,
            hideClosedCategories: hideClosedCategories,
            hideItemDetails: hideItemDetails,
            singlePageVariations: singlePageVariations,
            primaryColor: primaryColor,
            accentColor: accentColor,
            excludeCategories,
            exclude,
            include,
            categories,
            limitCategoryOpeningHoursCheck: limitCategoryOpeningHoursCheck,
            mandatoryCartItems: mandatoryCartItems,
            followLocationOpeningHours: followLocationOpeningHours,
            enableReservationMode: enableReservationMode,
            hideBlockedItems,
            slug,
            widgetUUID,
            eventsMode,
            isApplePayDisabled,
            property: property,
          }
        }
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.updateEtag
        })
    },
    claimEtag: ({ state, dispatch }, etag) => {

      const url = new URL(etag.property)
      const channel = url.searchParams.get('channel')
      const paymentInstance = url.searchParams.get('paymentInstanceUUID')
      const location = url.searchParams.get('l')
      const unitID = url.searchParams.get('unitID')
      const landingPage = url.searchParams.get('landingPage') === 'true'
      const showSubCategoryMenu = url.searchParams.get('showSubCategoryMenu') === 'true'
      const singlePageCategories = url.searchParams.get('singlePageCategories') === 'true'
      const hideListPictures = url.searchParams.get('hideListPictures') === 'true'
      const showWaitingTime = url.searchParams.get('showWaitingTime') === 'true'
      const showOtherItems = url.searchParams.get('showOtherItems') === 'true'
      let categories = url.searchParams.get('category')
      if (categories) {
        categories = categories.split(',')
      }
      const disableVariationSelection = url.searchParams.get('disableVariationSelection') === 'true'
      const language = url.searchParams.get('language')
      const currencyFormatRule = url.searchParams.get('currencyFormatRule')
      const hideClosedCategories = url.searchParams.get('hideClosedCategories') === 'true'
      const hideItemDetails = url.searchParams.get('hideItemDetails') === 'true'
      let exclude = url.searchParams.get('exclude')
      if (exclude) {
        exclude = exclude.split(',')
      }
      let include = url.searchParams.get('include')
      if (include) {
        include = include.split(',')
      }
      const singlePageVariations = url.searchParams.get('singlePageVariations') === 'true'
      const primaryColor = url.searchParams.get('primaryColor')
      const accentColor = url.searchParams.get('accentColor')

      let excludeCategories = url.searchParams.get('excludeCategories')
      if (excludeCategories) {
        excludeCategories = excludeCategories.split(',')
      }

      let limitCategoryOpeningHoursCheck = url.searchParams.get('limitCategoryOpeningHoursCheck')
      if(limitCategoryOpeningHoursCheck === 'false') {
        limitCategoryOpeningHoursCheck = false
      } else {
        limitCategoryOpeningHoursCheck = null
      }

      const hideBlockedItems = url.searchParams.get('hideBlockedItems') === 'true'

      const mandatoryCartItems = url.searchParams.getAll('addItem')

      const followLocationOpeningHours = url.searchParams.get('followLocationOpeningHours') === 'true'

      const enableReservationMode = url.searchParams.get('enableReservationMode') === 'true'
      const slug = url.searchParams.get('slug')
      const widgetUUID = url.searchParams.get('widgetUUID')
      const eventsMode = url.searchParams.get('eventsMode') === 'true'
      const isApplePayDisabled = url.searchParams.get('dap') === 'true'

      let property = url.searchParams.get('property')

      const body = {
        query:
          'mutation claimEtag($etag: ClaimEtag!) {\n' +
          '   claimEtag(etag: $etag) {\n' +
          '      property\n' +
          '      tagID\n' +
          '      unitID\n' +
          '   }\n' +
          '}',
        variables: {
          etag: {
            tagID: etag.tagID,
            channel: channel,
            paymentInstance: paymentInstance,
            location: location,
            unit: unitID,
            landingPage: landingPage,
            showSubCategoryMenu: showSubCategoryMenu,
            singlePageCategories: singlePageCategories,
            hideListPictures: hideListPictures,
            showWaitingTime: showWaitingTime,
            showOtherItems: showOtherItems,
            disableVariationSelection: disableVariationSelection,
            language: language,
            currencyFormatRule: currencyFormatRule,
            hideClosedCategories: hideClosedCategories,
            hideItemDetails: hideItemDetails,
            singlePageVariations: singlePageVariations,
            primaryColor: primaryColor,
            accentColor: accentColor,
            excludeCategories,
            exclude,
            include,
            categories,
            limitCategoryOpeningHoursCheck: limitCategoryOpeningHoursCheck,
            mandatoryCartItems: mandatoryCartItems,
            followLocationOpeningHours: followLocationOpeningHours,
            enableReservationMode: enableReservationMode,
            hideBlockedItems,
            slug,
            widgetUUID,
            eventsMode,
            isApplePayDisabled,
            property: property,
          }
        }
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          let claimedEtag = response.data.data.claimEtag
          if (claimedEtag) {
            return dispatch('getEtags').then(() => {
              return claimedEtag
            })
          }
        })
    },
    updateDashCollection: ({ state }, dashCollection) => {

      const body = {
        query:
          'mutation updateDashCollection($collection: UpdateDashCollection!) {\n' +
          '   updateDashCollection(collection: $collection) {\n' +
          '      uuid\n' +
          '      name\n' +
          '      period\n' +
          '      timeIntervalFrom\n' +
          '      timeIntervalTo\n' +
          '      startTimeInMillis\n ' +
          '      endTimeInMillis\n ' +
          '      channel\n' +
          '      channels\n' +
          '      data\n' +
          '      active\n' +
          '   }\n' +
          '}',
        variables: {
          collection: dashCollection
        }
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.updateDashCollection
        })
    },
    deleteDash: ({ state }, dash) => {

      const body = {
        query:
          'mutation deleteDash {\n' +
          '   deleteDash(dash: "' + dash.uuid + '")\n' +
          '}'
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.deleteDash
        })
    },
    createDashCollection: ({state}, dashCollection) => {
      const body = {
        query:
          'mutation createDashCollection($name: String!) {\n' +
          '  createDashCollection(name: $name ) {\n ' +
          '   name\n ' +
          '   uuid\n ' +
          '   period\n ' +
          '   channel\n ' +
          '   channels\n ' +
          '   data\n ' +
          '   startTimeInMillis\n ' +
          '   endTimeInMillis\n ' +
          '   timeIntervalFrom\n ' +
          '   timeIntervalTo\n '  +
          '   active\n ' +
          '  }\n ' +
          '}\n ',
        variables: {
          name: dashCollection.name
        }
      }
      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.createDashCollection
        })
    },
    deleteDashCollection: ({state}, dashCollection) => {
      const body = {
        query:
          'mutation deleteDashCollection($uuid: String!) {\n' +
          '  deleteDashCollection(uuid: "' + dashCollection.uuid + '") {\n ' +
          '}\n ',
      }
      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.deleteDashCollection
        })
    },
    createDash: ({ state }, dash) => {
      const body = {
        query:
          'mutation createDash($dash: NewDash!) {\n' +
          '   createDash(collection: "' + state.dashCollection.uuid + '", dash: $dash) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      title\n' +
          '      description\n' +
          '      weight\n' +
          '      cols\n' +
          '      content\n' +
          '      data\n' +
          '   }\n' +
          '}',
        variables: {
          dash: dash
        }
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          if(typeof response.data.data.createDash.data === 'string') {
            let dashData = JSON.parse(response.data.data.createDash.data)
            let createdDash = response.data.data.createDash
            createdDash.data = dashData
            return createdDash
          }
          return response.data.data.createDash
        })
    },
    sendWorkReviewInvite: ({ state }, settings) => {

      let mutationString = 'mutation inviteStaffToReviewWork {\n' +
        ' inviteStaffToWorkReview(' +
        'uuid: "' + settings.staff.uuid + '"' +
        (settings.optionalMessage ? ', optionalMessage: "' + settings.optionalMessage + '"' : '') +
        ') }'

      const body = {
        query: mutationString
      }

      return axios.post(state.graphUrl, body).then(response => {
        return response.data.data.inviteStaffToWorkReview
      })
    },
    updateDash: ({ state }, dash) => {
      const body = {
        query:
          'mutation updateDash($dash: UpdateDash!) {\n' +
          '   updateDash(dash: $dash) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      title\n' +
          '      description\n' +
          '      weight\n' +
          '      cols\n' +
          '      content\n' +
          '      data\n' +
          '   }\n' +
          '}',
        variables: {
          dash: dash
        }
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.updateDash
        })
    },
    getDashCollections: ({ state, commit }) => {
      const body = {
        query: 'query {\n' +
          ' dashCollections {\n' +
          '   uuid\n' +
          '   name\n' +
          '   period\n' +
          '   channel\n' +
          '   channels\n' +
          '   timeIntervalFrom\n' +
          '   timeIntervalTo\n' +
          '   startTimeInMillis\n' +
          '   endTimeInMillis\n' +
          '   dashes {\n' +
          '     uuid\n' +
          '     title\n' +
					'     renderType\n' +
          '     description\n' +
          '     disclaimer\n' +
          '     namespace\n' +
          '     cols\n' +
          '     content\n' +
          '     weight\n' +
          '     }\n' +
          '   }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        const dashCollections = response.data.data.dashCollections
        if(dashCollections) {
          commit('updateDashCollections', dashCollections)
          return dashCollections
        }
      })
    },
    updateDashOrder: ({ state }, dashArray) => {
      const body = {
        query:
          'mutation updateDashCollectionOrder($dashes: [UpdateDash!]!) {\n' +
          '   updateDashCollectionOrder(dashes: $dashes) {\n' +
          '      uuid\n' +
          '      namespace\n' +
          '      title\n' +
          '      description\n' +
          '      weight\n' +
          '      cols\n' +
          '      content\n' +
          '      data\n' +
          '   }\n' +
          '}',
        variables: {
          dashes: dashArray
        }
      }

      return axios.post(state.graphUrl, body)
        .then(response => {
          return response.data.data.updateDashCollectionOrder
        })
    },
    updateStaff: ({ state, commit, dispatch }, staff) => {
      commit('setIsUpdatingStaff', true)

      const staffObject = {
        uuid: staff.uuid,
        label: staff.label,
        ssn: staff.ssn ? staff.ssn.replace(/\D/g, '') : null,
        phone: staff.phone,
        email: staff.email,
        pin: staff.pin,
        is_pos_admin: staff.is_pos_admin,
        allowed_discounts: staff.allowed_discounts,
        active: staff.active,
        address: {
          name_line: staff.address.name_line
        },
        hideInEpos: Boolean(staff.hideInEpos)
      }

      if (staff.standardRate && staff.standardRate.amount && staff.standardRate.currency_code) {
        staffObject.standardRate = staff.standardRate
      }
      if (staff.eveningRate && staff.eveningRate.amount && staff.eveningRate.currency_code) {
        staffObject.eveningRate = staff.eveningRate
      }
      if (staff.weekendRate && staff.weekendRate.amount && staff.weekendRate.currency_code) {
        staffObject.weekendRate = staff.weekendRate
      }
      if (staff.publicHolidayRate && staff.publicHolidayRate.amount && staff.publicHolidayRate.currency_code) {
        staffObject.publicHolidayRate = staff.publicHolidayRate
      }
      if (staff.majorPublicHolidayRate && staff.majorPublicHolidayRate.amount && staff.majorPublicHolidayRate.currency_code) {
        staffObject.majorPublicHolidayRate = staff.majorPublicHolidayRate
      }

      const body = {
        query:
          'mutation updateStaff($staff: UpdateStaff!) {\n' +
          '   updateStaff(staff: $staff) {\n' +
          '      uuid\n' +
          '      label\n' +
          '      ssn\n' +
          '      phone\n' +
          '      email\n' +
          '      pin\n' +
          '      is_pos_admin\n' +
          '      allowed_discounts\n' +
          '      hideInEpos\n' +
          '      address {\n' +
          '          name_line\n' +
          '          first_name\n' +
          '          last_name\n' +
          '          thoroughfare\n' +
          '          locality\n' +
          '          country\n' +
          '          sub_administrative_area\n' +
          '          administrative_area\n' +
          '          dependent_locality\n' +
          '          postal_code\n' +
          '          premise\n' +
          '          sub_premise\n' +
          '      }\n' +
          '      standardRate {\n' +
          '          amount\n' +
          '          currency_code\n' +
          '      }\n' +
          '      eveningRate {\n' +
          '          amount\n' +
          '          currency_code\n' +
          '      }\n' +
          '      weekendRate {\n' +
          '          amount\n' +
          '          currency_code\n' +
          '      }\n' +
          '      publicHolidayRate {\n' +
          '          amount\n' +
          '          currency_code\n' +
          '      }\n' +
          '      majorPublicHolidayRate {\n' +
          '          amount\n' +
          '          currency_code\n' +
          '      }\n' +
          '   }\n' +
          '}',
        variables: {
          staff: staffObject
        }
      }
      // NOTE: Multiplying with 1 (one) is a quick and dirty way to change a string to a number

      return axios.post(state.graphUrl, body)
        .then(result => {
          const updateData = result.data.data.updateStaff
          if (updateData) {
            return dispatch('getStaff').then(() => {
              return updateData
            })
          }
        })
        .finally(() => {
          commit('setIsUpdatingStaff', false)
        })
    },
    updateCoupon: ({ state }, payload) => {

      try {
        payload.percentOff = parseFloat(payload.percentOff.toString())
      } catch (e) {
        payload.percentOff = 0.000
      }

      try {
        payload.maxRedemptions = payload.maxRedemptions.toString() * 1
        if(isNaN(payload.maxRedemptions)) {
          payload.maxRedemptions = 0
        }
      } catch (e) {
        payload.maxRedemptions = 0
      }

      try {
        payload.timesRedeemed = payload.timesRedeemed * 1
        if(isNaN(payload.timesRedeemed)) {
          payload.timesRedeemed = 0
        }
      } catch (e) {
        payload.timesRedeemed = 0
      }

      payload.effectiveStartingAtTimestampInMillis = payload.effectiveStartingAtTimestampInMillis > 0 ? payload.effectiveStartingAtTimestampInMillis : null
      payload.effectiveEndingAtTimestampInMillis = payload.effectiveEndingAtTimestampInMillis > 0 ? payload.effectiveEndingAtTimestampInMillis : null
      payload.customerName = (payload.customer && payload.customer.address && payload.customer.address.name_line) ? payload.customer.address.name_line : null
      payload.customerPhone = (payload.customer && payload.customer.phone) ? payload.customer.phone : null
      payload.customerEmail = (payload.customer && payload.customer.email) ? payload.customer.email : null

      const body = {
        query: 'mutation updateCoupon($coupon: UpdateCoupon!) {\n' +
          '  updateCoupon(coupon: $coupon) {\n' +
          '    uuid\n' +
          '    label\n' +
          '    description\n' +
          '    percentOff\n' +
          '    maxRedemptions\n' +
          '    timesRedeemed\n' +
          '    code\n' +
          '    active\n' +
          '    effectiveStartingAtTimestampInMillis\n' +
          '    effectiveEndingAtTimestampInMillis\n' +
          '    validWeekdays\n' +
          '    validItems\n' +
          '    validItemCategories\n' +
          '    validLocations\n' +
          '    validHours {\n' +
          '       startTime\n' +
          '       endTime\n' +
          '    }\n' +
          '    created\n' +
          '    changed\n' +
          '  }\n' +
          '}',
        variables: {
          coupon: {
            uuid: payload.uuid,
            code: payload.code,
            label: payload.label,
            description: payload.description,
            percentOff: payload.percentOff,
            maxRedemptions: payload.maxRedemptions,
            timesRedeemed: payload.timesRedeemed,
            active: payload.active,
            effectiveStartingAtTimestampInMillis: payload.effectiveStartingAtTimestampInMillis,
            effectiveEndingAtTimestampInMillis: payload.effectiveEndingAtTimestampInMillis,
            validWeekdays: payload.validWeekdays,
            validHours: payload.validHours,
            item: payload.couponItem,
            customerName: payload.customerName,
            customerPhone: payload.customerPhone,
            customerEmail: payload.customerEmail,
            validLocations: payload.validLocations
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          //commit('updateCoupon', result.data.data.updateCoupon)
          return result.data.data.updateCoupon
        }
      })

    },
    createCoupon: ({ state, dispatch }, payload) => {

      try {
        payload.percentOff = parseFloat(payload.percentOff.toString())
      } catch (e) {
        payload.percentOff = 0.000
      }

      try {
        payload.maxRedemptions = payload.maxRedemptions.toString() * 1
        if(isNaN(payload.maxRedemptions)) {
          payload.maxRedemptions = 0
        }
      } catch (e) {
        payload.maxRedemptions = 0
      }

      payload.timesRedeemed = 0
      payload.active = true
      payload.effectiveStartingAtTimestampInMillis = payload.effectiveStartingAtTimestampInMillis > 0 ? payload.effectiveStartingAtTimestampInMillis : null
      payload.effectiveEndingAtTimestampInMillis = payload.effectiveEndingAtTimestampInMillis > 0 ? payload.effectiveEndingAtTimestampInMillis : null
      payload.customerName = (payload.customer && payload.customer.address && payload.customer.address.name_line) ? payload.customer.address.name_line : null
      payload.customerPhone = (payload.customer && payload.customer.phone) ? payload.customer.phone : null
      payload.customerEmail = (payload.customer && payload.customer.email) ? payload.customer.email : null

      const body = {
        query: 'mutation createCoupon($coupon: NewCoupon!) {\n' +
          '  createCoupon(coupon: $coupon) {\n' +
          '    code\n' +
          '    label\n' +
          '    description\n' +
          '    percentOff\n' +
          '    maxRedemptions\n' +
          '    timesRedeemed\n' +
          '    active\n' +
          '    effectiveStartingAtTimestampInMillis\n' +
          '    effectiveEndingAtTimestampInMillis\n' +
          '    validWeekdays\n' +
          '    validLocations\n' +
          '    validHours {\n' +
          '       startTime\n' +
          '       endTime\n' +
          '    }\n' +
          '  }\n' +
          '}',
        variables: {
          coupon: {
            code: payload.code,
            label: payload.label,
            description: payload.description,
            percentOff: payload.percentOff,
            maxRedemptions: payload.maxRedemptions,
            timesRedeemed: payload.timesRedeemed,
            active: payload.active,
            effectiveStartingAtTimestampInMillis: payload.effectiveStartingAtTimestampInMillis,
            effectiveEndingAtTimestampInMillis: payload.effectiveEndingAtTimestampInMillis,
            validWeekdays: payload.validWeekdays,
            validHours: payload.validHours,
            item: payload.couponItem,
            customerName: payload.customerName,
            customerPhone: payload.customerPhone,
            customerEmail: payload.customerEmail,
            validLocations: payload.validLocations
          }
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        const createdCoupon = result.data.data.createCoupon
        if (createdCoupon) {
          return dispatch('getCoupons').then(() => {
            return createdCoupon
          })
        }
      }).catch(() => {
        return null
      })

    },

    generateCode: ({state}) => {
      const body = {
        query: 'query {\n' +
          ' generateCode\n' +
          ' }\n'
      }
      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          return result.data.data
        }
      })
    },

    createMultipleCoupons({ state }, { quantity, coupon }) {
      if(typeof quantity !== 'number' || quantity < 0 || typeof coupon === 'undefined' || coupon === null) {
        return []
      }

      const body = {
        query: 'mutation CreateMultipleCoupons($quantity: Int!, $coupon: NewCoupon!) {\n' +
            ' createMultipleCoupons(quantity: $quantity, coupon: $coupon) {\n' +
            '   uuid\n' +
            ' }\n' +
            '}',
        variables: {
          quantity,
          coupon
        }
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data.createMultipleCoupons) {
          return response.data.data.createMultipleCoupons
        }
        return []
      }).catch(() => {
        return []
      })
    },

    getCoupons: ({ commit, state }) => {

      const body = {
        query: 'query{\n' +
          '  coupons {\n' +
          '    uuid\n' +
          '    label\n' +
          '    description\n' +
          '    percentOff\n' +
          '    maxRedemptions\n' +
          '    timesRedeemed\n' +
          '    validFrom\n' +
          '    validUntil\n' +
          '    effectiveStartingAtTimestampInMillis\n' +
          '    effectiveEndingAtTimestampInMillis\n' +
          '    validWeekdays\n' +
          '    validHours {\n' +
          '       startTime\n' +
          '       endTime\n' +
          '    }\n' +
          '    validItems\n' +
          '    validLocations\n' +
          '    validItemCategories\n' +
          '    code\n' +
          '    item {\n' +
          '      uuid\n' +
          '      title\n' +
          '      price {\n' +
          '        amount\n' +
          '        currency_code\n' +
          '      }\n' +
          '    }\n' +
          '    customer {\n' +
          '      address {\n' +
          '        name_line\n' +
          '      }\n' +
          '      phone\n' +
          '      email\n' +
          '    }\n' +
          '    active\n' +
          '    created\n' +
          '    changed\n' +
          '  }\n' +
          '}',
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          let coupons = result.data.data.coupons.sort(function(a,b) {return b.created-a.created})
          commit('updateCoupons', coupons)
        }

        return result.data.data.coupons
      })

    },
    getOffers: ({state}, {conditions}) => {
      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: '{ offers { uuid, title, compatibleWithOtherOffers, discountPercent, type, items { uuid, title }, active, conditions { property, operator, floatValue}}}'
        }
      }).then((response) => {
        return response.data.data.offers
      })
    },
    updateOffer({state}, offer) {
      delete offer.created
      delete offer.changed

      if(offer.discountPercent) {
        try {
          offer.discountPercent = parseFloat(offer.discountPercent)
        } catch {
          // do nothing
        }
      }

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation updateOffer($offer: UpdatedOffer!) { updateOffer(offer: $offer) { uuid, title }}',
          variables: {
            offer
          }
        },
      }).then((response) => {
        return response.data.data.updateOffer
      })
    },
    createOffer({state}, offer) {

      try {
        offer.discountPercent = parseFloat(offer.discountPercent)
      } catch {
        // do nothing
      }

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query: 'mutation  createOffer($offer: NewOffer!) { createOffer(offer: $offer) { uuid, title }}',
          variables: {
            offer
          }
        },
      }).then((response) => {
        return response.data.data.createOffer
      })
    },
    getSubscriptions: async ({ commit, state }, {conditions}) => {
      if(conditions === null || conditions === undefined) {
        conditions = []
      }

      const body = {
        query: 'query subscriptions($conditions: [QueryCondition]) {\n' +
          '  subscriptions(conditions: $conditions) {\n' +
          '    uuid\n' +
          '    payer {\n' +
          '       uuid\n' +
          '       customer_id\n' +
          '       ssn\n' +
          '       phone\n' +
          '       email\n' +
          '       address {\n' +
          '         name_line\n' +
          '         first_name\n' +
          '         last_name\n' +
          '         thoroughfare\n' +
          '         locality\n' +
          '         country\n' +
          '         sub_administrative_area\n' +
          '         administrative_area\n' +
          '         dependent_locality\n' +
          '         postal_code\n' +
          '         premise\n' +
          '         sub_premise\n' +
          '         organisation_name\n' +
          '       }\n' +
          '    }\n' +
          '    receiver {\n' +
          '       uuid\n' +
          '       customer_id\n' +
          '       ssn\n' +
          '       phone\n' +
          '       email\n' +
          '       address {\n' +
          '         name_line\n' +
          '         first_name\n' +
          '         last_name\n' +
          '         thoroughfare\n' +
          '         locality\n' +
          '         country\n' +
          '         sub_administrative_area\n' +
          '         administrative_area\n' +
          '         dependent_locality\n' +
          '         postal_code\n' +
          '         premise\n' +
          '         sub_premise\n' +
          '         organisation_name\n' +
          '       }\n' +
          '    }\n' +
          '    recurringPrice {\n' +
          '     amount\n' +
          '     currency_code\n' +
          '    }\n' +
          '    item {\n' +
          '     uuid\n' +
          '     sku\n' +
          '     title\n' +
          '     price {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '     }\n' +
          '    }\n' +
          '    nextPayment\n' +
          '    paused\n' +
          '    expires\n' +
          '    active\n' +
          '    created\n' +
          '    changed\n' +
          '    cycle\n' +
          '    cycles\n' +
          '    hasCardOnFile\n' +
          '    units\n' +
          '    label\n' +
          '  }\n' +
          '}',
        variables: {
          conditions: conditions
        }
      }

      try {
        const result = await axios.post(state.graphUrl, body)
        let subscriptions = []
        if (result.data.data) {
          subscriptions = result.data.data.subscriptions.filter(subscription => subscription !== null)
          commit('updateSubscriptions', subscriptions)
        }
        return subscriptions
      } catch (error) {
        return []
      }
    },
    userExistsByEmail: ({ state }, email) => {

      const body = {
        query: 'query {\n' +
          '  userExistsByEmail(email: "' + email + '")\n' +
          '}\n'
      }

      return axios.post(state.graphUrl, body).then(result => {

        if (result && result.data && result.data.data) {
          return result.data.data.userExistsByEmail
        }

        return null
      })
    },
    searchUser: async ({ state }, email) => {
      const body = {
        query:  'query searchUsers ($email: String!) {\n' +
                ' users(email: $email) {\n' +
                '   email,\n' +
                '   displayName}}',
        variables: { email }
      }

      const result = await axios.post(state.graphUrl, body)

      return result?.data?.data?.users ?? null
    },
    userExistsByDisplayName: ({ state }, userName) => {

      const body = {
        query: 'query {\n' +
          '  userExistsByDisplayName(displayName: "' + userName + '")\n' +
          '}\n'
      }

      return axios.post(state.graphUrl, body).then(result => {

        if (result && result.data && result.data.data) {
          return result.data.data.userExistsByDisplayName
        }

        return null
      })
    },
    getTeam: ({ commit, state }) => {
      const body = {
        query: 'query {\n' +
          '  team {\n' +
          '    role_id\n' +
          '    user {\n' +
          '       uid\n' +
          '       email\n' +
          '       displayName\n' +
          '       phoneNumber\n' +
          '       active\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result && result.data && result.data.data) {
          commit('updateTeam', result.data.data.team)
          return result.data.data.team
        }
      })
    },
    getOrganizations: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  organizations {\n' +
          '    uuid\n' +
          '    label\n' +
          '    description\n' +
          '    ssn\n' +
          '    email\n' +
          '    phone\n' +
          '    primary_color\n' +
          '    accent_color\n' +
          '    defaultTimezone\n' +
          '    hasDeliveryService\n' +
          '    defaultCurrency {\n' +
          '       code\n' +
          '       name\n' +
          '    }\n' +
          '    credits {\n' +
          '       amount\n' +
          '       currency_code\n' +
          '    }\n' +
          '    address {\n' +
          '       name_line\n' +
          '       first_name\n' +
          '       last_name\n' +
          '       thoroughfare\n' +
          '       locality\n' +
          '       country\n' +
          '       sub_administrative_area\n' +
          '       administrative_area\n' +
          '       dependent_locality\n' +
          '       postal_code\n' +
          '       premise\n' +
          '       sub_premise\n' +
          '       organisation_name\n' +
          '    }\n' +
          '    logo {\n' +
          '       file {\n' +
          '         name\n' +
          '         size\n' +
          '         sources {\n' +
          '           original\n' +
          '           large\n' +
          '           medium\n' +
          '           thumbnail\n' +
          '         }\n' +
          '       }\n' +
          '    }\n' +
          '    cover {\n' +
          '       file {\n' +
          '         name\n' +
          '         size\n' +
          '         sources {\n' +
          '           original\n' +
          '           large\n' +
          '           medium\n' +
          '           thumbnail\n' +
          '         }\n' +
          '       }\n' +
          '    }\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result && result.data && result.data.data) {
          commit('updateOrganizations', result.data.data.organizations)
          return result.data.data.organizations
        } else {
          return []
        }
      })

    },
    createProcessDisplaySystem: ({ commit, state }, payload) => {

      const body = {
        query: 'mutation($new: NewProcessDisplaySystem!) {\n' +
          '  createProcessDisplaySystem(processDisplaySystem: $new) {\n' +
          '    title\n' +
          '  }\n' +
          '}',
        variables: {
          new: payload
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.processDisplaySystem
      })
    },
    createOrderStatusDisplay: ({ commit, state }, payload) => {

      const body = {
        query: 'mutation($new: NewOrderStatusDisplay!) {\n' +
          '  createOrderStatusDisplay(orderStatusDisplay: $new) {\n' +
          '    title\n' +
          '  }\n' +
          '}',
        variables: {
          new: payload
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.orderStatusDisplay
      })
    },
    updateProcessDisplaySystem: ({ commit, state }, payload) => {

      if(payload.hasOwnProperty('changed')) {
        delete payload.changed
      }


      const body = {
        query: 'mutation($update: UpdateProcessDisplaySystem!) {\n' +
          '  updateProcessDisplaySystem(processDisplaySystem: $update) {\n' +
          '    title\n' +
          '  }\n' +
          '}',
        variables: {
          update: payload
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.processDisplaySystem
      })
    },
    updateOrderStatusDisplay: ({ commit, state }, payload) => {

      if(payload.changed) {
        delete payload.changed
      }

      const body = {
        query: 'mutation($update: UpdateOrderStatusDisplay!) {\n' +
          '  updateOrderStatusDisplay(orderStatusDisplay: $update) {\n' +
          '    title\n' +
          '  }\n' +
          '}',
        variables: {
          update: payload
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.orderStatusDisplay
      })
    },
		getOrderStatusDisplays: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  orderStatusDisplays {\n' +
          '    title\n' +
          '    uuid\n' +
          '    active\n' +
          '    channels\n' +
          '    categories\n' +
          '    posChannels\n' +
          '    location\n' +
          '    primaryColor\n' +
          '    useDarkText\n' +
          '    changed\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.orderStatusDisplays
      })
    },
		getReferencedProcessDisplaySystems: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  referencedProcessDisplaySystems {\n' +
          '    title\n' +
          '    uuid\n' +
          '    channels\n' +
          '    printers\n' +
          '    posChannels\n' +
          '    location\n' +
          '    categories\n' +
          '    states\n' +
          '    active\n' +
          '    changed\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        return result.data.data.referencedProcessDisplaySystems
      })
    },
    getUsers: ({ commit, state }) => {

      const body = {
        query: 'query {\n' +
          '  users {\n' +
          '    displayName\n' +
          '    email\n' +
          '    phoneNumber\n' +
          '    lastSignIn\n' +
          '    uid\n' +
          '    active\n' +
          '    isDevice\n' +
          '  }\n' +
          '}'
      }

      return axios.post(state.graphUrl, body).then(result => {

        if (result.data.data) {
          commit('updateUsers', result.data.data.users)
        }

        return result.data.data.users
      })
    },
    getEtags: ({ commit, state }) => {
      const body = {
        query: 'query{ \n' +
          'etags(uuid:"' + state.organization.uuid + '"){\n' +
          '    tagID\n' +
          '    property\n' +
          '    organization\n' +
          '    unitID\n' +
          '}\n' +
          'unclaimedEtags {\n' +
          '    tagID\n' +
          '    property\n' +
          '    organization\n' +
          '    description\n' +
          '}\n' +
          '}'
      }

      axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateEtags', result.data.data.etags)
          commit('updateUnclaimedEtags', result.data.data.unclaimedEtags)
        }
        return result
      })
    },
    // eslint-disable-next-line no-empty-pattern
    emailPDF: ({ state }, payload) => {

      const url = 'https://europe-west1-proton-mainframe.cloudfunctions.net/generatePDF'

      let html = state.exportHtmlHeader
      html += payload.html
      html += state.exportHtmlFooter

      const body = {
        html: html,
        subject: payload.subject,
        title: payload.fileName + '.pdf',
        recipients: payload.recipient + ';',
        replyTo: state.user.email,
        fromName: state.user.displayName + ' - ' + state.organization.label
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          "salescloud-organization": null,
          "authorization": null 
        }
      }

      axios.post(url, body, config).then(() => {})
    },
    createAccount: ({ state }, payload) => {

      const query = {
        query: 'mutation signupOrganizationAndUser($signup: SignupOrganizationAndUser!) {\n' +
          '  signupOrganizationAndUser(signupOrganizationAndUser: $signup) {\n' +
          '     success\n' +
          '     text\n' +
          '  }\n' +
          '}'
        ,
        variables: {
          signup: payload
        }
      }

      return axios.post(state.graphUrl, query).then(result => {
        return result.data.data.signupOrganizationAndUser
      })
    },
    // eslint-disable-next-line no-empty-pattern
    processPaymentRequest: ({ }, payload) => {

      const paymentRequest = payload.paymentRequest
      const paymentMethodInstanceUUID = payload.paymentMethodInstanceUUID

      const url = 'https://service-payment-request.salescloud.is/v/1/f95bbe80-b9c2-40eb-943d-95d696c1acfb/6a7fd054-57a9-4c39-96d5-594d71eaa932/' + paymentMethodInstanceUUID

      return axios.post(url, paymentRequest).then(response => {
        return response.data
      }).catch(() => {
        return {
          success: false,
          code: -1
        }
      })
    },
    refreshAppInstances: async ({state, dispatch, commit}) => {
      const body = {
        query: 'query {\n' +
        '  appInstances {\n' +
        '   uuid \n' +
        '   organization {\n' +
        '     uuid \n' +
        '     label \n' +
        '   }\n' +
        '   version \n' +
        '   settings \n' +
        '   created \n' +
        '   changed \n' +
        '   app { \n' +
        '     uuid\n' +
        '   }\n' +
        '  }\n' +
        '}\n'
      }

      return axios.post(state.graphUrl, body).then(response => {
        commit('updateAppInstances', result.data.data.appInstances)
        return response.data
      }).catch(e => {
        // do nothing
      })
    },
    getInitialData: async ({ state, dispatch, commit }) => {
			try {
				if(state.selectedOrganization !== null && state.selectedOrganization !== undefined && db.getGlobalDB()['initialData'] !== undefined) {
					const initialData = await db.getGlobalDB()['initialData'].where({uuid: state.selectedOrganization}).toArray()

					if(Array.isArray(initialData) && initialData.length > 0) {
						const intialDataCachedJSON = initialData[0].data

						if(intialDataCachedJSON !== null && intialDataCachedJSON !== undefined) {
              dispatch('processInitialData', intialDataCachedJSON)
						}
					}
				}
			}
			catch(e) {

			}

      const body = {
        query: 'query {\n' +
					'  canEnableSalesCloudPay\n' +
					'  isPendingSalesCloudPayOnboardingCompletion\n' +
          '  whoAmI {\n' +
          '    uid\n' +
          '    email\n' +
          '    displayName\n' +
          '    language\n' +
          '    accessibleOrganizations {\n' +
          '      uuid\n' +
          '      label\n' +
          '    }\n' +
          '    organization {\n' +
          '      uuid\n' +
          '      label\n' +
          '      email\n' +
          '      phone\n' +
          '      primary_color\n' +
          '      accent_color\n' +
          '      terms {\n' +
          '        version\n' +
          '        confirmedDate\n' +
          '        confirmedBy\n' +
          '      }\n' +
          '      logo {\n' +
          '        file {\n' +
          '         sources {\n' +
          '           large\n' +
          '         }\n' +
          '        }\n' +
          '      }\n' +
          '      settings{\n' +
          '       strings{\n'+
          '         key\n' +
          '         value\n' +
          '         secure\n' +
          '         }\n' +
          '       booleans{\n' +
          '         key\n' +
          '         value\n' +
          '         secure\n' +
          '         }\n' +
          '      }\n' +
          '      credits {\n' +
          '        amount\n' +
          '        currency_code\n' +
          '      }\n' +
          '      formsOfPayment {\n' +
          '        uuid\n' +
          '      }\n' +
          '      defaultCurrency {\n' +
          '        code\n' +
          '        decimals\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '  dashCollections {\n' +
          '    uuid\n' +
          '    name\n' +
          '    period\n' +
          '    channel\n' +
          '    channels\n' +
          '    timeIntervalFrom\n' +
          '    timeIntervalTo\n' +
          '    startTimeInMillis\n' +
          '    endTimeInMillis\n' +
          '    dashes {\n' +
          '      uuid\n' +
          '      title\n' +
          '      description\n' +
          '      namespace\n' +
          '      cols\n' +
          '      content\n' +
					'      renderType\n' +
          '      disclaimer\n' +  
          '      weight\n' +
          '      data\n' +
          '      uiFields {\n' +
          '       key\n' +
          '       label\n' +
          '       description\n' +
          '       type\n' +
          '       multiple\n' +
          '       options {\n' +
          '        key\n' +
          '        label\n' +
          '       }\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '  dashTypes {\n' +
          '    namespace\n' +
          '    title\n' +
          '    description\n' +
          '    supportsLive\n' +
          '    configFields {\n' +
          '      key\n' +
          '      label\n' +
          '      description\n' +
          '      type\n' +
          '      multiple\n' +
          '      options {\n' +
          '        key\n' +
          '        label\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '  channelsList {\n' +
          '    uuid\n' +
          '    label\n' +
          '    description\n' +
          '    active\n' +
          '    acceptedPaymentMethodInstances {\n' +
          '       uuid\n' +
          '       title\n' +
          '       namespace\n' +
          '    }\n' +
					'		deliverSMSToCustomerOnOrderStateChanges\n' +
					'		deliverSMSToCustomerOnOrderConfirmation\n' +
					'		notifyCustomerOnStateChanges{\n' +
					'			orderState\n' +
					'			smsContent\n' +
					'		}\n' +
          '  }\n' +
          '  routes {\n' +
          '    title\n' +
          '    path\n' +
          '    icon\n' +
          '    weight\n' +
          '    type\n' +
          '    routes {\n' +
          '      title\n' +
          '      path\n' +
          '      icon\n' +
          '      weight\n' +
          '      type\n' +
          '    }\n' +
          '  }\n' +
          '  itemTypes {\n' +
          '    namespace\n' +
          '    title\n' +
          '    description\n' +
          '    short_description\n' +
          '    icon\n' +
          '    uiFields {\n' +
          '      key\n' +
          '      type\n' +
          '      label\n' +
          '      description\n' +
          '      required\n' +
          '      value\n' +
          '      placeholder\n' +
          '      hint\n' +
          '      options {\n' +
          '        key\n' +
          '        label\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '  team {\n' +
          '    role_id\n' +
          '    user {\n' +
          '      uid\n' +
          '      email\n' +
          '      displayName\n' +
          '      phoneNumber\n' +
          '      active\n' +
          '    }\n' +
          '  }\n' +
          '  categories(type: "products") {\n' +
          '    name\n' +
          '    uuid\n' +
          '    description\n' +
          '    weight\n' +
          '    required\n' +
          '    single\n' +
          '    administrative\n' +
          '    ept\n' +
          '    legacyId\n' +
          '    primary_color\n' +
          '    allowSplitByHalf\n' +
          '    accountingKey\n' +
          '    parent {\n' +
          '      uuid\n' +
          '      name\n' +
          '    }\n' +
          '    opening_hours {\n' +
          '      day\n' +
          '      starthours\n' +
          '      endhours\n' +
          '      comment\n' +
          '    }\n' +
          '    image {\n' +
          '      title\n' +
          '      alt\n' +
          '      file {\n' +
          '        sources {\n' +
          '          original\n' +
          '          thumbnail\n'+
          '        }\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '  paymentMethods {\n' +
          '    namespace\n' +
          '    supportedCallbacks\n' +
          '    title\n' +
          '  }\n' +
          '  paymentMethodInstances(terminal: true, checkout: true) {\n' +
          '    uuid\n' +
          '    namespace\n' +
          '    title\n' +
          '    icon\n' +
          '    description\n' +
          '    checkout\n' +
          '    terminal\n' +
          '    status\n' +
          '    legacyId\n ' +
          '  }\n' +
          '  platformPaymentMethodInstances: paymentMethodInstanceList(organization: "f95bbe80-b9c2-40eb-943d-95d696c1acfb", checkout: true) {\n' +
          '    uuid\n' +
          '    namespace\n' +
          '    title\n' +
          '    icon\n' +
          '    description\n' +
          '    uiFields {\n' +
          '      label\n' +
          '      key\n' +
          '      type\n' +
          '      value\n' +
          '      cols\n' +
          '      required\n' +
          '      options {\n' +
          '        key\n' +
          '        label\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '  locations {\n' +
          '    uuid\n' +
          '    label\n' +
          '    description\n' +
          '    active\n' +
					'    timezone\n' +
          '    address {\n' +
          '      name_line\n' +
          '      country\n' +
          '      thoroughfare\n' +
          '      locality\n' +
          '      postal_code\n' +
          '    }\n' +
          '    seasonalHours{\n' +
          '       endTimeInMillis\n' +
          '       endhours\n' +
          '       startTimeInMillis\n' +
          '       starthours\n' +
          '    }\n' +
          '    closedDates \n' +
          '    gps {\n' +
          '      geom\n' +
          '      geo_type\n' +
          '      lat\n' +
          '      lon\n' +
          '      left\n' +
          '      top\n' +
          '      right\n' +
          '      bottom\n' +
          '      geohash\n' +
          '    }\n' +
          '    opening_hours {\n' +
          '      day\n' +
          '      starthours\n' +
          '      endhours\n' +
          '      maximumSalesPerSlot\n' +
          '      intervalBetweenSlotsInMillis\n' +
          '      pauseBetweenSlotsInMillis\n' +
          '      comment\n' +
          '    }\n' +
          '    bookableHours {\n' +
          '      day\n' +
          '      starthours\n' +
          '      endhours\n' +
          '      maximumSalesPerSlot\n' +
          '      intervalBetweenSlotsInMillis\n' +
          '      pauseBetweenSlotsInMillis\n' +
          '      comment\n' +
          '    }\n' +
          '    opening_rule\n' +
          '    ept\n' +
          '    excludedLocationVariationsFromBookings {\n' +
          '      uuid\n' +
          '    }\n' +
          '    excludedZonesFromBookings {\n' +
          '      uuid\n' +
          '    }\n' +
          '    excludedZonesFromOnlineBookings {\n' +
          '      uuid\n' +
          '    }\n' +
          '    maximumSalesPerSlot\n' +
          '    intervalBetweenSlotsInMillis\n' +
          '    pauseBetweenSlotsInMillis\n' +
          '    variations {\n' +
          '      label\n' +
          '      status\n' +
          '      uuid\n' +
          '      x\n' +
          '      y\n' +
          '      shape\n' +
          '      standardPersons\n' +
          '      minimumPersons\n' +
          '      maximumPersons\n' +
          '      sorting_weight\n' +
          '      zone {\n' +
          '        uuid\n' +
          '        name\n' +
          '      }\n' +
          '    }\n' +
          '    preferredChannel {\n' +
          '     label\n' +
          '     uuid\n' +
          '     description\n' +
          '     active\n' +
          '    }\n' +
          '  }\n' +
          '  zones: categories(type: "zones") {\n' +
          '    name\n' +
          '    uuid\n' +
          '  }\n' +
          '  countries {\n' +
          '    name\n' +
          '    code\n' +
          '  }\n' +
          '  currencies {\n' +
          '    name\n' +
          '    code\n' +
          // '    decimals\n' +
          '  }\n' +
          '  platforms {\n' +
          '    name\n' +
          '    icon\n' +
          '    path\n' +
          '  }\n' +
          '  emailTemplates {\n' +
          '    name\n' +
          '    mjml\n' +
          '  }\n' +
          '  roles {\n' +
          '    role_id\n' +
          '    name\n' +
          '  }\n' +
          '  defaultCurrency {\n' +
          '    code\n' +
          '    name\n' +
          '  }\n' +
          '  defaultCountry {\n' +
          '    name\n' +
          '    code\n' +
          '  }\n' +
          '  taxes {\n' +
          '    uuid\n' +
          '    title\n' +
          '    description\n' +
          '    namespace\n' +
          '    rate\n' +
          '    priceComponent\n' +
          '    displayTitle\n' +
          '  }\n' +
          '  variations {\n' +
          '    uuid\n' +
          '    label\n' +
          '    owner_id\n' +
          '    description\n' +
          '    type\n' +
          '    status\n' +
          '    sorting_weight\n' +
          '    price {\n' +
          '      amount\n' +
          '    }\n' +
          '    attribute{\n' +
          '      name\n' +
          '    }\n' +
          '  }\n' +
          '  attributes {\n' +
          '    uuid\n' +
          '    name\n' +
          '    description\n' +
          '    weight\n' +
          '  }\n' +
          '  bulkOperations {\n' +
          '    namespace\n' +
          '    title\n' +
          '    description\n' +
          '    routes\n' +
          '    whiteListed' +
          '    uiFields {\n' +
          '      key\n' +
          '      type\n' +
          '      label\n' +
          '      description\n' +
          '      required\n' +
          '      value\n' +
          '      cols\n' +
          '      placeholder\n' +
          '      hint\n' +
          '      options {\n' +
          '        key\n' +
          '        label\n' +
          '      }\n' +
          '      group {\n' +
          '        key\n' +
          '        label\n' +
          '        multiple\n' +
          '      }\n' +
          '      multiple\n' +
          '      minLength\n' +
          '      maxLength\n' +
          '      weight\n' +
          '    }\n' +
          '  }\n' +
          '  callToActions {\n' +
          '    namespace\n' +
          '    title\n' +
          '    description\n' +
          '    routes\n' +
          '    uiFields {\n' +
          '      key\n' +
          '      type\n' +
          '      label\n' +
          '      description\n' +
          '      required\n' +
          '      value\n' +
          '      cols\n' +
          '      placeholder\n' +
          '      hint\n' +
          '      options {\n' +
          '        key\n' +
          '        label\n' +
          '      }\n' +
          '      group {\n' +
          '        key\n' +
          '        label\n' +
          '        multiple\n' +
          '      }\n' +
          '      multiple\n' +
          '      minLength\n' +
          '      maxLength\n' +
          '      weight\n' +
          '    }\n' +
          '  }\n' +
          '  workTypes\n' +
          '  supportedLanguages{\n' +
          '   name\n' +
          '   key\n' +
          '  }\n' +
          '  appInstances {\n' +
          '   uuid \n' +
          '   organization {\n' +
          '     uuid \n' +
          '     label \n' +
          '   }\n' +
          '   version \n' +
          '   settings \n' +
          '   created \n' +
          '   changed \n' +
          '   app { \n' +
          '     uuid\n' +
          '   }\n' +
          '  }\n' +
          '  printers {\n' +
          '   uuid \n' +
          '   title \n' +
          '   model \n' +
          '   ipAddress \n' +
          '  }\n' +
          '}\n'
      }

      axios.defaults.headers.common['lo'] = state.lo
      axios.defaults.headers.common['ls'] = state.ls

      return axios.post(state.graphUrl, body).then(async result => {

				try {
					if(state.selectedOrganization !== null && state.selectedOrganization !== undefined) {
						const entry = {data: JSON.stringify(result.data.data), uuid: state.selectedOrganization}
						await db.getGlobalDB()['initialData'].put(entry).catch(error => {
							console.error(error)
						})
					}
				}
				catch(e) {
					// Do nothing
				}

				return dispatch('processInitialData', result.data.data)
      }).catch(() => {
				return null
			})
    },
		processInitialData: ({state, commit, dispatch}, initialData) => {
			commit('setCanEnableSalesCloudPay', initialData.canEnableSalesCloudPay)
			commit('setIsPendingSalesCloudPayOnboardingCompletion', initialData.isPendingSalesCloudPayOnboardingCompletion)
			commit('updatePlatformPaymentMethodInstances', initialData.platformPaymentMethodInstances)
			commit('updateUser', initialData.whoAmI)
      commit('updateRoutes', initialData.routes)
			commit('updateChannels', initialData.channelsList)
			commit('updateOrganization', initialData.whoAmI.organization)
			commit('updateOrganizationUUID', initialData.whoAmI.organization.uuid)
			commit('updatePlatforms', initialData.platforms)
			commit('updateBulkOperations', initialData.bulkOperations)
			commit('updateCallToActions', initialData.callToActions)
			commit('updateWorkTypes', initialData.workTypes)
			commit('updateItemTypes', initialData.itemTypes)
			commit('updatePaymentMethods', initialData.paymentMethods)
			commit('setUserLanguage', initialData.whoAmI.language)
			if(Array.isArray(initialData.supportedLanguages) && initialData.supportedLanguages.length > 0) {
				commit('updateLanguages', initialData.supportedLanguages)
			} else {
				commit('updateLanguages', [{name: 'English', key: 'en'}, {name: 'Íslenska', key: 'is'}, {name: 'Svenska', key: 'sv'}, {name: 'Português', key: 'pt'}, {name: 'Dansk', key: 'da'}])
			}
			let categories = initialData.categories
			if (Array.isArray(categories) && categories.length > 0) {
				categories = categories.filter(notNull => notNull).sort((a, b) => !!a && !!b && typeof a.weight !== 'undefined' && b.weight !== 'undefined' && a.weight - b.weight)
			}
			commit('updateCategories', categories)
			commit('updateEmailTemplates', initialData.emailTemplates)
			commit('updateLocations', initialData.locations)
      commit('updatePrinters', initialData.printers)
			commit('updateZones', initialData.zones)
			commit('updateTeam', initialData.team)
			commit('updateCountries', initialData.countries)
			commit('updateCurrencies', initialData.currencies)
			commit('initializeDashCollections', initialData.dashCollections)
			commit('updateRoles', initialData.roles)
			commit('updatePaymentMethodInstances', initialData.paymentMethodInstances)
			commit('updateDefaultCurrency', initialData.defaultCurrency)
			commit('updateDefaultCountry', initialData.defaultCountry)
			commit('updateOrganizationTaxRates', initialData.taxes)
			commit('setVariations', initialData.variations)
			commit('setAttributes', initialData.attributes)
      commit('updateAppInstances', initialData.appInstances)
			commit('updateSocketClient', createClient({
				url: state.socketUrl,
				connectionParams:  () => {
					return {
						headers: {
							'ls': state.ls,
							'lo': state.lo
						},
					}
				}
			}))
			dispatch('getItems')
			dispatch('getDashTypes')
      try {
        const showTerms = !initialData.whoAmI.organization.terms
        const userEmail = initialData.whoAmI.email
        const isSalesCloudUser = userEmail.includes("@salescloud")
        commit('setShowTermsAndConditions', showTerms && !isSalesCloudUser)
      } catch (error) {
        console.error("[TermsAndCondition] Error processing terms and conditions", error, initialData.whoAmI)
      }
      
      try {
        dispatch('initializeAmplitude', { userEmail: state.user.email })
      } catch (error) {
        console.error('[amplitude] error initializing amplitude', error)
      }

			commit('updateInitialDataProcessed', true)      
		},
    inviteToTeam: ({ state, commit }, payload) => {
      commit('setIsProcessingTeamInvite', true)

      let invitee = {
        displayName: payload.displayName,
        email: payload.email
      }

      invitee.phone = payload.phone

      if (payload.role_id) {
        invitee.role_id = payload.role_id.toString()
      }

      if (payload.password) {
        invitee.password = payload.password
      }

      const query = {
        query: 'mutation inviteUserToOrganization($invitee: InviteUserToOrganization) {\n' +
          '  inviteUserToOrganization(user: $invitee) {\n' +
          '     success\n' +
          '     text\n' +
          '  }\n' +
          '}'
        ,
        variables: {
          invitee: invitee
        }
      }

      return axios.post(state.graphUrl, query).then(result => {
        return result.data.data.inviteUserToOrganization
      }).finally(() => {
        commit('setIsProcessingTeamInvite', false)
      })
    },
    removeFromTeam: ({ state, dispatch }, payload) => {
      const user = {
        uid: payload.user.uid,
        organization: state.organization.uuid
      }

      const query = {
        query: 'mutation removeUserFromOrganization($user: RemoveUserFromOrganization) {\n' +
          '  removeUserFromOrganization(user: $user)\n' +
          '}'
        ,
        variables: {
          user: user
        }
      }

      return axios.post(state.graphUrl, query).then(result => {
        const removedUserFromTeam = result.data.data.removeUserFromOrganization
        if (removedUserFromTeam) {
          return dispatch('getTeam').then(() => {
            return removedUserFromTeam
          })
        }
      })

    },
		getDashTypes: ({ commit, state, dispatch }) => {

      const body = {
        query: 'query {\n' +
          '  dashTypes {\n' +
          '    namespace\n' +
          '    title\n' +
          '    description\n' +
          '    supportsLive\n' +
          '    configFields {\n' +
          '      key\n' +
          '      label\n' +
          '      description\n' +
          '      type\n' +
          '      multiple\n' +
          '      options {\n' +
          '        key\n' +
          '        label\n' +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '}\n'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if (result.data.data) {
          commit('updateDashTypes', result.data.data.dashTypes)
          return true
        }
      })
		},
    sendMessageToBookingOwners: ({ state }, payload) => {
      const query = {
        query: 'mutation notifyBookingOwner($subject: String!, $message: String!, $bookingUUIDS: [String!]!) {\n' +
          '  notifyBookingOwner(subject: $subject, message: $message, bookings: $bookingUUIDS)\n' +
          '}'
        ,
        variables: {
          subject: payload.subject,
          message: payload.message,
          bookingUUIDS: payload.uuids
        }
      }

      return axios.post(state.graphUrl, query).then(result => {
        if (result.data.data) {
          //REACT IF MUTATION WAS SUCCESSFUL
        }
      })
    },
    executeBulkOperation: ({ state }, { action, route, uiValues, payload }) => {
      const query = {
        query: 'mutation($namespace: String!, $route: String!, $uiValues: JSON, $payload: JSON) {\n' +
          '  executeBulkOperation(namespace: $namespace, route: $route, uiValues: $uiValues, payload: $payload) {\n' +
          '    namespace\n' +
          '    success\n' +
          '    message\n' +
          '    data\n' +
          '  }\n' +
          '}'
        ,
        variables: {
          "namespace": action.namespace,
          "route": route,
          "uiValues": uiValues,
          "payload": payload
        }
      }

      return axios.post(state.graphUrl, query).then(result => {
        return result.data.data.executeBulkOperation
      })
    },
    executeCallToAction: ({ state }, { action, route, uiValues, payload }) => {
      const query = {
        query: 'mutation($namespace: String!, $route: String!, $uiValues: JSON, $payload: JSON) {\n' +
          '  executeCallToAction(namespace: $namespace, route: $route, uiValues: $uiValues, payload: $payload) {\n' +
          '    namespace\n' +
          '    success\n' +
          '    message\n' +
          '    data\n' +
          '  }\n' +
          '}'
        ,
        variables: {
          "namespace": action.namespace,
          "route": route,
          "uiValues": uiValues,
          "payload": payload
        }
      }

      return axios.post(state.graphUrl, query).then(result => {
        return result.data.data.executeCallToAction
      })
    },
    getYessOrganizationPaymentMethodInstanceList: ({ state, commit }, { organizationUUID, terminal, checkout }) => {
      if(typeof organizationUUID === 'undefined' || organizationUUID === null || organizationUUID === '') {
        return []
      }

      commit('gettingPaymentMethodInstanceList', true)

      let showTerminal = ''
      let showCheckout = ''

      if(terminal) {
        showTerminal = ', terminal: ' + terminal
      }

      if(checkout) {
        showCheckout = ', checkout: ' + checkout
      }

      const query = {
        query: 'query {\n' +
          ' paymentMethodInstanceList(organization: "' + organizationUUID + '"' + showTerminal + showCheckout + ') {\n'+
          '   uuid\n' +
          '   namespace\n' +
          '   title\n' +
          '   description\n' +
          ' }\n' +
          '}'
      }

      return axios.post(state.graphUrl, query).then(result => {
        const paymentMethodInstanceListResponse = result.data.data.paymentMethodInstanceList
        if(paymentMethodInstanceListResponse) {
          return paymentMethodInstanceListResponse
        } else {
          return []
        }
      }).finally(() => {
        commit('gettingPaymentMethodInstanceList', false)
      })
    },
    listAvailableLocationVariations: ({ state }, payload) => {
      const query = {
        query: 'query($location: String!, $startTimeInMillis: Float!, $endTimeInMillis: Float!) {\n' +
          '  availableLocationVariations(location: $location, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis) {\n'+
          '   uuid\n' +
          '   label\n' +
          '   status\n' +
          '   zone {\n' +
          '     uuid\n' +
          '     name\n' +
          '   }\n' +
          ' }\n' +
          '}'
        ,
        variables: {
          location: payload.location,
          startTimeInMillis: payload.startTimeInMillis,
          endTimeInMillis: payload.endTimeInMillis
        }
      }

      return axios.post(state.graphUrl, query).then(result => {
        if(result.data) {
          return result.data.data.availableLocationVariations.filter(locationVariation => locationVariation.zone.uuid !== '8def7e20-ce3f-4da2-97b3-3deebcded6f2')
        }
      })
    },
    getBookingsPerDayForSelectedMonth({ state, getters }, { selectedDate, locationUUID }) {
      const date = selectedDate instanceof Date ? selectedDate : new Date(selectedDate)

      const firstDayOfMonth = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0, 0)
      const lastDayOfMonth = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0, 23, 59, 59, 999)

      const startTimeInMillis = firstDayOfMonth.getTime()
      const endTimeInMillis = lastDayOfMonth.getTime()

      const query = {
        query: 'query {\n' +
        '   bookingsCountPerDay(organization: "", item: "", startTimeInMillis: ' + startTimeInMillis + ', endTimeInMillis: ' + endTimeInMillis + ', location: "' + locationUUID + '") {\n' +
        '     date\n' +
        '     bookingsCount\n' +
        '   }\n' +
        '}'
      }

      return axios.post(state.graphUrl, query).then(result => {
        if(result && result.data && result.data.data && result.data.data.bookingsCountPerDay) {
          return result.data.data.bookingsCountPerDay
        }
        return []
      })
    },
    getAppGroups: ({state, commit}) => {
      const body = {
        query: 'query {\n' +
        ' appGroups {\n' +
        '   key \n' +
        '   label \n' +
        ' }\n' +
        '}\n'
      }

      return axios.post(state.graphUrl, body).then(response => {
        commit('setAppGroups', response.data.data.appGroups)
      })
    },
    getAppProfileTypes: ({ state, commit }) => {
      const body = {
        query: 'query {\n' +
            '   __type(name: "AppProfileType") {\n' +
            '     enumValues {\n' +
            '       name\n' +
            '     }\n' +
            '   }\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(result => {
        if(result && result.data && result.data.data) {
          if(result.data.data['__type'] && Array.isArray(result.data.data['__type'].enumValues)) {
            const types = result.data.data['__type'].enumValues.map(value => value && value.name).filter(noNull => noNull)
            commit('updateAppProfileTypes', types)
            return types
          }
        }
      })
    },
    getOrderFulfillmentOptions: ({ state }) => {
      const body = {
        query: 'query {\n' +
            ' orderFulfillmentOptions {\n' +
            '   key\n' +
            '   label\n' +
            ' }\n' +
            '}'
      }

      return axios.post(state.graphUrl, body).then(response => {
        if(response && response.data && response.data.data && response.data.data.orderFulfillmentOptions) {
          state.orderFulfillmentOptions = response.data.data.orderFulfillmentOptions
          return response.data.data.orderFulfillmentOptions
        }
        return []
      }).catch(() => {
        return []
      })
    },
    acceptTermsAndConditions: ({ state, commit }, {termsChecked, authorityChecked}) => {
      const body = {
        query: 'mutation($version: String, $termsApproved: Boolean, $authorityApproved: Boolean) {\n' +
          '  confirmTermsAndConditions(version: $version, termsApproved: $termsApproved, authorityApproved: $authorityApproved) {\n' +
          '    success\n' +
          '    message\n' +
          '  }\n' +
          '}',
        variables: {
          "version": "1.0",
          "termsApproved": termsChecked,
          "authorityApproved": authorityChecked,
        }
      }

      return axios.post(state.graphUrl, body).then(result => {
        commit('setShowTermsAndConditions', !result.data.data.confirmTermsAndConditions.success)
        return result.data.data.confirmTermsAndConditions        
      }).catch((err) => {
        console.error("[TermsAndCondition] Error confirming terms and conditions", err)
      })
    }
  },
  getters: {
    validateAndGetOpeningHourAsTimestampInMillis: (getters) => (openingHour, dateTimestampInMillis, timezone) => {
      if(typeof openingHour === 'undefined' || openingHour === null) {
        return 0
      }

      const formattedOpeningHour = getters.validateAndGetFormattedOpeningHour(openingHour)
      const hours = Number(formattedOpeningHour.substring(0, 2))
      const minutes = Number(formattedOpeningHour.substring(2, 4))

      let timestamp = new Date().getTime()
      if(typeof dateTimestampInMillis !== 'undefined' && dateTimestampInMillis !== null) {
        timestamp = dateTimestampInMillis
      }

      let tz = 'UTC'
      if(typeof timezone !== 'undefined' && timezone !== null) {
        tz = timezone
      }

      const timezoneOpeningHour = moment(timestamp).tz(tz).hours(hours).minutes(minutes).seconds(0).milliseconds(0)

      return timezoneOpeningHour.utc()
    },
    validateAndGetFormattedOpeningHour: () => (openingHour) => {
      if(typeof openingHour === 'undefined' || openingHour === null || typeof openingHour !== 'string') {
        return ''
      }
      let hourMinute = openingHour.replace(/:/g, '')
      hourMinute = hourMinute.padStart(2, '0').padEnd(4, '0')
      // Validate the hour
      if(isNaN(Number(hourMinute.substring(0, 2)))) {
        return ''
      }
      // Validate the minute
      if(isNaN(Number(hourMinute.substring(2, 4)))) {
        return ''
      }
      return hourMinute
    },
    /**
     * @description A getter function that will either return 0 or a 13 digit epoch timestamp
     * @return {number}
     */
    validateAndGetEpochTimestampInMillis: () => (providedTimestamp) => {
      // If no value is provided we return a zero
      if(typeof providedTimestamp === 'undefined' || providedTimestamp === null) {
        return 0
      }

      // Check how many zeros we are missing
      const difference = EPOCH_TIMESTAMP_IN_MILLIS_LENGTH - providedTimestamp.toString().replace(/\./g, '').length
      // Multiply the provided timestamp with the missing zeros
      return providedTimestamp * Number('1e' + difference)
      // NOTE: Another way to do this would be to do something like Number(providedTimestamp.toString().replace(/\./g, '').padEnd(13, '0'))
    },
    isShowAppBar: (state) => (isMobile) =>{
      if(state.user === null) {
        return false
      }
      if(state.showAppBar === true) {
        return true
      }
      if(state.inApp === true) {
        return false
      }
      return isMobile
    },
    applyFilters: () => ({ $worker, filters, listOfObjects, operator = 'AND' }) => {
      return $worker.run((arg1, arg2, arg3) => {

        const get = (p, o) =>
          p.reduce((xs, x) => {
            if(Array.isArray(xs)) {
              return xs.reduce((allValues, value) => {
                if(value !== undefined && value !== null && value[x] !== undefined) {
                  allValues.push(value[x])
                }
                return allValues
              }, [])
            }
            return (xs !== null && xs !== undefined && xs[x] !== undefined) ? xs[x] : null
          }, o)

        return arg2.filter(object => {

          const showObject = []

          for (let i in arg1) {
            if(typeof i !== 'undefined' && i !== '') {
              const filter = arg1[i]

              if(filter.value === null) {
                continue
              }

              const objectPath = filter.key
              const objectPathValue = get(objectPath.split('.'), object)

              switch (filter.type) {
                case 'datetime':
                  if(filter.operator === 'between' && objectPathValue !== null) {
                    if(objectPathValue > filter.value[0] && objectPathValue < filter.value[1]){
                      showObject.push(true)
                      break
                    }
                  }
                  showObject.push(false)
                  break
                case 'date':
                  filter.value = Number (filter.value)
                  let objectDateValue = new Date(objectPathValue)
                  objectDateValue.setSeconds(0)
                  objectDateValue.setMinutes(0)
                  objectDateValue.setHours(0)
                  objectDateValue =  Date.parse(objectDateValue)

                  if(filter.operator ==='equals' && objectPathValue !== null) {
                    if(objectDateValue === filter.value){
                      showObject.push(true)
                      break
                    }
                  }

                  if(filter.operator ==='before' && objectPathValue !== null) {
                    if(objectDateValue < filter.value && objectPathValue !== 0){
                      showObject.push(true)
                      break
                    }
                  }

                  if(filter.operator ==='after' && objectPathValue !== null) {
                    if(objectDateValue > filter.value || objectPathValue === 0){
                      showObject.push(true)
                      break
                    }
                  }

                  showObject.push(false)
                  break
                case 'number':
                  filter.value = Number (filter.value)

                  if(filter.operator ==='equals' && objectPathValue !== null) {

                    if(objectPathValue === filter.value){
                      showObject.push(true)
                      break
                    }
                    else if (filter.dynamicValue && (object[filter.dynamicValue] === objectPathValue)) {
                      showObject.push(true)
                      break
                    }

                  }

                  if(filter.operator ==='lessThan' && objectPathValue !== null) {
                    if(objectPathValue < filter.value){
                      showObject.push(true)
                      break
                    }
                  }

                  if(filter.operator ==='greaterThan' && objectPathValue !== null) {
                    if(objectPathValue > filter.value){
                      showObject.push(true)
                      break
                    }
                  }

                  showObject.push(false)
                  break
                case 'text':

                  if (filter.operator === 'contains' && objectPathValue !== null) {
                    if (objectPathValue.toLowerCase().includes(filter.value.toLowerCase())) {
                      showObject.push(true)
                      break
                    }
                  }
                  else if (filter.operator === 'equals' && objectPathValue !== null) {
                    if (objectPathValue == filter.value) {
                      showObject.push(true)
                      break
                    }
                  }

                  showObject.push(false)
                  break
                case 'boolean':
                    if (filter.operator === 'equals' && objectPathValue !== null) {
                      if (objectPathValue === filter.value) {
                        showObject.push(true)
                        break
                      }
                    }
                    else if(filter.operator === 'not_equals') {
                      if(objectPathValue !== filter.value) {
                        showObject.push(true)
                        break
                      }
                    }
                    else if(filter.operator === 'contains' && objectPathValue !== null) {
                      if(Array.isArray(filter.value)) {
                        if(Array.isArray(objectPathValue)) {
                          let isIncluded = (filter.value.length === 0)
                          for(let j = 0; j < objectPathValue.length; j++) {
                            if(filter.value.includes(objectPathValue[j]) && isIncluded === false) {
                              isIncluded = true
                            }
                          }
                          showObject.push(isIncluded)
                          break
                        }
                        showObject.push(filter.value.includes(objectPathValue))
                        break
                      }
                      if (objectPathValue.includes(filter.value)) {
                        showObject.push(true)
                        break
                      }
                    }
                    showObject.push(false)
                    break
                case 'select':
                  if (filter.operator === 'contains' && objectPathValue !== null) {
                    if(Array.isArray(filter.value)) {
                      if(Array.isArray(objectPathValue)) {
                        let isIncluded = (filter.value.length === 0)
                        for(let j = 0; j < objectPathValue.length; j++) {
                          if(filter.value.includes(objectPathValue[j]) && isIncluded === false) {
                            isIncluded = true
                          }
                        }
                        showObject.push(isIncluded)
                        break
                      }
                      showObject.push(filter.value.includes(objectPathValue.toLowerCase()))
                      break
                    }
                    if (objectPathValue.toLowerCase().includes(filter.value)) {
                      showObject.push(true)
                      break
                    }
                  }
                  if (filter.operator === 'equals') {
                    let isIncluded = false
                    for (const item in filter.value) {
                      if (objectPathValue === filter.value[item]) {
                        isIncluded = true
                      }
                    }
                    showObject.push(isIncluded)
                    break
                  }
                  showObject.push(false)
                  break
                default:
                  break
              }
            }
          }

          let operation = arg3
          // Setting default operation if operator is not defined
          if(typeof operation === 'undefined' || operation === '') {
            operation = 'and'
          }

          // Handling OR operator, else we default to AND operator
          if(operation.toLowerCase() === 'or') {
            return showObject.some(value => value === true)
          }
          return showObject.every(value => value === true)
        })
      }, [filters, listOfObjects, operator]).then(results => {
        return results
      }).catch(() => {
        return null
      })
    },
    systemTime: (state) => {
      return state.systemTime
    },
    categories: (state) => {
      return state.categories.sort((a,b) => a.weight - b.weight)
    },
    paymentServiceRequestForSinglePlatformItem: (state) => (item, namespace, amount, currencyCode, details) => {

      const request = {
        billingInfo: {
          name: state.organization.label,
          email: state.organization.email,
          phone: state.organization.phone
        },
        items: [
          item
        ],
        namespace,
        amount,
        currency_code: currencyCode,
        details
      }

      return request
    },
    weekdays: () => {
      return [
        {
          text: i18n.t('sunday'),
          value: 0
        },
        {
          text: i18n.t('monday'),
          value: 1
        },
        {
          text: i18n.t('tuesday'),
          value: 2
        },
        {
          text: i18n.t('wednesday'),
          value: 3
        },
        {
          text: i18n.t('thursday'),
          value: 4
        },
        {
          text: i18n.t('friday'),
          value: 5
        },
        {
          text: i18n.t('saturday'),
          value: 6
        }
      ]
    },
    appBarSearchChips: (state) => {

      const chips = []

      for (const i in state.searchQuery) {
        if(typeof state.searchQuery[i] !== 'undefined' && state.searchQuery[i] !== null) {
          chips.push(state.searchQuery[i])
        }
      }

      return chips
    },
    currencyRuleTypes: () => {
      return [
        {
          text: i18n.t('default'),
          example: '1000',
          value: null
        },
        {
          text: i18n.t('space'),
          example: '1 000',
          value: 'space'
        },
        {
          text: i18n.t('dot'),
          example: '1.000',
          value: 'dot'
        },
        {
          text: i18n.t('comma'),
          example: '1,000',
          value: 'comma'
        }
      ]
    },
    getTimeFromTimeString: () => (timeString, withSeconds = false) => {
      const ts = (timeString.toString().length === 10) ? timeString * 1000 : timeString

      const time = new Date(ts).toTimeString()

      if (withSeconds) {
        return time.substr(0, 8)
      }
      return time.substr(0, 5)
    },
    getDateFromTimeString: () => (timeString, simpleDate = true) => {
      const ts = (timeString.toString().length === 10) ? timeString * 1000 : timeString

      const time = new Date(ts)

      if (simpleDate) {
        return time.toLocaleTimeString()
      }
      return time.toDateString()
    },
    getDateAndTimeFromTimeString: () => (timeString) => {
      const ts = (timeString.toString().length === 10) ? timeString * 1000 : timeString

      const d = new Date(ts).toISOString()
      return `${ d.slice(0, 10) } ${ d.slice(11, 16) }`
    },
    getISODate: () => (timeString) => {
      const ts = (timeString.toString().length === 10) ? timeString * 1000 : timeString

      const date = new Date(ts).toISOString()
      return date.slice(0, 10)
    },
    getISOTime: () => (timeString, withSeconds = false) => {
      const ts = (timeString.toString().length === 10) ? timeString * 1000 : timeString

      const time = new Date(ts).toISOString()
      if (withSeconds) {
        return time.slice(11, 19)
      }
      return time.slice(11, 16)
    },
    getEventsFromBookings: (state) => {
      // The idea here was (and is) to create an event for a calendar per customer uuid so if customer has more than one booking
      // it will show as one continuous event (ATTN: might have unintended consequences when cancelling or changing bookings, untested)
      const activeBookings = state.bookings.filter(booking => booking.status && (booking.status === true || booking.status === '1'))
      const events = {}

      for (const i in activeBookings) {
        if(typeof activeBookings[i] !== 'undefined' && activeBookings[i] !== null) {
          const booking = JSON.parse(JSON.stringify(activeBookings[i]))
          if (booking.customer) {
            if (!events[booking.customer.uuid]) {
              events[booking.customer.uuid] = []
              events[booking.customer.uuid].push(booking)
            } else {
              const customerBookings = events[booking.customer.uuid]
              for (const j in customerBookings) {
                if (customerBookings[j].startsAtTime !== booking.startsAtTime && customerBookings[j].endsAtTime !== booking.endsAtTime) {
                  customerBookings.push(booking)
                } else if (customerBookings[j].startsAtTime === booking.endsAtTime) {
                  customerBookings[j].startsAtTime = booking.startsAtTime
                } else if (customerBookings[j].endsAtTime === booking.startsAtTime) {
                  customerBookings[j].endsAtTime = booking.endsAtTime
                }
              }
            }
          } else {
            if (!Array.isArray(events.null)) {
              events.null = []
            }
            events.null.push(booking)
          }
        }
      }
      return events
    },
    hexToLuma: () => (colour) => {
      const hex = colour.replace(/#/, '')
      const r = parseInt(hex.substr(0, 2), 16)
      const g = parseInt(hex.substr(2, 2), 16)
      const b = parseInt(hex.substr(4, 2), 16)

      return [
        0.299 * r,
        0.587 * g,
        0.114 * b
      ].reduce((a, c) => a + c) / 255
    },
    colorIsLight: (state, getters) => (colour) => {
      return ((1 - getters.hexToLuma(colour)) < 0.45)
    },
    textButtonColor: (state, getters) => (color) => {
      if (getters.colorIsLight(color)) {
        return 'black--text'
      }
      return 'white--text'
    },
    userIsAdmin: (state) => {
      if(state.user !== null && typeof state.user !== 'undefined') {
        if(state.user.email !== null && typeof state.user.email !== 'undefined') {
          return state.user.email.includes('@salescloud.is')
        }
      }
      return false
    },
		userIsLoggedIn: (state) => {
      if(state.user !== null && typeof state.user !== 'undefined') {
       return true
      }
      return false
    },
    getSum: () => (numbersArray) => {
      if (!numbersArray) {
        return 0
      }

      let totals = 0
      numbersArray.forEach(n => {
        if(typeof n === 'number') {
          totals += n
        }
      })
      const fractionDigits = 2
      return totals.toFixed(fractionDigits)
    },
    rules: () => {
      const phoneRegex = /^[0-9]+$/
      const intlPhoneRegex = /^\+[1-9]{1}[0-9]{3,14}$/
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const fullNameRegex = /^(\p{L}+(\s\p{L}+)+)$/iu
      const whiteSpacesOnly = /^\s*$/
      const trailingWhiteSpace = /[ \t]+$/
      return {
        required: v => Boolean(v) ||  i18n.t('thisFieldIsRequired'),
        email: v => emailRegex.test(v) || i18n.t('youMustInputAValidEmailAddress'),
        phone: v => phoneRegex.test(v) || i18n.t('aPhoneNumberCanOnlyIncludeNumbers'),
        intlPhone: v => intlPhoneRegex.test(v) || i18n.t('phoneNumberMustBeValidAndIncludeCountryCode'),
        fullName: v => fullNameRegex.test(v) || i18n.t('youMustProvideAFullName'),
        whiteSpaceString: v => !whiteSpacesOnly.test(v) || i18n.t('inputCanNotBeOnlyWhiteSpace'),
        trailingWhiteSpace: v => !trailingWhiteSpace.test(v) || i18n.t('inputCanNotIncludeTrailingWhiteSpace'),
        salescloudEmail: v => !v.includes('@salescloud.is') || i18n.t('emailCanNotBeASalesCloudDomainEmail'),
        selectItemRequired: v => !!v || i18n.t('itemRequired'),
        selectItemRequiredOnMultiple: v => v.length > 0 || i18n.t('itemRequired'),
        percentageValue: v => (v >= 0 && v <= 100) || i18n.t('inputMustBeBetween0And100'),
      }
    },
    json2table: () => (data, classes) => {
      const json = jsonToTable(data)

      const cols = json[0]

      let headerRow = ''
      let bodyRows = ''

      const tableClasses = classes || ''

      for (const i in cols) {
        if(typeof cols[i] !== 'undefined' && cols[i] !== null) {
          headerRow += `<th>${ cols[i] }</th>`
        }
      }

      for (const i in json) {

        if (i > 0) {
          bodyRows += '<tr>'

          for (const n in json[i]) {
            if(typeof json[i][n] !== 'undefined' && json[i][n] !== null) {
              bodyRows += `<td>${ encodeURIComponent(json[i][n]) }</td>`
            }
          }

          bodyRows += '</tr>'
        }

      }

      return `<table class="${ tableClasses }"><thead>${ headerRow }</thead><tbody>${ bodyRows }</tbody></table>`
    },
    primaryColor: (state) => {

      if (state.organization !== null && state.organization.primary_color !== null && typeof state.organization.primary_color !== 'undefined' && state.organization.primary_color !== '') {
        return state.organization.primary_color
      }

      return '#2277dd'
    },
    accentColor: (state) => {

      if (state.organization !== null && state.organization.accent_color !== null && typeof state.organization.accent_color !== 'undefined' && state.organization.accent_color !== '') {
        return state.organization.accent_color
      }

      return '#e91e63 !important'
    },
    showAppBar: (state) => {
      return state.showAppBar
    },
    renderSidebar: (state, getters) => {

      if(state.user === null) {
        return false
      }

      if(state.inIframe && state.showAppBar) {
        return false
      }

      if(state.inApp) {
        return false
      }

      return getters.navigationItems.length > 0
    },
    navigationItems: (state) => {
      // We have to parse the routes and compute the hierarchy
      const navigationItems = []

      for (const i in state.routes) {

        if(typeof state.routes !== 'undefined' && state.routes !== null) {
          const route = state.routes[i]

          if (route.type !== 'nativeNormal') {
            continue
          }

          if(route.path === 'billing' || route.path === 'company' || route.path === 'team') {
            continue
          }

          if (route.routes.length > 0) {
            const subRoutes = []

            for (const n in route.routes) {

              if(typeof route.routes[n] !== 'undefined' && route.routes[n] !== null) {
                if (route.routes[n].type === 'nativeNormal') {
                  subRoutes.push(route.routes[n])
                }
              } else {
                delete route.routes[n]
              }
            }

            route.routes = subRoutes
          }

          navigationItems.push(route)
        }
      }

      return navigationItems
    },
    customers: (state) => (start, length) => {

      if (state.customers === null) {
        return null
      }

      return state.customers.slice(start, length)
    },
    getDeliveryMethod: (state) => (namespace) => {
      let deliveryMethod = null

      for (const i in state.deliveryMethods) {
        if (state.deliveryMethods[i].namespace === namespace) {
          deliveryMethod = state.deliveryMethods[i]
        }
      }

      return deliveryMethod
    },
    getStaffByUUID: (state) => (uuid) => {
      return state.staff.find(staff => staff.uuid === uuid)
    },
    getBookingByUUID: (state) => (uuid) => {
      return state.bookings.find(booking => booking.uuid === uuid)
    },
    getCustomerByUUID: (state) => (uuid) => {
      return state.customers.find(customer => customer.uuid === uuid)
    },
    getEventItems: (state) => {

      if(state.items === null || state.items === undefined) {
        return []
      }

      return state.items.filter(item => item.type === 'event').sort((i1, i2) => i1.title.localeCompare(i2.title))
    },
    getAppInstances: (state) => (appUUID) => {
      return state.appInstances.filter(instance => instance && instance.organization && instance.organization.uuid === state.organization.uuid && instance.app && instance.app.uuid  && instance.app.uuid === appUUID)
    },
    getEventItemIndex: (state, getters) => {
      const index = {}
      const eventItems = getters.getEventItems

      for(const i in eventItems) {
        if(typeof eventItems[i] !== 'undefined' && eventItems[i] !== null) {
          index[eventItems[i].uuid] = eventItems[i]
        }
      }

      return index
    },
    getActualBookings: (state, getters) => {

      if(state.bookings === null) {
        return state.bookings
      }

      let returnBookings = state.bookings

      if(typeof state.selectedBookingState !== 'undefined' && state.selectedBookingState !== null) {
        switch(state.selectedBookingState) {
          case 'active':
            returnBookings = returnBookings.filter(booking => booking && booking.status === '1')
            break
          case 'canceled':
            returnBookings = returnBookings.filter(booking => booking && booking.status === '0')
            break
          default:
            break
        }
      }

      const eventItemIndex = getters.getEventItemIndex

      if(getters.getEventItems.length > 0) {
        returnBookings = returnBookings.filter(booking => booking && booking.item && booking.item.uuid && typeof eventItemIndex[booking.item.uuid] !== 'undefined')
      }

      if(state.selectedLocation !== null && typeof state.selectedLocation !== 'undefined' && state.selectedLocation !== '') {
        returnBookings = returnBookings.filter(booking => booking && (typeof booking.location === 'undefined' || booking.location === null || booking.location.uuid === state.selectedLocation))
      }

      return returnBookings
    },
    customer: (state) => (uuid) => {

      if (state.customers === null) {
        return null
      }

      return state.customers.find(customer => customer.uuid === uuid)
    },
    bookings: (state) => (customer) => {

      if (state.bookings === null) {
        return null
      }

      return state.bookings.find(booking => {

        if (booking === null) {
          return null
        }

        if (customer && booking.customer && booking.customer.uuid && booking.customer.uuid !== customer) {
          return null
        }

        return booking
      })
    },
    items: (state) => (start, length) => {

      if (state.items === null) {
        return null
      }

      return state.items.slice(start, length)
    },
    itemEvents: (state) => {
      if(state.items === null || !Array.isArray(state.items)) {
        return []
      }
      return state.items.filter(item => item.type === 'event')
    },
    itemProducts: (state) => {
      if(state.items === null || !Array.isArray(state.items)) {
        return []
      }
      return state.items.filter(item => item.type === 'product')
    },
    bookingItems: (state, getters) => {
      if(state.items === null || !Array.isArray(state.items)) {
        return []
      }
      return getters.itemEvents.concat(getters.itemProducts)
    },
    availableTimeSlots: (state) => {
      return state.availableTimeSlots
    },
    bookingLocations: (state) => {
      if(state.locations === null || !Array.isArray(state.locations)) {
        return []
      }
      return state.locations.filter(location => location.label && location.active)
    },
    bookingChannels: (state) => {
      if(state.channels === null || !Array.isArray(state.channels)) {
        return []
      }
      return state.channels
    },
    quantityOptions: () => (item, noLimit = false) => {
      const MAXIMUMQUANTITY = 10
      if(item === null || typeof item === 'undefined') {
        return []
      }

      const min = item?.minimumOrderQuantity ?? 1
      let max = item?.maximumOrderQuantity ?? MAXIMUMQUANTITY

      if(noLimit && max < 100) {
        max = 100
      }

      const options = []
      for(let i = min; i <= max; i++) {
        options.push(i)
      }

      return options
    },
    cancellingBooking: (state) => {
      return state.cancellingBooking
    },
    graphUrl: (state) => {
      return state.graphUrl
    },
    organization: (state) => {
      return state.organization
    },
    updatingItem: (state) => {
      return state.updatingItem
    },
    variations: (state) => {
      return state.variations
    },
    attributes: (state) => {
      return state.attributes
    },
    translations: (state) => (language, type) => {
      /*
      translation form: { uuid (of translated item), key (of translated object property), language (being translated to), value (the translation), type (item, category, variation, attribute) }
       */
      if(type === null || typeof type === 'undefined') {
        return []
      }
      if(language === null || typeof language === 'undefined') {
        return []
      }

      const items = Array.isArray(state.items) ? [...state.items] : []
      const categories = Array.isArray(state.categories) ? [...state.categories] : []
      const variations = state.variations.filter(variation => variation && variation.type === 'product')
      const attributes = Array.isArray(state.attributes) ? [...state.attributes] : []
      const translations = Array.isArray(state.translations) ? [...state.translations] : []
      //const whitelistedProperties = ['title', 'name', 'label', 'description', 'summary']
      // const supportedLanguages = ['is', 'en']
      if(type === 'item') {
        for(let i = 0; i < items.length; i++) {
          const item = items[i]
          const titleProperty = translations.find(translation => translation.uuid === item.uuid && translation.language === language && translation.key === 'title')
          if(titleProperty === null || typeof titleProperty === 'undefined') {
            translations.push({
              uuid: item.uuid,
              key: 'title',
              language,
              value: '',
              type: 'item',
              status: item.status,
              item
            })
          }
          const descriptionProperty = translations.find(translation => translation.uuid === item.uuid && translation.language === language && translation.key === 'description:value')
          if(descriptionProperty === null || typeof descriptionProperty === 'undefined') {
            translations.push({
              uuid: item.uuid,
              key: 'description:value',
              language,
              value: '',
              type: 'item',
              status: item.status,
              item
            })
          }
          const summaryProperty = translations.find(translation => translation.uuid === item.uuid && translation.language === language && translation.key === 'description:summary')
          if(summaryProperty === null || typeof summaryProperty === 'undefined') {
            translations.push({
              uuid: item.uuid,
              key: 'description:summary',
              language,
              value: '',
              type: 'item',
              status: item.status,
              item
            })
          }
        }
      }
      if(type === 'category') {
        for(let i = 0; i < categories.length; i++) {
          const category = categories[i]
          const nameProperty = translations.find(translation => translation.uuid === category.uuid && translation.language === language && translation.key === 'name')
          if(nameProperty === null || typeof nameProperty === 'undefined') {
            translations.push({
              uuid: category.uuid,
              key: 'name',
              language,
              value: '',
              type: 'category',
              status: !category.administrative,
              item: category
            })
          }
          const descriptionProperty = translations.find(translation => translation.uuid === category.uuid && translation.language === language && translation.key === 'description')
          if(descriptionProperty === null || typeof descriptionProperty === 'undefined') {
            translations.push({
              uuid: category.uuid,
              key: 'description',
              language,
              value: '',
              type: 'category',
              status: !category.administrative,
              item: category
            })
          }
        }
      }
      if(type === 'variation') {
        for (let i = 0; i < variations.length; i++) {
          const variation = variations[i]
          const labelProperty = translations.find(translation => translation.uuid === variation.uuid && translation.language === language && translation.key === 'label')
          if (labelProperty === null || typeof labelProperty === 'undefined') {
            translations.push({
              uuid: variation.uuid,
              key: 'label',
              language,
              value: '',
              type: 'variation',
              status: variation.status,
              item: variation
            })
          }
        }
      }
      if(type === 'attribute') {
        for(let i = 0; i < attributes.length; i++) {
          const attribute = attributes[i]
          const nameProperty = translations.find(translation => translation.uuid === attribute.uuid && translation.language === language && translation.key === 'name')
          if(nameProperty === null || typeof nameProperty === 'undefined') {
            translations.push({
              uuid: attribute.uuid,
              key: 'name',
              language,
              value: '',
              type: 'attribute',
              status: !attribute.administrative,
              item: attribute
            })
          }
          const descriptionProperty = translations.find(translation => translation.uuid === attribute.uuid && translation.language === language && translation.key === 'description')
          if(descriptionProperty === null || typeof descriptionProperty === 'undefined') {
            translations.push({
              uuid: attribute.uuid,
              key: 'description',
              language,
              value: '',
              type: 'attribute',
              status: !attribute.administrative,
              item: attribute
            })
          }
        }
      }
      return translations.sort((a,b) => b.key.localeCompare(a.key)).sort((a,b) => a.uuid.localeCompare(b.uuid))
    },
    updatingTranslation: (state) => {
      return state.updatingTranslation
    },
    gettingYessProfiles: (state) => {
      return state.gettingYessProfiles
    },
    gettingPaymentMethodInstanceList: (state) => {
      return state.gettingPaymentMethodInstanceList
    },
    bulkOperationsByRoute: (state) => (route) => {
      return state.bulkOperations.filter(op => op.routes.includes(route))
    },
    timeStringNow: () => {
      return new Date().getTime()
    },
    graphUrlParams: (state, getters) => {
      return {
        params: {
          time: getters.timeStringNow
        }
      }
    },
    formatAmountByCurrency: (state) => (price) => {
      if(typeof price !== 'undefined' && price !== null) {
        let amount = price.amount
        const currency = price.currency_code

        if(Array.isArray(state.currencies) && state.currencies.length > 0) {
          const foundCurrency = state.currencies.find(c => c && c.code === currency)
          if(foundCurrency && typeof foundCurrency.decimals !== 'undefined') {
            amount = amount / Math.pow(10, foundCurrency.decimals)
          } else if(currency !== 'ISK') {
            // Fallback calculations
            amount = amount / Math.pow(10, 2)
          }
        } else if(currency !== 'ISK') {
          // Fallback calculations
          amount = amount / Math.pow(10, 2)
        }
        return currencyFormatter.format(amount, { code: currency })
      }

      return i18n.t('noPriceDefined')
    },
    formatNumberByCurrency: (state) => ({ amount, currency_code = 'ISK' }) => {
      if(isNaN(Number(amount))) {
        return 0
      }
      let formattedAmount = amount

      if(currency_code !== 'ISK') {
        formattedAmount = amount / Math.pow(10, 2)
      }

      if(Array.isArray(state.currencies)) {
        const foundCurrency = state.currencies.find(c => c && c.code === currency_code)
        if(typeof foundCurrency === 'undefined' || foundCurrency === null) {
          return formattedAmount
        }
        if(typeof foundCurrency.decimals === 'undefined' || foundCurrency.decimals === null) {
          return formattedAmount
        }

        formattedAmount = amount / Math.pow(10, foundCurrency.decimals)
      }

      return formattedAmount
    },
    unformatAmountByCurrency: (state) => (price) => {
      if(typeof price !== 'undefined' && price !== null) {
        let amount = price.amount
        const currency = price.currency_code

        if(currency !== 'ISK') {
          amount = amount * Math.pow(10, 2)
        }

        if(Array.isArray(state.currencies)) {
          const foundCurrency = state.currencies.find(c => c && c.code === currency)
          if(typeof foundCurrency === 'undefined' || foundCurrency === null) {
            return amount
          }
          if(typeof foundCurrency.decimals === 'undefined' || foundCurrency.decimals === null) {
            return amount
          }

          amount = amount * Math.pow(10, foundCurrency.decimals)
        }

        return amount
      }

      return i18n.t('noPriceDefined')
    },
    channelsFormatForUiFields: (state) => (channels) => {
      let channelsToFormat = state.channels
      if(Array.isArray(channels)) {
        channelsToFormat = channels
      }
      if(Array.isArray(channelsToFormat) && channelsToFormat.length > 0) {
        return channelsToFormat.map(channel => {
          return {
            key: channel.uuid,
            label: channel.label
          }
        })
      }
      return []
    },
    locationsFormatForUiFields: (state) => (locations) => {
      const locationsToReturn = []
      
      if(!Array.isArray(locations)) {
        locations = state.locations
      }

      if(Array.isArray(locations) && locations.length > 0) {
        
        for (const location of locations) {

          if (!location.active) {
            continue
          }

          locationsToReturn.push({
            key: location.uuid,
            label: location.description && location.label.toLowerCase() !== location.description.toLowerCase() ? `${location.label} (${location.description})` : location.label
          })

        }
      }

      return locationsToReturn
    },
    categoriesFormatForUiFields: (state) => (categories) => {
      if(!Array.isArray(categories)) {
        categories = state.categories
      }

      if(Array.isArray(categories) && categories.length > 0) {
        return categories.map(category => {
          return {
            key: category.uuid,
            label: category.name
          }
        })
      }

      return []
    },
    itemsFormatForUiFields: (state) => (items) => {
      if(!Array.isArray(items)) {
        items = state.items
      }

      if(Array.isArray(items) && items.length > 0) {
        return items.map(item => {
          return {
            key: item.uuid,
            label: item.title
          }
        })
      }

      return []
    },
    paymentMethodInstancesFormatForUiFields: (state) => (paymentMethodInstances) => {
      if(!Array.isArray(paymentMethodInstances)) {
        paymentMethodInstances = state.paymentMethodInstances
      }
      if(Array.isArray(paymentMethodInstances)) {
        return paymentMethodInstances.reduce((result, instance) => {
          if(typeof instance !== 'undefined' && instance !== null) {
            result.push({
              key: instance.uuid,
              label: instance.title,
              // label: instance.title + (instance.checkout ? ' (checkout)' : ''),
              // label: instance.title + (instance.status ? ' (disabled)' : ''),
              // disabled: instance.status,
              fullItem: instance
            })
          }
          return result
        }, [])
      }

      return []
    },
    deliveryMethodInstancesFormatForUiFields: (state) => (deliveryMethodInstances) => {
      if(!Array.isArray(deliveryMethodInstances)) {
        deliveryMethodInstances = state.deliveryMethodInstances
      }
      if(Array.isArray(deliveryMethodInstances)) {
        return deliveryMethodInstances.reduce((result, instance) => {
          if(typeof instance !== 'undefined' && instance !== null) {
            result.push({
              key: instance.uuid,
              label: instance.title,
              fullItem: instance
            })
          }
          return result
        }, [])
      }

      return []
    },
    languagesFormatForUiFields: (state) => (languages) => {
      if(!Array.isArray(languages)) {
        languages = state.languages
      }
      if(Array.isArray(languages)) {
        return languages.reduce((result, language) => {
          if(typeof language !== 'undefined' && language !== null) {
            result.push({
              key: language.key,
              label: language.name,
              fullItem: language
            })
          }
          return result
        }, [])
      }

      return []
    },
    orderFulfillmentOptionsFormatForUiFields: (state) => (orderFulfillmentOptions) => {
      if(!Array.isArray(orderFulfillmentOptions)) {
        orderFulfillmentOptions = state.orderFulfillmentOptions
      }
      if(Array.isArray(orderFulfillmentOptions)) {
        return orderFulfillmentOptions.reduce((result, option) => {
          if(typeof option !== 'undefined' && option !== null) {
            result.push({
              key: option.key,
              label: i18n.t(option.label)
            })
          }
          return result
        }, [])
      }

      return []
    }
  },
  modules: {
    yess,
    timeSlots,
    amplitudeWrapper,
  }
})
